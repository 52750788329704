import {
  Box,
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  Stack,
  Typography,
  Chip,
  SelectChangeEvent,
  useTheme,
} from '@mui/material';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { MenuItem } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGroup, useValueSets } from 'src/@nicheaim/fhir-react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { FormProvider, RHFTextField, RHFSelect } from '../../../../../components/hook-form';
import { ValueSetComposeIncludeConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { Select } from '@mui/material';
import { OutlinedInput } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Theme } from '@mui/material';
import { GroupWrapper } from 'src/@nicheaim/fhir-base/wrappers/Group';
import useLocales from 'src/hooks/useLocales';

type FormValue = {
  name: string;
  code: string[];
  identifier: string;
  type: string;
  identifiervalue: string;

};

type Props = {
  patient: WrappedPatient;
  open: boolean;
  onClose: VoidFunction;
  id: string;
  refresh: any;

};

export default function EditGroup({ refresh, id, patient, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useLocales();
  const [codeGroup] = useValueSets({ filter: { name: `ph-group-codes` } });
  const [codeIndetifier] = useValueSets({ filter: { name: `ph-group-identifiers` } });
  const [group, { update: UpdateGroup }] = useGroup(id, { map: GroupWrapper });
  const [code, setCode]: any = useState([]);
  const [codeRow, setCodeRow]: any = useState([]);

  const theme = useTheme();
  const defaultValues = useMemo(
    () =>
    ({
      name: group?.name || '',
      code: group?.code?.coding?.map(item => item.display) || [],
      identifier: group?.identifier?.[0]?.type?.text || '',
      type: 'person',
      identifiervalue: group?.identifier != undefined ? group?.identifier[0]?.value : '' || '',
    } as FormValue),
    [group]
  );

  const EventSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    identifier: Yup.string().required('Identifier is required'),
    type: Yup.string(),
    managingEntity: Yup.string(),
  });

  const methods = useForm({ resolver: yupResolver(EventSchema), defaultValues });

  const { reset, handleSubmit } = methods;

  useEffect(() => {
    setCode(group?.code?.coding?.map(item => item.display));
  }, [group]);
  useEffect(() => {
    const arrayCode: any = codeGroup[0]?.compose?.include[0]?.concept

    const transformedArray: any[] = (group?.code?.coding ?? []).map((item) => ({
      code: item.code,
      display: item.display,
    }));

    const combinedArray: any[] = arrayCode?.concat(transformedArray);
    const filterCode: ValueSetComposeIncludeConcept[] = combinedArray?.reduce((result, obj) => {
      if (!result.some((item) => item.code === obj.code)) {
        result.push(obj);
      }
      return result;
    }, []);
    setCodeRow(filterCode)
  }, [group, codeGroup]);


  useEffect(() => {
    reset(defaultValues);
  }, [open, defaultValues]);


  const handleClose = () => {
    refresh();
    onClose();
  };


  const handleChangeStatus = (event: SelectChangeEvent<typeof code>) => {

    const {
      target: { value },
    } = event;
    setCode(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const onSubmit = async (data: FormValue) => {
    const filterCode = codeRow.filter(obj => code.includes(obj.display));
    const filterCodeUnique: ValueSetComposeIncludeConcept[] = filterCode.reduce((result, obj) => {
      if (!result.some((item) => item.code === obj.code)) {
        result.push(obj);
      }
      return result;
    }, []);
    try {
      await UpdateGroup({
        id: group?.id,
        resourceType: 'Group',
        name: data.name,
        active: true,
        actual: true,
        type: 'person',
        code: {
          coding: filterCodeUnique
        },
        identifier: [
          {
            system: `https://careflow2.nicheaim.com/group/${data?.identifier?.toLowerCase().replace(/\s+/g, '-')}`,
            type: {
              text: data.identifier
            },
            value: `${data.identifiervalue}`
          }
        ],
      }
      );

      handleClose();
      enqueueSnackbar(`${i18n('patients.title', 'crs')} was updated.`);
    } catch {
      handleClose();
      enqueueSnackbar(`${i18n('patients.title', 'crs')} was not updated.`, { variant: 'error' });
    }
  };
  function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  if (!open) return null;

  return (
    <Dialog keepMounted={false} open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>Group</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Typography variant="body2">Name</Typography>
                <RHFTextField name="name" label="Type Name" />

                <Typography variant="body2">Code</Typography>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chips"
                  multiple
                  value={code}
                  onChange={handleChangeStatus}
                  input={<OutlinedInput id="select-multiple-chip" label="Status" />}
                  renderValue={(selected) => {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value: any) => {
                          const capitalizedValue =
                            value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
                          return (
                            <Chip
                              key={value}
                              label={capitalizedValue}
                              onClick={() => {
                                const updatedValues = code.filter((v: any) => v !== value);
                                console.log(updatedValues);
                                setCode(updatedValues);
                              }}
                              deleteIcon={
                                <ChevronRightIcon
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    const updatedValues = code.filter((v: any) => v !== value);
                                    console.log(updatedValues);
                                    setCode(updatedValues);
                                  }}
                                />
                              }
                            />
                          );
                        })}
                      </Box>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  {codeRow?.map((option) => (
                    <MenuItem
                      key={option.display}
                      value={option.display}
                      style={getStyles(option, [], theme)}
                    >
                      {option.display}
                    </MenuItem>
                  ))}
                </Select>

                <Typography variant="body2">Identifier</Typography>
                <RHFSelect name="identifier" label="Identifier">
                  <MenuItem disabled></MenuItem>
                  {codeIndetifier[0].compose?.include[0]?.concept?.map((option) => (
                    <MenuItem key={option.code} value={option.display}>
                      {option.display}
                    </MenuItem>
                  ))}
                </RHFSelect>

                <Typography variant="body2">Value</Typography>
                <RHFTextField name="identifiervalue" label="Identifier Value" />

                <Typography variant="body2">Type</Typography>
                <RHFTextField disabled name="type" label="Group Type" />

              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" color="info" onClick={handleClose}>
                Cancel
              </Button>

              <Button variant="contained" color="info" type="submit">
                Submit
              </Button>

            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
};






