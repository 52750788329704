import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Card, CardHeader, CardProps } from '@mui/material';
// utils
import { fNumber } from '../../../../utils/formatNumber';
// components
import { BaseOptionChart } from '../../../../components/chart';
import { useMemo } from 'react';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;

const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  chartData: {
    label: string;
    value: number;
  }[];
  chartColors: string[][];
  labelColors: string[];
  customChartOptions?: any;
}

export default function DonutChart({
  title,
  subheader,
  chartColors,
  chartData,
  labelColors,
  customChartOptions = {},
  ...other
}: Props) {
  const theme = useTheme();
  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = merge(
    BaseOptionChart(),
    merge(
      {
        labels: chartLabels,
        legend: { floating: true, horizontalAlign: 'center' },
        fill: {
          type: 'gradient',
          gradient: {
            colorStops: chartColors.map((colors) => [
              { offset: 0, color: colors[0] },
              { offset: 100, color: colors[1] },
            ]),
            opacity: 1,
            opacityFrom: 1,
            opacityTo: 1,
            shadeIntensity: 1,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: { size: '68%' },
            dataLabels: {
              value: { offsetY: 16 },
              total: {
                enabled: true,
                formatter: (w: { globals: { seriesTotals: number[] } }) => {
                  const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                  return fNumber(sum);
                },
              },
            },
          },
        },
        colors: labelColors,
      },
      customChartOptions
    )
  );

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <ChartWrapperStyle dir="ltr">
        <ReactApexChart
          type="donut"
          series={chartSeries}
          options={chartOptions}
          // https://github.com/apexcharts/react-apexcharts/issues/180
          height={250 + Math.floor(Math.random() * 2) + 1}
        />
      </ChartWrapperStyle>
    </Card>
  );
}
