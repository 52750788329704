import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { Task } from 'src/@nicheaim/fhir-base/mappings/Task';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { TaskWrapper } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { fhirClient } from 'src/App';
import { CustomModalBasicProps } from 'src/components/CustomModal';
import useCheckNTaskLinker from 'src/hooks/useCheckNTaskLinker';
import useLocales from 'src/hooks/useLocales';
import useTranslations from 'src/hooks/useTranslations';
import { useStandardLoadingBackdrop } from 'src/sections/careflow/common/StandardLoadingBackdropProvider';
import { MemberResourceTypeOption } from 'src/sections/crs/case/components/SearchMember/SearchMember';
import { getAssessmentFromTask } from 'src/sections/crs/helpers/common';
import AssessmentFrame from 'src/sections/crs/patient/components/AssessmentFrame';

export type ReferralReferedOption = MemberResourceTypeOption;

interface Props extends CustomModalBasicProps {
  patient: WrappedPatient | null;
  open: boolean;
  handlerSave: (data: any) => Promise<any>;
  onClose: () => void;
  checkListData: any;
}

const CANCELED_STATUSES = ['rejected', 'cancelled', 'failed', 'entered-in-error'];
const ACTIVE_STATUSES = [
  'draft',
  'requested',
  'received',
  'accepted',
  'ready',
  'in-progress',
  'on-hold',
  'completed',
];

export default function ChartReview({ patient, open, onClose, handlerSave, checkListData }: Props) {
  const translate = useTranslations(`assessmentDialog`);


  const activityDefinitionId: string | null = useMemo(() => {
    if (!checkListData) {
      return null;
    }

    try {
      const metadata = JSON.parse(checkListData.metadata);
      const activityDefinitionRef = metadata.ui.data.activityDefinitionRef;
      const activityDefinitionId = activityDefinitionRef.split('/')[1];

      if (typeof activityDefinitionId === 'string') {
        return activityDefinitionId;
      }
    } catch (err) {
      return null;
    }

    return null;
  }, [checkListData]);

  const patientId = useMemo(() => {
    return patient?.id || '';
  }, [patient]);

  const standardLoadingBackdrop = useStandardLoadingBackdrop();
  const { enqueueSnackbar } = useSnackbar();

  const taskLinker = useCheckNTaskLinker({ patientId, activityDefinitionId: activityDefinitionId });

  const [assessmentUrl, setAssessmentUrl] = useState<string>();

  async function findAssessmentTask(subjectId: string, activityDefinitionId: string) {
    const statusQuery = ACTIVE_STATUSES.join(',');
    let query = `/Task?code=assessment&subject=${subjectId}&status=${statusQuery}`;
    let taskQueryRes;

    try {
      taskQueryRes = await fhirClient.get<any>(query);
    } catch (error) {
      console.error(error);
      throw new Error('failed to get Task data to get assessment');
    }

    if (!taskQueryRes.entry || taskQueryRes.entry.length < 1) {
      return undefined;
    }

    const expectedInstatiatesCanonical = 'ActivityDefinition/' + activityDefinitionId;

    const firstAssessmentTaskEntry = taskQueryRes.entry.find((item) => {
      const task = item.resource as Task;
      return (
        task.instantiatesCanonical === expectedInstatiatesCanonical &&
        task.code?.coding?.[0]?.code?.includes?.('assessment')
      );
    });

    if (firstAssessmentTaskEntry) {
      return firstAssessmentTaskEntry.resource as Task;
    }

    return undefined;
  }

  async function setAssessmentUrlFromTask(task: Task) {
    const inputAssessment = getAssessmentFromTask(task);

    if (!inputAssessment || !inputAssessment.valueUrl) {
      enqueueSnackbar(translate('notReadyContactAdminMessage') , {
        variant: 'error',
      });
      handleClose();
      return;
    }

    if (!inputAssessment.valueUrl.includes('token=')) {
      enqueueSnackbar(translate('notReadyTryAgainLaterMessage'), {
        variant: 'error',
      });
      handleClose();
      return;
    }

    standardLoadingBackdrop.close();

    setAssessmentUrl(inputAssessment.valueUrl);
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms || 300));
  }

  async function initialHandler(patientId: string, activityDefinitionId: string) {
    let assessmentTask = await findAssessmentTask(patientId, activityDefinitionId);

    if (!assessmentTask) {
      assessmentTask = await taskLinker.createAssessmentTask(activityDefinitionId);
      enqueueSnackbar(translate('preparingWaitMessage'), {
        variant: 'info',
      });

      await sleep(12000);

      assessmentTask = await findAssessmentTask(patientId, activityDefinitionId);
    }

    if (!assessmentTask) {
      enqueueSnackbar(translate('notReadyTryAgainLaterMessage'), {
        variant: 'error',
      });
      handleClose();
      throw new Error('failed to get Assessment Task data');
    }

    const aTaskWrapper = TaskWrapper(assessmentTask);

    if (assessmentTask && assessmentTask.status && !aTaskWrapper.isActive()) {
      enqueueSnackbar(translate('doneMessage'), {
        variant: 'info',
      });
      handlerSave({});
      handleClose();
      return;
    }

    setAssessmentUrlFromTask(assessmentTask);
  }

  function handleClose() {
    standardLoadingBackdrop.close();
    onClose();
  }

  useEffect(() => {
    if (open) {
      standardLoadingBackdrop.open();
    }

    if (open && patientId && activityDefinitionId) {
      initialHandler(patientId, activityDefinitionId);
    }
  }, [open, patientId, activityDefinitionId]);

  useEffect(() => {
    if (standardLoadingBackdrop.forceClose) {
      handleClose();
    }
  }, [standardLoadingBackdrop.forceClose]);

  return assessmentUrl ? (
    <AssessmentFrame open={open} url={assessmentUrl} onCancel={handleClose} />
  ) : null;
}
