import { IsEnum, IsNumber, IsOptional, IsString } from 'class-validator';

export enum ContactAttemptOutcome {
  HEALTHJOURNEY_SCHEDULED = "HealthJourney scheduled",
  INTERESTED_REQUIRES_FOLLOWUP = "Interested - requires follow-up",
  NO_ANSWER_VOICEMAIL_LEFT = "No answer - voicemail left",
  NO_ANSWER_NO_VOICEMAIL_LEFT = "No answer - no voicemail left",
  OPTOUT_SPECIFY_REASON = "Opt out - specify reason",
  PROMPT_TO_CALL_LETTER = "Prompt to Call Letter",
  WRONG_NUMBER = "Wrong number",
  OTHER_SPECIFY_IN_NOTES = "Other - specify in notes",
}

export enum TypeContactAttempt {
  CONTACT_CLIENT_ITEM = 'Contact client item',
  CONTACT_PCP_ITEM = 'Contact PCP item',
  CONFIRM_CONTACT = 'Confirm contact',
  CASE_CONTACT_ITEM = 'Case Note',
  INTERNAL_ITEM = 'Internal Note',
}

export class ContactAttemptDto {
  @IsNumber()
  @IsOptional()
  id?: number;

  @IsString()
  @IsOptional()
  contactOn?: string;

  @IsString()
  @IsOptional()
  nextContactOn?: string;

  @IsString()
  typeNote: string;

  @IsEnum(ContactAttemptOutcome)
  @IsOptional()
  outcome?: ContactAttemptOutcome;

  @IsString()
  reasonNote: string;

  @IsEnum(TypeContactAttempt)
  @IsOptional()
  type?: TypeContactAttempt;

  @IsString()
  @IsOptional()
  duration?: string;
}
