import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as actions from './actions';
import { UserManager } from 'src/@types/user';
import { useMemo } from 'react';

export function useUsers() {
  return useQuery(['users'], () => actions.getUsersList());
}

export function useUsersPaginate(params: actions.GetUsersListPaginateQueryFilters) {
  return useQuery(['users-paginate'], () => actions.getUsersListPaginate(params));
}

export function useUsersWithFhirUri() {
  const { data: users, ...queryState } = useUsers();

  // FIXME: fhirUri not in UserManager type?
  // @ts-expect-error
  const usersWithFhirUri = useMemo(() => users?.filter((user) => user.fhirUri) ?? [], [users]);

  return {
    data: usersWithFhirUri,
    ...queryState,
  };
}

export function useUserByUuid(uuid: string) {
  return useQuery(['users', `uuid:${uuid}`], () => actions.getUserByUuid(uuid));
}

export function useUserByEmail(email: string) {
  return useQuery(['users', `email:${email}`], () => actions.getUserByEmail(email));
}

export function useCreateUser() {
  const client = useQueryClient();

  return useMutation((payload: UserManager) => actions.createUser(payload), {
    onSuccess: () => {
      client.refetchQueries({
        predicate: (query) => query.queryKey[0] === 'users',
      });
    },
  });
}
