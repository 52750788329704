import {
  Backdrop,
  Chip,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import Page from 'src/components/Page';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import {
  IncidentResponse,
  IncidentStatus,
  ScopeReasonType,
  pcpAssignmentIncidentScopeCodes,
} from 'src/crs/incident/IncidentService';
import { PCP_ROLE_CODE } from 'src/config';
import { useParams } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import { doFormService } from 'src/nj/doform';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { incidentService } from 'src/crs/incident';
import CustomModal from 'src/components/CustomModal';
import Header from './incident-detail-section/Header';
import { usePatients } from 'src/@nicheaim/fhir-react';
import { getValuesFromReference } from 'src/utils/fhir';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { practitionerService } from 'src/crs/practitioner';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DoFormIncident } from '../../../nj/doform/DoFormIncident';
import { PCPIncident } from './incident-detail-section/PCP/PCPIncident';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { AddressIncident } from './incident-detail-section/AddressIncident';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import { AssignableGeneralPractitioner } from 'src/@types/crs/providerDirectory';
import { PCPAssignmentScopeData, PCPIncidentSourceData } from 'src/@types/crs/incident';
import { LoadingComponent, NoResultText } from '../../case/components/RelatedReferrals/components';
import ResolutionConfirmationDialog from './incident-detail-section/PCP/ResolutionConfirmationDialog';
import { addressesSorted, getPatientField, getResourceByReference, statusStyle } from '../../helpers/indicent';
import useLocales from 'src/hooks/useLocales';

export default function IncidentDetails() {
  const { id } = useParams();
  const { i18n } = useLocales();
  const user = useAuth().getCurrentUser();
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [dataIncident, setDataIncident] = useState<IncidentResponse | undefined>(undefined);
  const [sourceData, setSourceData] = useState<any | undefined>(undefined);
  const [typeIncident, setTypeIncident] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPCPIncidentLoading, setIsPCPIncidentLoading] = useState(false);
  const [isNatServiceLoading, setIsNatServiceLoading] = useState(false);
  const [linkRecord, setLinkRecord] = useState('');
  const [isPCPIncidentResolutionDialogOpen, setIsPCPIncidentResolutionDialogOpen] = useState(false);
  const [patientPCPPractitionerRoles, setPatientPCPPractitionerRoles] = useState<
    AssignableGeneralPractitioner[] | null
  >(null);
  const [openReject, setOpenReject] = useState(false);
  const [reasonReject, setReasonReject] = useState('');
  const [rejectModalLoading, setRejectModalLoading] = useState(false);
  const [fhirResource, setFhirResource] = useState<any>();
  const [refresh, setRefresh] = useState(false);

   const getIncidentDetails = async (id: any) => {
    setIsLoading(true);
    try {

      const resp = await incidentService.getOne(id);
      setDataIncident(resp);

      setTypeIncident(resp?.scope_reason ?? "");

      if(resp?.scope_reason === ScopeReasonType.PCP_ASSIGNMENT_GROUP_REASON){
          const sourceData = resp?.source_data as PCPIncidentSourceData;
          if(sourceData?.fhirPatientURL) {
            const resource = await getResourceByReference(sourceData.fhirPatientURL);
            setFhirResource(resource);
          }
          setSourceData(sourceData)
      }else if(resp?.source_reference_value && resp?.scope_reason === ScopeReasonType.INVALID_ADDRESS){
        const resource = await getResourceByReference(resp.source_reference_value);
        setFhirResource(resource);
      }else if (resp?.source_reference_value){
        const doFormResponse = await 
          doFormService.getDoFormRecordNJInckRegistration(resp?.source_reference_value);
        setSourceData(doFormResponse);
      }

      if(resp?.scope_data !== null){
        setLinkRecord(resp?.scope_data?.linked_record_uuid);
      }

    } catch (e) {
      console.log(`Something failed get incident details ${e}`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getIncidentDetails(id);
    setRefresh(false);
    return () => {
      setDataIncident(undefined);
    };
  }, [id, refresh]);

  const fullName = `${getPatientField(dataIncident, 'Beneficiary_First_Name', 'text')} 
    ${getPatientField(dataIncident, 'Beneficiary_Last_Name', 'text')}`.trim();

  const [, { update: updatePatient, refresh: refreshPatient }] = usePatients({
    autofetch: false,
  });

  const aclEditUser = checkAclValidation({ acls: [crsAcls.CRS.INCIDENT.EDIT] });
  const isPCPIncidentResolutionAvailable = dataIncident?.scope_data?.linkedPractitionerRoleId;
  const isDispositionDone = !!(
    dataIncident &&
    dataIncident?.status !== IncidentStatus.NEW &&
    dataIncident?.status !== IncidentStatus.IN_PROGRESS
  );
  const dataIncidentStatus =
    dataIncident?.status === IncidentStatus.REJECTED ||
    dataIncident?.status === IncidentStatus.ACCEPTED;
  const acceptDisabledCondition = (typeIncident === ScopeReasonType.PCP_ASSIGNMENT_GROUP_REASON)
    ? !isPCPIncidentResolutionAvailable || isPCPIncidentLoading || isDispositionDone
    : isNatServiceLoading
    ? true
    : linkRecord === ''
    ? true
    : dataIncidentStatus
    ? true
    : dataIncident?.assigned_to === null
    ? true
    : false;
  const isMultiplePCP = useMemo(
    () => dataIncident?.scope_code === pcpAssignmentIncidentScopeCodes.MULTIPLE_PCP,
    [dataIncident]
  );

  const handleOnIncidentUpdate = useCallback((incident: IncidentResponse) => {
    setDataIncident(incident);
    if (typeIncident === ScopeReasonType.PCP_ASSIGNMENT_GROUP_REASON) setSourceData(incident);
  }, []);

  const handlerAccept = async () => {

    if(!dataIncident?.assigned_to) {
      enqueueSnackbar('Please, complete the field Assing To', {variant: 'error'});
      return;
    }

    if (typeIncident === ScopeReasonType.PCP_ASSIGNMENT_GROUP_REASON) return setIsPCPIncidentResolutionDialogOpen(true);
    if(typeIncident === ScopeReasonType.INVALID_ADDRESS) await handlerAddressIncident();
    // setIsNatServiceLoading(true);
  };

  useEffect(() => {
    const patientId = fhirResource?.id;
    if (!isMultiplePCP || !patientId) return;

    const getPatientPCPPractitionerRoles = async () => {
      const generalPractitioners = await practitionerService.getPatientGeneralPractitioner(
        patientId
      );
      if (!generalPractitioners?.length) return setPatientPCPPractitionerRoles([]);

      setPatientPCPPractitionerRoles(
        generalPractitioners.filter(
          ({ roleCode, practitioner }) => roleCode === PCP_ROLE_CODE && !!practitioner
        )
      );
    };
    getPatientPCPPractitionerRoles();
  }, [isMultiplePCP, fhirResource]);

  const handlerPCPIncidentAccept = async (notes: string) => {
    const { linkedPractitionerRoleId } = (dataIncident?.scope_data ?? {}) as PCPAssignmentScopeData;
    if (!linkedPractitionerRoleId || !fhirResource || !dataIncident) return;
    setIsPCPIncidentLoading(true);

    if (isMultiplePCP && fhirResource?.generalPractitioner?.length) {
      const pcpPractitionerRoleIds =
        patientPCPPractitionerRoles?.map?.(({ resourceId }) => resourceId) ?? [];
      await updatePatient({
        ...fhirResource,
        generalPractitioner: fhirResource?.generalPractitioner.filter(({ reference }) => {
          const [_, id] = getValuesFromReference(reference);
          if (!id) return true;
          return !pcpPractitionerRoleIds.includes(id);
        }),
      });
    }

    let generalPractitioners = [...(fhirResource?.generalPractitioner ?? [])];
    if (
      !generalPractitioners.find(
        (generalPractitioner) =>
          generalPractitioner?.reference === `PractitionerRole/${linkedPractitionerRoleId}`
      )
    )
      generalPractitioners = [
        ...generalPractitioners,
        { reference: `PractitionerRole/${linkedPractitionerRoleId}` },
      ];

    await updatePatient({
      ...fhirResource,
      generalPractitioner: generalPractitioners,
    });

    refreshPatient();

    if (fhirResource?.generalPractitioner?.length === 0) {
      enqueueSnackbar(
        "There has been an error while updating patient's general practitioners. Please try again",
        {
          variant: 'error',
        }
      );
      return setIsPCPIncidentLoading(false);
    }

    const updatedIncident = await incidentService.updateIncident(dataIncident.id, {
      ...dataIncident,
      disposition_by: user?.userName,
      disposition_on: new Date(),
      disposition_note: notes,
      status: IncidentStatus.ACCEPTED,
    });
    if (!updatedIncident) return setIsPCPIncidentLoading(false);

    handleOnIncidentUpdate(updatedIncident);

    enqueueSnackbar('Incident has been resolved succesfully', {
      variant: 'success',
    });
    setIsPCPIncidentResolutionDialogOpen(false);
  };

  const handlerAddressIncident = async () => {
    const getAddresses = addressesSorted(fhirResource?.address, ['valid', 'period.end']);

    const validAddress = getAddresses?.filter((x1:any) => x1?.valid && 
      (x1?.period?.end === undefined || moment(x1?.period?.end ?? null).isAfter(moment()))) ?? [];
   
    if(validAddress?.length > 0 && dataIncident){
      const updatedIncident = await incidentService.updateIncident(dataIncident?.id, {
        ...dataIncident,
        disposition_by: user?.userName,
        disposition_on: new Date(),
        status: IncidentStatus.ACCEPTED,
      });

      if (updatedIncident) handleOnIncidentUpdate(updatedIncident);

      enqueueSnackbar('Incident has been resolved succesfully');
    }else{
      enqueueSnackbar('There is no valid address, please check', {
        variant: 'error',
      });
    }

  };

  const handlerReject = async () => {
    try {
      if (!dataIncident) {
        setRejectModalLoading(false);
        setOpenReject(false);
        setReasonReject('');
        return;
      }

      setRejectModalLoading(true);

      const updatedIncident = await incidentService.updateIncident(dataIncident.id, {
        ...dataIncident,
        disposition_by: user?.userName,
        disposition_on: new Date(),
        disposition_note: reasonReject ?? null,
        status: IncidentStatus.REJECTED,
      });

      if (updatedIncident) handleOnIncidentUpdate(updatedIncident);
    } catch (error) {
      enqueueSnackbar('An error has occurred', { variant: 'error' });
    }

    setRejectModalLoading(false);
    setOpenReject(false);
    setReasonReject('');
  };

  return (
    <Page title={i18n('incident.details.title', 'crs')}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          title={i18n('incident.details.title', 'crs')}
          heading=""
          links={[
            { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
            { name: `${i18n('breadcrumbs.title', 'crs')}`},
            { name: `${i18n('incident.list.title', 'crs')}`, href: PATH_DASHBOARD.crs.incident },
            { name: `${i18n('incident.details.title', 'crs')}` },
          ]}
        />
        {!isLoading ? (
          dataIncident ? (
            <Stack sx={{ m: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Stack
                    spacing={1}
                    direction={{ sm: 'row' }}
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ mb: 3 }}
                  >
                    <Typography variant="body1">
                      <b>{fullName}</b>
                    </Typography>
                    <Chip
                      label={dataIncident?.status}
                      sx={{ ...statusStyle(dataIncident?.status!), fontWeight: 'bold' }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    spacing={1}
                    direction={{ sm: 'row' }}
                    justifyContent="flex-end"
                    sx={{ mb: 3 }}
                  >
                    {aclEditUser && (
                      <>
                        <Tooltip title="Reject">
                          <IconButton
                            onClick={() => setOpenReject(true)}
                            disabled={dataIncidentStatus}
                          >
                            <Iconify
                              icon={'carbon:close-filled'}
                              color={dataIncidentStatus ? 'grey' : '#ff4842'}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={
                            (typeIncident === ScopeReasonType.PCP_ASSIGNMENT_GROUP_REASON) && 
                            !isDispositionDone && !isPCPIncidentResolutionAvailable
                              ? 'Must select a Provider and a Role to Continue'
                              : 'Accept'
                          }
                        >
                          <IconButton onClick={handlerAccept} disabled={acceptDisabledCondition}>
                            <Iconify
                              icon={'icon-park-solid:check-one'}
                              color={acceptDisabledCondition ? 'grey' : '#3dab2b'}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Stack>
                </Grid>
              </Grid>

              <Header
                incident={dataIncident}
                resource={fhirResource}
                dataDoForm={sourceData}
                typeIncident={typeIncident}
                user={user}
                onIncidentUpdate={handleOnIncidentUpdate}
              />

              {(typeIncident === ScopeReasonType.PCP_ASSIGNMENT_GROUP_REASON) && (
                <PCPIncident 
                  incident={dataIncident}
                  patientPCPPractitionerRoles={patientPCPPractitionerRoles}
                  onIncidentUpdate={handleOnIncidentUpdate}
                  onLoading={setIsPCPIncidentLoading}
                />
              )}

              {!(typeIncident === ScopeReasonType.INVALID_ADDRESS || 
                typeIncident === ScopeReasonType.PCP_ASSIGNMENT_GROUP_REASON) && (
                <DoFormIncident 
                  dataDoForm={sourceData}
                  incident={dataIncident}
                  dataIncidentStatus={dataIncidentStatus}
                />
              )}

              {(typeIncident === ScopeReasonType.INVALID_ADDRESS) && (
                <AddressIncident 
                  resource={fhirResource} 
                  refresh={() => setRefresh(true)}
                  dataIncidentStatus={dataIncidentStatus}
                />
              )}
            </Stack>
          ) : (
            <NoResultText text="Incident details not found" />
          )
        ) : (
          <LoadingComponent />
        )}
        <ResolutionConfirmationDialog
          open={isPCPIncidentResolutionDialogOpen}
          incident={dataIncident}
          patient={fhirResource ? PatientWrapper(fhirResource) : null}
          onClose={() => setIsPCPIncidentResolutionDialogOpen(false)}
          onAccept={handlerPCPIncidentAccept}
          isLoading={isPCPIncidentLoading}
        />
        <CustomModal
          open={openReject}
          title="Reject"
          breadcrumbs={[]}
          showSaveButton={true}
          onCancel={() => {
            setRejectModalLoading(false);
            setReasonReject('');
            setOpenReject(false);
          }}
          onSave={handlerReject}
          isLoading={rejectModalLoading}
        >
          <TextField
            label="Additional Note"
            multiline
            fullWidth
            rows={4}
            sx={{ mt: 2 }}
            value={reasonReject}
            onChange={(ev) => {
              setReasonReject('');
              setReasonReject(ev.target.value);
            }}
          />
        </CustomModal>
      </Container>
    </Page>
  );
}
