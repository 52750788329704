import Iconify from "../Iconify";
import { useState } from "react";
import MenuPopover from "../MenuPopover";
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { Box, Button, FormControlLabel, Radio, RadioGroup, Stack, Switch, Typography } from "@mui/material";
import uuidv4 from "src/utils/uuidv4";

type Props = {
  headerColumn: any;
  options?: any[];
  onSort: (id: string) => any;
  setOrder?: React.Dispatch<React.SetStateAction<"asc" | "desc">>;
};
  
export default function TableModalSort({
  headerColumn,
  options,
  onSort,
  setOrder
}: Props) {

  const generateInitialState = () => {
    const initialState = {};
    options?.forEach(option => {
      if (option.headerColumn === headerColumn?.id) {
        initialState[option.value] = 'asc';
      }
    });
    return initialState;
  };

  const [open, setOpen] = useState<HTMLButtonElement | null>(null);

  const [sortBy, setSortBy] = useState('');

  const [switchStates, setSwitchStates] = useState(generateInitialState);

  const handleOpen = (currentTarget: HTMLButtonElement) => {
    setOpen(currentTarget);
  };

  const handleClose = () => {
    setSortBy('');
    setSwitchStates(generateInitialState);
    setOpen(null);
  };

  const handleChangeSortBy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSortBy('');
    setSwitchStates(generateInitialState)
    setSortBy((event.target as HTMLInputElement).value);
    onSort((event.target as HTMLInputElement).value)
  };

  const handleSwitchChange = (value) => (event) => {
    const newOrder = event.target.checked ? 'desc' : 'asc';
    setSwitchStates(prevState => ({
      ...prevState,
      [value]: newOrder
    }));
    setOrder?.(newOrder);
  };
  
  return(
    <>
      <Button
        color="inherit"
        disableFocusRipple
        disableRipple
        onClick={(event) => handleOpen(event.currentTarget)}
        endIcon={<ExpandCircleDownOutlinedIcon />}
        sx={{
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: 'transparent',
          },
        }}
      >
        {headerColumn?.label}
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 'auto' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        disabledArrow={true}
      >
        {options?.filter((e) => e.headerColumn === headerColumn?.id).map((option) => {
          
          const { value, icon, label } = option;
          const isChecked = switchStates[value] === 'desc';
          
          return (
            <Box sx={{ flexGrow: 1 }} key={uuidv4()}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ m: 1 }}>
                <Typography variant="subtitle1">
                  {icon}
                  {label}
                </Typography>

                <RadioGroup 
                  row
                  value={sortBy} 
                  onChange={handleChangeSortBy}
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  sx={{ justifyContent:'flex-end'}}
                >
                  <FormControlLabel
                    value={value}
                    control={<Radio checkedIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />} />}
                    label={null}
                    labelPlacement="start"
                  />
                </RadioGroup>
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="center">
                <Typography variant="caption" sx={{ mr: 1 }}>
                  ASC
                </Typography>
                
                <Switch
                  disabled={value !== sortBy}
                  checked={isChecked}
                  onChange={handleSwitchChange(value)}
                />
                <Typography variant="caption">
                  DESC
                </Typography>
              </Stack>
            </Box>
          )
        })}
      </MenuPopover>
    </>
  )
}