import {
  Cake as CakeIcon,
  ContactMail as ContactMailIcon,
  ContactPhone as ContactPhoneIcon,
  MedicalInformation as MedicalInformationIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import Label from '../../../../components/Label';
import { CaseManager } from 'src/@types/crs/referral';
import { useNavigate, useLocation } from 'react-router-dom';
import { TableRow, TableCell, Typography, Box } from '@mui/material';
import { PATH_DASHBOARD } from 'src/routes/paths';
import CustomLink from '../../common/CustomLink';

type Props = {
  row: CaseManager;
};

export default function CaseRow({ row }: Props) {
  const {
    mrn,
    patient,
    phone,
    email,
    assignmentType,
    scope,
    step,
    status,
    id,
    internalNumber,
    subjectDOBFormatted,
    subjectAge,
    createdOnFormatted,
  } = row;

  const navigate = useNavigate();
  const location = useLocation();

  const handleOnRowDoubleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    const navigateType = id;
    navigate(`${location.pathname}/${navigateType}`, {
      replace: false,
    });
  };

  return (
    <TableRow>
      <TableCell>
        <CustomLink to={`${PATH_DASHBOARD.crs.case}/${id}`}>
          <Typography noWrap variant="subtitle2">
            {internalNumber}
          </Typography>
        </CustomLink>
      </TableCell>
      <TableCell>
        <Box display={'flex'} justifyContent={'row'}>
          <MedicalInformationIcon sx={{ marginRight: 1 }} fontSize={'small'} />
          <Typography variant="subtitle2">{mrn}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'row'}>
          <PersonIcon fontSize={'small'} sx={{ marginRight: 1 }} />
          <Typography variant="subtitle2">{patient}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'row'}>
          <ContactMailIcon sx={{ marginRight: 1 }} fontSize={'small'} />
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {email}
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'row'}>
          <ContactPhoneIcon sx={{ marginRight: 1 }} fontSize={'small'} />
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {phone}
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'row'}>
          <CakeIcon sx={{ marginRight: 1 }} fontSize={'small'} />
          <Typography noWrap variant="body2">
            {subjectDOBFormatted ? `${subjectDOBFormatted} (${subjectAge})` : null}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{assignmentType}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{scope}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{step}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{!!createdOnFormatted && createdOnFormatted}</Typography>
      </TableCell>
      <TableCell>
        <Label
          color={status === 'Completed' || status === 'Open' ? 'success' : 'error'}
          sx={{ textTransform: 'uppercase' }}
        >
          {status}
        </Label>
      </TableCell>
    </TableRow>
  );
}
