/* eslint-disable */
import Iconify from 'src/components/Iconify';
import { RHFSelect } from 'src/components/hook-form';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { WrappedValueSet } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { Box, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import StarIcon from '@mui/icons-material/Star';
import useLocales from 'src/hooks/useLocales';

type Props = {
  ethnicityCategories: WrappedValueSet | null;
}
const Ethnicity = ({ ethnicityCategories }: Props) => {
  const { i18n } = useLocales();
  const { control, setValue, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ethnicityArray',
  });
  const handleAddLanguage = () => {
    append({
      value: '',
      preferred: false,
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const values = watch();

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) return;

    const list = source.droppableId === 'ethnicityArray' ? values.ethnicityArray : values.email;

    const items = reorder(list, result.source.index, result?.destination?.index);

    //@ts-ignore
    setValue(source.droppableId, items);
  };
  return (
    <>
      <Stack direction="row" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography variant="body2">
          {i18n('patients.details.personalDetails.ethnicity', 'crs')}
        </Typography>
        <IconButton onClick={handleAddLanguage} sx={{ p: 0.5, ml: 1 }}>
          <div style={{
            width: "160px",
            height: "30px",
            padding: "6px 12px",
            borderRadius: "8px",
            backgroundColor: "#3360f7",
            backgroundSize: "cover",
            display: "flex",
            alignItems: "center",
            color: "#ffffff",
            fontSize: "0.9rem"
          }}>
            <Iconify icon={'eva:plus-fill'} />
            New Ethnicity
          </div>
        </IconButton>
      </Stack>
      <DragDropContext onDragEnd={onDragEnd}>

        {fields.map((item: any, index) => (
          <Droppable droppableId="ethnicityArray">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <Stack key={item.id} direction="row" sx={{ mb: 2 }}>
                        <Iconify icon={'material-symbols:drag-indicator'} width={32} height={50} />

                        <Box>
                          <RHFSelect style={{ width: "400px" }} name={`ethnicityArray.${index}.display`} label="" fullWidth={true}>
                            {ethnicityCategories?.asList().map((option: any) => (
                              <MenuItem key={option.code} value={option.display}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                  {option.display}
                                  {values.ethnicityArray[0].display === option?.display && (
                                    <div style={{
                                      width: "120px",
                                      height: "35px",
                                      padding: "6px 12px",
                                      borderRadius: "8px",
                                      backgroundColor: "#cafdf5",
                                      backgroundSize: "cover",
                                      display: "flex",
                                      alignItems: "center",
                                      color: "#006c9c",
                                    }}>
                                      <StarIcon fontSize='small' />Primary
                                    </div>
                                  )}
                                </div>

                              </MenuItem>
                            ))}
                          </RHFSelect>
                        </Box>
                        <IconButton
                          onClick={() => handleRemove(index)}
                          size="medium"
                          sx={{
                            '&:hover': {
                              background: 'none',
                            },
                          }}
                        >
                          <Iconify icon={'eva:trash-2-outline'} color="red" />
                        </IconButton>
                      </Stack>
                    </div>
                  )}
                </Draggable>
                {provided.placeholder}
              </div>
            )}
          </Droppable>


        ))}
      </DragDropContext>

    </>
  );
};

export default Ethnicity;
