import moment from 'moment';
import { Cake as CakeIcon, Person as PersonIcon } from '@mui/icons-material';
import { capitalCase } from 'change-case';
import { TableRow, TableCell, Typography, Chip, Checkbox } from '@mui/material';
import { PatientEngagementResponse } from 'src/engagement/patient/PatientService';
import Iconify from '../../../../components/Iconify';
import CustomLink from '../../../crs/common/CustomLink';
import { PATH_DASHBOARD } from '../../../../routes/paths';

type Props = {
  row: PatientEngagementResponse;
  selected: boolean;
  onSelectRow: VoidFunction;
};

export default function PatientEngagementRow({ row, selected, onSelectRow }: Props) {
  const {
    workflowStatus,
    workflowStageName,
    workflowScope,
    patientName,
    patientMrn,
    patientDOBFormatted,
    patientAge,
    workflowOwnedBy,
    workflowDispositionReason,
    workflowDispositionOn,
    patientEngagementId,
    careflowIdentifierPatientEngagement
  } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell>
        <Typography noWrap variant="body2">
          {careflowIdentifierPatientEngagement}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography noWrap variant="subtitle2">
          {workflowStatus === 'Open' ? (
            <Chip color="success" label={workflowStatus} />
          ) : workflowStatus === 'Close' ? (
            <Chip color="info" label={workflowStatus} />
          ) : (
            <Chip color="primary" label={workflowStatus} />
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap variant="body2">
          <CustomLink 
            to={`${PATH_DASHBOARD.engagementHub.patientEngagement}/${patientEngagementId}`}
          >
            {workflowScope}
          </CustomLink>
        </Typography>
        <Typography noWrap variant="body2">
          {workflowStageName}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle2">
          <Iconify
            icon="mdi:letter-m"
            sx={{ fontSize: '2.2rem', color: '#919eab', marginBottom: '-10px', marginLeft: '-7px' }}
          />
          <CustomLink to={PATH_DASHBOARD.crs.patient.details.forId(row.patientFhirUUID)}>
            {patientMrn}
          </CustomLink>
        </Typography>

        <Typography variant="subtitle2">
          <PersonIcon sx={{ marginRight: 1, marginBottom: '-2px' }} fontSize={'small'} />
          {patientName}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <CakeIcon
            sx={{ marginRight: 1, color: '#919eab', marginBottom: '-2px' }}
            fontSize={'small'}
          />
          {patientDOBFormatted ? `${patientDOBFormatted} (${patientAge})` : null}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2">
          {workflowOwnedBy ? capitalCase(workflowOwnedBy?.split('@')[0].replace('.', ' ')) : ''}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{workflowDispositionReason}</Typography>
        <Typography variant="body2">
          {workflowDispositionOn ? moment(workflowDispositionOn).format('MM/DD/YYYY') : ''}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
