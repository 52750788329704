import React, { useEffect } from 'react';
import {
  Typography,
  IconButton,
  Button,
  Grid,
  Tooltip,
  SxProps,
  Pagination,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  TextField,
  ButtonProps,
} from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridFooterContainer,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  GridRowId,
} from '@mui/x-data-grid';
import FilterDrawer, { FilterDrawerProps } from 'src/components/FilterDrawer';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import Iconify from 'src/components/Iconify';
import SearchTextField from 'src/components/SearchTextField';
import { spreadSxProp } from 'src/utils/cssStyles';
import './styles.css';
import { trimMultipleWhiteSpaces } from 'src/utils/string';
import useObjectState from 'src/hooks/useObjectState';
import { onSuccess } from 'src/@types/crs/case';
import { PermissionsBase } from 'src/contexts/PermissionsContext';
import usePermissionsContext from 'src/hooks/usePermissionsContext';
import { defaultRowsPerPageOptions } from 'src/sections/crs/constants';
export interface DataGridWithFiltersPermissions extends PermissionsBase {
  isAllowedToAdd: boolean;
}
export interface GridWithFilterProps<T> extends GridFilterDrawerProps<T> {
  onSuccessfulCreation: onSuccess;
  onSuccessfulEdit: onSuccess;
  showPagination?: boolean;
  isNestedGrid?: boolean;
}

export interface GridFilterProps {
  searchTextFieldValue: string | null;
  onSearchTextFieldChange: (value: string) => void;
  onFilterDrawerOpen: Function;
}

export interface GridFilterDrawerProps<T> extends GridFilterProps {
  isFilterDrawerOpen: boolean;
  onApplyFilters: (filters: Partial<T>) => void;
  onFilterDrawerClose: Function;
  filterValues: T;
}

export type OnVisibleRowsChange = (visibleRows: GridRowId[]) => void;
export type OnPageSizeChange = (newPageSize: number) => void;
export type OnPageInputChange = (pageInputValue: string) => void;
export type RowsPerPageOptions = number[];

export interface DataGridWithFiltersProps extends DataGridProps, Partial<GridFilterProps> {
  title?: string;
  showAddButton?: boolean;
  addButtonTitle: string;
  onAddButtonClick: ButtonProps['onClick'];
  addButtonIcon?: React.ReactNode;
  FilterDrawerProps?: FilterDrawerProps;
  Filters?: JSX.Element | null;
  showPagination?: boolean;
  onVisibleRowsChange?: OnVisibleRowsChange;
  containerSx?: SxProps;
  dataGridSx?: SxProps;
  searchTextFieldSx?: SxProps;
  rowsPerPageOptions?: RowsPerPageOptions;
  isNestedGrid?: boolean;
}

interface PaginationData {
  pageSize: number;
  pageInput: string;
}

const DataGridWithFilters = ({
  containerSx,
  showAddButton = true,
  addButtonTitle,
  title,
  onAddButtonClick,
  onFilterDrawerOpen,
  components,
  showPagination = true,
  searchTextFieldSx,
  dataGridSx,
  onSearchTextFieldChange,
  searchTextFieldValue,
  FilterDrawerProps = {} as FilterDrawerProps,
  Filters,
  onVisibleRowsChange,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  isNestedGrid,
  addButtonIcon,
  density = 'standard',
  ...props
}: DataGridWithFiltersProps) => {
  const isCompact = density === 'compact';
  const [{ pageSize, pageInput }, updatePaginationData] = useObjectState<PaginationData>({
    pageSize: rowsPerPageOptions[0],
    pageInput: '',
  });

  const onPageSizeChange: OnPageSizeChange = (newPageSize) => {
    updatePaginationData({ pageSize: newPageSize });
  };

  const onPageInputChage: OnPageInputChange = (pageInputValue) => {
    updatePaginationData({ pageInput: pageInputValue });
  };

  const { isAllowedToAdd } = usePermissionsContext<DataGridWithFiltersPermissions>() ?? {};

  return (
    <>
      <Box
        sx={[
          { width: '100%', height: '100%' },
          ...spreadSxProp(containerSx),
          isNestedGrid
            ? {
                border: 1,
                borderRadius: 2,
                borderColor: '#D8D8D8',
                boxShadow: '3px 2px 10px 5px rgba(145,158,171,0.31)',
                py: 2,
              }
            : {},
        ]}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Grid
            container
            mb={!isCompact ? 3 : 1.8}
            display={'flex'}
            alignItems={'center'}
            paddingX={3}
          >
            <Grid item xs={9}>
              {!!title && <Typography variant="h6">{title}</Typography>}
            </Grid>

            {!!isAllowedToAdd && showAddButton ? (
              <Grid item xs={3} display={'flex'} justifyContent={'flex-end'}>
                <Button
                  sx={{ height: '36px' }}
                  disabled={false}
                  onClick={onAddButtonClick}
                  variant="contained"
                  startIcon={addButtonIcon ? addButtonIcon : <Iconify icon={'eva:plus-fill'} />}
                >
                  {addButtonTitle}
                </Button>
              </Grid>
            ) : null}
          </Grid>
          {!!Filters && (
            <Box
              sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
              paddingLeft={3}
              paddingRight={3}
              mb={!isNestedGrid ? 3.4 : 1.8}
            >
              <SearchTextField
                value={searchTextFieldValue}
                onChange={(event) => {
                  onSearchTextFieldChange?.(trimMultipleWhiteSpaces(event.target.value));
                }}
                fullWidth
                sx={[{ marginRight: 2 }, ...spreadSxProp(searchTextFieldSx)]}
                InputProps={{ sx: isCompact ? { height: '40px' } : {} }}
              />
              <Tooltip title="Filter">
                <IconButton
                  onClick={() => {
                    onFilterDrawerOpen?.();
                  }}
                  sx={{ height: '40px' }}
                >
                  <FilterListIcon htmlColor="#919eab" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
        <DataGrid
          pageSize={showPagination ? pageSize : undefined}
          className="dataGridWithFilter"
          sx={[{ width: '100%' }, ...spreadSxProp(dataGridSx)]}
          isRowSelectable={() => false}
          disableColumnFilter
          disableColumnMenu
          density={isNestedGrid ? 'compact' : density}
          components={{
            ...(showPagination
              ? {
                  Footer: () => (
                    <CustomPagination
                      rowsPerPageOptions={rowsPerPageOptions}
                      pageInput={pageInput}
                      onPageInputChange={onPageInputChage}
                      onVisibleRowsChange={onVisibleRowsChange}
                      onPageSizeChange={onPageSizeChange}
                    />
                  ),
                }
              : { Footer: () => null, Pagination: () => null }),
            ...(components ?? {}),
          }}
          {...props}
        />
      </Box>
      {!!Filters && (
        <FilterDrawer anchor={'right'} {...FilterDrawerProps}>
          {Filters}
        </FilterDrawer>
      )}
    </>
  );
};

interface CustomPaginationProps {
  onVisibleRowsChange?: OnVisibleRowsChange;
  onPageSizeChange: OnPageSizeChange;
  pageInput: string;
  onPageInputChange: OnPageInputChange;
  rowsPerPageOptions: RowsPerPageOptions;
}

export const CustomPagination = ({
  pageInput,
  onVisibleRowsChange,
  onPageSizeChange,
  onPageInputChange,
  rowsPerPageOptions,
}: CustomPaginationProps): JSX.Element => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const visibleRows = useGridSelector(apiRef, gridPaginatedVisibleSortedGridRowIdsSelector);

  useEffect(() => {
    onVisibleRowsChange?.(visibleRows);
  }, [visibleRows, onVisibleRowsChange]);

  return (
    <GridFooterContainer
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-between',
        paddingTop: 2,
        paddingLeft: 3,
        paddingRight: 3,
        paddingBottom: 2,
      }}
    >
      <Box>
        <RowsPerPage onPageSizeChange={onPageSizeChange} rowsPerPageOptions={rowsPerPageOptions} />
      </Box>
      <Box>
        <Pagination
          shape="rounded"
          count={pageCount}
          page={page + 1}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
          showFirstButton
          showLastButton
        />
      </Box>
      <Box>
        <GoToPage pageInput={pageInput} onPageInputChange={onPageInputChange} />
      </Box>
    </GridFooterContainer>
  );
};

interface RowsPerPageProps {
  rowsPerPageOptions: RowsPerPageOptions;
  onPageSizeChange: OnPageSizeChange;
}

const RowsPerPage = ({ rowsPerPageOptions, onPageSizeChange }: RowsPerPageProps): JSX.Element => {
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  const handleChange = (event: SelectChangeEvent<number>) => {
    apiRef.current.setPageSize(Number(event.target.value));
    onPageSizeChange(Number(event.target.value));
  };

  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
      <Typography sx={{ typography: 'body2' }}>Show</Typography>

      <Select
        className="resultsPerPage"
        value={pageSize}
        onChange={handleChange}
        sx={{
          marginLeft: 1,
          width: 30,
          height: 30,
          typography: 'body2',
          fontWeight: 'bold',
        }}
      >
        {rowsPerPageOptions.map((value) => (
          <MenuItem key={String(value)} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
      <Typography sx={{ marginLeft: 1, typography: 'body2' }}>results per page</Typography>
    </Box>
  );
};

interface GoToPageProps {
  pageInput: string;
  onPageInputChange: OnPageInputChange;
}

const GoToPage = ({ pageInput, onPageInputChange }: GoToPageProps): JSX.Element => {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
      <Typography sx={{ typography: 'body2' }}>Go to Page</Typography>
      <TextField
        className="paginationInput"
        value={pageInput}
        type="number"
        InputProps={{
          sx: {
            width: '30px',
            height: '30px',
            typography: 'body2',
            fontWeight: 'bold',
          },
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onPageInputChange(event.target.value);
          const newPage = Number(event.target.value);
          if (newPage > pageCount || newPage < 1) return;
          apiRef.current.setPage(newPage - 1);
        }}
        sx={{
          marginLeft: 1,
        }}
      />
      <Typography sx={{ marginLeft: 1, typography: 'body2' }}>of {pageCount} </Typography>
    </Box>
  );
};

export default DataGridWithFilters;
