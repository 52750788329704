import { capitalCase } from 'change-case';
import { isObject } from 'lodash';

export const trimMultipleWhiteSpaces = (str: string): string => str.replace(/\s+/g, ' ');
export const capitalize = (str: string): string =>
  cleanSearchInput(str)
    .split(' ')
    .map((word) => {
      if (!word) return word;
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(' ');

export const transformScope = (str: string): string => {
  str = str.replace(/[._]/g, ' ');
  str = capitalCase(str);
  return str;
};

export const convertToQueryParams = (filters: any): string =>
  Object.entries(filters).reduce<string>((filterString, [filterKey, filterValue]) => {
    if ((Array.isArray(filterValue) && !filterValue.length) || !filterValue) {
      return filterString;
    }
    return (filterString += `&${filterKey}=${filterValue}`);
  }, '');

export const cleanSearchInput = (search: string): string => trimMultipleWhiteSpaces(search).trim();

export const containsSpecialCharacters = (str: string): boolean => {
  const specialCharactersPattern = /[^a-zA-Z0-9. ]/;

  return specialCharactersPattern.test(str);
};

export const isValidEmail = (email: string): boolean => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return emailPattern.test(email);
};

export const isValidJsonString = (str: string): boolean => {
  try {
    const parsed = JSON.parse(str);
    return isObject(parsed);
  } catch (e) {
    return false;
  }
}