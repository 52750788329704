import {
  Box,
  Card,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import _, { isArray } from 'lodash';
import moment from 'moment';
import agent from 'src/api/agent';
import Page from 'src/components/Page';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { capitalCase } from 'change-case';
import { mapContactAttempts } from './map';
import { getReferenceId, getValuesFromReference, setSILDisplay } from 'src/utils/fhir';
import useSettings from 'src/hooks/useSettings';
import EditIcon from '@mui/icons-material/Edit';
import NotesList from '../common/notes/NotesLists';
import CreateTask from '../../nat/task/CreateTask';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { patientService } from 'src/engagement/patient';
import Workflow from 'src/components/workflow/Workflow';
import { TableCustom } from 'src/components/TableCustom';
import { TABLE_HEAD_CONTACT } from '../common/table-head';
import KeyValueGrid from '../../../components/KeyValueGrid';
import { dateYearFormatWithAge } from 'src/utils/formatTime';
import TasksList from './components/workflow-step/ListTasks';
import { getParseColumn, mapDataAndDataSchemaWorkflowInstance } from 'src/utils/utilities';
import ExpandableCard from 'src/components/common/ExpandableCard';
import Input from 'src/components/workflow/common/components/Input';
import { TaskWrapper } from 'src/@nicheaim/fhir-base/wrappers/Task';
import ConfirmContact from './components/workflow-step/ConfirmContact';
import ConfirmNatOptIn from './components/workflow-step/ConfirmNatOptIn';
import ButtonType from 'src/components/workflow/common/components/Button';
import SelectType from 'src/components/workflow/common/components/Select';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ConsentWrapper, WrappedConsent } from 'src/@nicheaim/fhir-base/wrappers/Consent';
import ConfirmMemberInfo from './components/workflow-step/ConfirmMemberInfo';
import ScheduleNatSession from './components/workflow-step/ScheduleNatSession';
import ConfirmCMOptInItem from './components/workflow-step/ConfirmCMOptInItem';
import ReviewNatReportItem from './components/workflow-step/ReviewNatReportItem';
import { PatientEngagementResponse } from 'src/engagement/patient/PatientService';
import ConfirmGuarantorInfo from './components/workflow-step/ConfirmGuarantorInfo';
import DispositionType from 'src/components/workflow/common/components/Disposition';
import ComplementaryInfo from 'src/components/complementary-info/ComplementaryInfo';
import { RelatedPersonWrapper } from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import {
  useCareTeams,
  useConsents,
  useDocumentReferences,
  usePatient,
  usePractitionerRoles,
  useRelatedPersons,
  useServiceRequests,
  useTasks,
} from 'src/@nicheaim/fhir-react';
import useLocales from 'src/hooks/useLocales';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import {
  PatientEngagementPermissions,
  ResourceWithIncludedResources,
} from 'src/sections/crs/types';
import useTenantConfigData from 'src/hooks/useTenantConfigData';
import { ENGAGEMENT_WORKFLOW } from 'src/config';
import CustomSelectType from 'src/components/workflow/common/components/CustomSelect';
import EditType from 'src/components/workflow/common/components/Edit';
import ReferralInformation, {
  getDisplay,
  getReferred,
} from '../intake/components/workflow-step/ReferralInformation';
import { ServiceRequestWrapper } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { getRegistries } from 'src/services/api/registry';
import {
  Bundle,
  Consent,
  FhirResource,
  Organization,
  PractitionerRole,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getPractitionerRoleRelatedResources } from 'src/sections/crs/patient/components/Consent/ConsentGrid/ConsentGrid';
import { fhirClient } from 'src/App';
import { CareTeamMemberWithEditableData } from 'src/@types/crs/case';
import { CareTeamWrapper } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import { PractitionerRoleWrapper } from 'src/@nicheaim/fhir-base/wrappers/PractitionerRole';
import ProviderInformation, {
  getSelectOptionCustomButton,
  PayloadProvider,
} from '../intake/components/workflow-step/ProviderInformation';
import ConsentInformation, {
  getSelectOptionCustomButton as getConsentOption,
} from '../intake/components/workflow-step/ConsentInformation';
import produce from 'immer';
import InitialScreeningInterview from '../intake/components/workflow-step/InitialScreeningInterview';
import ConfirmScreeningInterview from '../intake/components/workflow-step/ConfirmScreeningInterview';
import { referralService } from 'src/crs/referral/services';
import { DocumentList } from 'src/sections/crs/patient/components/patientDocuments/DocumentList';
import { DocumentReferenceWrapper } from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import GeneralPractitionerList from 'src/sections/crs/patient/components/GeneralPractitionerList';
import { RelatedPersons } from 'src/sections/crs/patient/components/PatientRelatedPersons';
import PatientCase from 'src/sections/crs/patient/components/patientReferralCases/patientCase';
import CustomLink from 'src/sections/crs/common/CustomLink';
import ChartReview from '../intake/components/workflow-step/ChartReview';

const engagementType = ENGAGEMENT_WORKFLOW === 'INTAKE_ENGAGEMENT' ? 'INTAKE' : 'ENGAGEMENT';

export default function PatientEngagementDetails() {
  const { id: patientEngagementId = null } = useParams();
  const user = useAuth();
  const userEmail = user?.getCurrentUser?.()?.email;
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useLocales();
  const { componentsData } = useTenantConfigData();
  const { showOptInStatus } = componentsData ?? {};

  const [patientId, setPatientId] = useState('');
  const [patientEngagement, setPatientEngagement] = useState<
    PatientEngagementResponse | undefined
  >();
  const [contactAttempts, setContactAttempts] = useState<any>([]);
  const [allUsers, setAllUsers] = useState([]);

  const [workflowStage, setWorkflowStage] = useState(undefined);
  const [workflowInstance, setWorkflowInstance] = useState<any>();
  const [checklistData, setChecklistData] = useState<any>([]);
  const [reject, setReject] = useState(false);
  const [disposition, setDisposition] = useState<any>(false);
  const [externalRefreshWorkFlow, setExternalRefreshWorkFlow] = useState(false);
  const [intakeOption, setIntakeOption] = useState<any>([]);
  const [consents, setConsents] = useState<ResourceWithIncludedResources<WrappedConsent>[]>([]);
  const [refreshConsents, setRefreshConsents] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [confirmOptInItem, setConfirmOptInItem] = useState<any>(false);
  const [reviewNatReportItem, setReviewNatReportItem] = useState<any>(false);
  const [showTasksList, setShowTasksList] = useState<boolean>(false);
  const [confirmNatOptInModalOpen, setConfirmNatOptInModalOpen] = useState(false);
  const [scheduleNatSessionModalOpen, setScheduleNatSessionModalOpen] = useState(false);
  const [confirmContactModalOpen, setConfirmContactModalOpen] = useState(false);
  const [createTask, setCreateTask] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmGuarantorModalOpen, setConfirmGuarantorModalOpen] = useState(false);

  const [referralInfoModalOpen, setReferralInfoModalOpen] = useState(false);
  const [consentsModalOpen, setConsentsModalOpen] = useState(false);
  const [provideInformationModalOpen, setProvideInformationModalOpen] = useState(false);
  const [screeningInterviewModalOpen, setScreeningInterviewModalOpen] = useState(false);
  const [chartReviewModalOpen, setChartReviewModalOpen] = useState(false);
  const [confirmScreeningResponsesModalOpen, setConfirmScreeningResponsesModalOpen] =
    useState(false);

  const [isOpenReAssign, setIsOpenReAssign] = useState(false);

  const [referredSR, setReferredSR] = useState('');

  useEffect(() => {
    if (patientEngagement) {
      const patientIdFhir = patientEngagement.patientFhirUUID;
      if (patientIdFhir) {
        setPatientId(patientIdFhir);
      }
    }
  }, [patientEngagement]);

  const [
    patient,
    { isFetching: isPatientFetching, refresh: refreshPatient, update: updatePatient },
  ] = usePatient(patientId, {
    map: PatientWrapper,
    autofetch: !!patientId,
  });

  const [tasks, { refresh: refreshTask }] = useTasks({
    filter: {
      patient: patient?.id,
      code: 'completed-nat-assessment',
    },
    autofetch: !!patient?.id,
    map: TaskWrapper,
  });

  const [consent] = useConsents({
    filter: {
      patient: patient?.id,
    },
    autofetch: !!patient?.id,
    map: ConsentWrapper,
  });

  const [
    relatedPersons,
    { create: createRelatedPerson, update: updateRelatedPerson, refresh: refreshRelatedPerson },
  ] = useRelatedPersons({
    filter: {
      patient: patient?.id,
    },
    autofetch: !!patient,
    map: RelatedPersonWrapper,
  });

  const [serviceRequest, { refresh: refreshServiceRequest }] = useServiceRequests({
    filter: {
      category: 'intake-referral',
      code: 'enroll-to-sbha-program',
      subject: patient?.id,
    },
    map: ServiceRequestWrapper,
    autofetch: !!patientId,
  });

  const [
    careTeams,
    { isFetching: isCareTeamFetching, update: updateCareTeams, refresh: refreshCareTeams },
  ] = useCareTeams({
    filter: {
      subject: patient?.id,
    },
    map: CareTeamWrapper,
    autofetch: !!patient,
  });

  const practitionerRolesId = useMemo(
    () =>
      patient?.generalPractitioner
        ?.filter(({ reference }) => reference?.includes('PractitionerRole'))
        .map(({ reference }) => {
          const [_, id] = getValuesFromReference(reference);
          return id;
        }),
    [patient]
  );

  const [
    practitionerRoles,
    { isFetching: isPractitionerRoleFetching, refresh: refreshPractitionerRoles },
  ] = usePractitionerRoles({
    filter: {
      _id: practitionerRolesId?.join(','),
      active: true,
    },
    map: PractitionerRoleWrapper,
    autofetch: !!practitionerRolesId?.length,
  });

  const filteredPractitionerRoles = useMemo(() => {
    if (
      practitionerRoles &&
      isArray(practitionerRoles) &&
      practitionerRoles.length > 0 &&
      practitionerRolesId &&
      isArray(practitionerRolesId) &&
      practitionerRolesId.length > 0 &&
      patient?.generalPractitioner &&
      isArray(patient?.generalPractitioner) &&
      patient?.generalPractitioner.length > 0
    ) {
      const filtered = practitionerRoles.filter((x) => x?.id && practitionerRolesId.includes(x.id));
      return filtered;
    }
    return [];
  }, [practitionerRoles, practitionerRolesId, patient]);

  const [documentReferences, { refresh: refreshDocumentReference }] = useDocumentReferences({
    filter: { subject: patient?.id },
    map: DocumentReferenceWrapper,
    autofetch: !!patient,
  });

  const getPatientEngagementDetails = async (id: any) => {
    try {
      const resp = await patientService.patientApiClient.getOne(id);
      setPatientEngagement(resp);
    } catch (e) {
      console.log(`Something failed get patient engagement details ${e}`);
    }
  };

  useEffect(() => {
    getPatientEngagementDetails(patientEngagementId);

    return () => {
      setPatientEngagement(undefined);
    };
  }, [patientEngagementId]);

  const fetchStageFromWorkflow = async () => {
    try {
      const result = await agent.Workflow.getInstance(
        patientEngagement?.patientEngagementId,
        engagementType
      );
      setWorkflowInstance(result);
      setWorkflowStage(result?.stage?.id);
    } catch (error) {
      console.log('error', error);
    }
  };

  const fetchContactAttempt = async (patientEngagementId: string) => {
    const result: any = await patientService.getContactAttempt(patientEngagementId);
    setContactAttempts(result);
  };

  const fetchAllUsers = async () => {
    try {
      const getAllUsers = await agent.User.getAllUsers();
      setAllUsers(
        getAllUsers
          .sort((_a: any, _b: any) => {
            const a = (_a.firstName + ' ' + _a.lastName).toLowerCase(),
              b = (_b.firstName + ' ' + _b.lastName).toLowerCase();

            if (a < b) return -1;

            if (a > b) return 1;

            return 0;
          })
          .filter((e: any) => e.status === 'Active')
      );
    } catch (err) {
      console.log('error on fetching all users', err);
    }
  };

  useEffect(() => {
    fetchStageFromWorkflow();
    fetchContactAttempt(patientEngagement?.patientEngagementId || '');
  }, [patientEngagement, engagementType]);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    const getReferredFromServiceRequest = async () => {
      const { referred } = await getReferred(serviceRequest[0]);
      const stringReferred = referred ? getDisplay(referred) : '';
      setReferredSR(stringReferred);
    };

    getReferredFromServiceRequest();
  }, [serviceRequest]);

  const referralInfoItemData = useMemo(() => {
    return [
      {
        title: i18n('patientEngagement.details.workflow.workflowHandler.referred', 'engagement'),
        value: referredSR,
      },
      {
        title: i18n('patientEngagement.details.workflow.workflowHandler.cause', 'engagement'),
        value: serviceRequest?.[0]?.reasonCode?.[0]?.coding?.[0]?.display,
      },
    ];
  }, [referredSR]);

  const getIntakeOption = async () => {
    setIsLoading(true);
    const { data } = await getRegistries('intake', 'intake-option');
    const dataKeyValue = data?.[0]?.keyValue ? JSON.parse(data?.[0]?.keyValue) : null;
    setIntakeOption(dataKeyValue);
    setIsLoading(false);
  };

  useEffect(() => {
    getIntakeOption();
  }, []);

  const relatedProviders = useMemo(() => {
    const combinedResources: CareTeamMemberWithEditableData[] = [
      ...(careTeams
        ? careTeams.map((careTeam) => ({
            id: careTeam.id ?? '',
            associatedOrg: 'N/A',
            associatedOrgName: '',
            associatedOrgId: null,
            role: '',
            roleName: 'Care Team',
            roleId: 'care-team',
            name: careTeam.name ?? '',
            identifier: '',
            identifierType: '',
            memberType: careTeam.resourceType,
            startDate: '',
            endDate: '',
            startDateObj: null,
            endDateObj: null,
          }))
        : []),
      ...(filteredPractitionerRoles
        ? filteredPractitionerRoles.map((practitionerRole) => ({
            id: practitionerRole.id ?? '',
            associatedOrg: '',
            associatedOrgName: '',
            associatedOrgId: null,
            role: practitionerRole?.code?.[0]?.coding?.[0]?.display ?? '',
            roleName: practitionerRole?.code?.[0]?.coding?.[0]?.display ?? '',
            roleId: practitionerRole?.code?.[0]?.coding?.[0]?.code ?? '',
            name: practitionerRole?.practitioner?.display ?? '',
            identifier: '',
            identifierType: '',
            memberType: practitionerRole.resourceType,
            startDate: '',
            endDate: '',
            startDateObj: null,
            endDateObj: null,
          }))
        : []),
    ];
    return combinedResources;
  }, [careTeams, filteredPractitionerRoles]);

  const getConsents = useCallback(async (): Promise<
    ResourceWithIncludedResources<WrappedConsent>[]
  > => {
    if (!patient?.id) return [];
    try {
      const url = `Consent?patient=${patient.id}&_include=Consent:source-reference&_include=Consent:consentor&_include=Consent:organization&_count=1000`;
      const response = await fhirClient.get<Bundle>(url);
      if (!response?.entry?.length) return [];
      let entries = response.entry.reduce<FhirResource[]>((resources, { resource }) => {
        if (!resource) return resources;
        return [...resources, resource];
      }, []);
      const consents = entries.filter(
        ({ resourceType }) => resourceType === 'Consent'
      ) as Consent[];

      const practitionerRoleRelatedResources = await getPractitionerRoleRelatedResources(consents);

      entries = [...entries, ...practitionerRoleRelatedResources];

      return consents.reduce<ResourceWithIncludedResources<WrappedConsent>[]>(
        (resources, consentUnwrapped) => {
          if (!consentUnwrapped?.id) return resources;
          const consent = ConsentWrapper(consentUnwrapped as Consent);

          const documentReference = entries.find?.(
            ({ id }) => id === getReferenceId(consent?.sourceReference?.reference)
          );

          const organizations =
            consent?.organization?.reduce?.<Organization[]>((organizations, { reference }) => {
              const organization = entries.find?.(
                ({ id }) => id === getReferenceId(reference)
              ) as Organization;
              if (!organization) return organizations;
              return [...organizations, organization];
            }, []) ?? [];

          const performers =
            consent?.performer?.reduce?.<FhirResource[]>((resources, { reference }) => {
              const resource = entries.find?.(({ id }) => id === getReferenceId(reference));
              if (!resource) return resources;
              return [...resources, resource];
            }, []) ?? [];

          const prRelatedResources = performers.reduce<FhirResource[]>((resources, resource) => {
            if (resource?.resourceType !== 'PractitionerRole') return resources;
            const practitionerRole = resource as PractitionerRole;

            const organization = entries.find(
              ({ id }) => id === getReferenceId(practitionerRole?.organization?.reference)
            );

            const practitioner = entries.find(
              ({ id }) => id === getReferenceId(practitionerRole?.practitioner?.reference)
            );
            return [
              ...resources,
              ...(organization ? [organization] : []),
              ...(practitioner ? [practitioner] : []),
            ];
          }, []);

          return [
            ...resources,
            {
              resource: consent,
              includedResources: [
                ...(documentReference ? [documentReference] : []),
                ...organizations,
                ...performers,
                ...prRelatedResources,
              ],
            },
          ];
        },
        []
      );
    } catch (error) {
      return [];
    }
  }, [patient]);

  useEffect(() => {
    const getConsentsAsync = async () => {
      const consents = await getConsents();
      setConsents(consents);
      setRefreshConsents(false);
    };
    getConsentsAsync();
  }, [getConsents, refreshConsents]);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms || 300));
  }

  async function resetExternalRefreshWorkflow() {
    await sleep(1000);
    setExternalRefreshWorkFlow(false);
  }

  const createReferral = async (payload: any) => {
    const response = await referralService.createChildReferral(payload);
    return response;
  };

  useEffect(() => {
    if (externalRefreshWorkFlow) {
      resetExternalRefreshWorkflow();
    }
  }, [externalRefreshWorkFlow]);

  const engagementWorkflowData = useMemo(
    () => ({
      id: 1,
      recordId: patientEngagement?.patientEngagementId || '',
      recordReference: engagementType,
      stage: {
        id: workflowStage,
      },
      referralRecord: patientEngagement,
      workflowInstance,
    }),
    [workflowStage, workflowInstance]
  );

  const WorkflowComponentMapping: any = {
    Button: ButtonType,
    CustomSelect: CustomSelectType,
    Disposition: DispositionType,
    Edit: EditType,
    Input,
    Select: SelectType,
  };

  const WorkflowData: any = {
    disposition,
    reject,
  };

  const handlerDisposition = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setDisposition(true);
  };

  const handlerEngage = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmContactModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmMemberInfo = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationGuarantorInfo = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmGuarantorModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationNatOptIn = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmNatOptInModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationScheduleNatSession = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setScheduleNatSessionModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationReviewNat = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setReviewNatReportItem(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationCmOptIn = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setConfirmOptInItem(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmationInitiateNatRequest = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setCreateTask(true);
    setChecklistData(checklistItem);
  };

  const handlerConfirmCompleteNatInterview = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setShowTasksList(true);
    setChecklistData(checklistItem);
  };

  const handlerReject = async (open: any) => {
    setReject(open);
  };

  const providerInfoItemData = useMemo(() => {
    const { providers } = intakeOption;
    const data = getSelectOptionCustomButton(relatedProviders ?? [], providers ?? []);
    return data?.map((e) => ({
      title: e?.sub,
      value: e?.title ?? 'N/A',
    }));
  }, [relatedProviders, intakeOption]);

  const consentInfoItemData = useMemo(() => {
    const { consents: consentRegistry } = intakeOption;
    const data = getConsentOption(consents, consentRegistry);
    return data?.map((e) => ({
      title: e?.title,
      value: e?.sub,
    }));
  }, [consents, intakeOption]);

  const handlerAssign = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    const payloadForUpdateWorkflowinstance = {
      item: checklistItem,
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
      value: more.selectedUser,
      attribute_name: 'ownedBy',
    };

    try {
      const response = await agent.Record.updateRecordByAttribute(payloadForUpdateWorkflowinstance);

      const payloadForSetItemCompleted = {
        item: checklistItem,
        record: {
          ...response,
        },
      };

      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      refreshChecklistHandler();
    } catch (err) {
      enqueueSnackbar('An error has occurred', { variant: 'error' });
      console.log('error on trying to update patient engagement record');
    } finally {
      setIsOpenReAssign(false);
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }
    setExternalRefreshWorkFlow(false);
  };

  const handleReAssingOwned = async (ownedSelected: any) => {
    try {
      const payload = {
        record: {
          ...workflowInstance,
          assigned: {
            selectedUser: ownedSelected?.more?.selectedUser,
          },
        },
      };

      const result = await agent.Workflow.reAssignOwner(payload);
      if (result) {
        enqueueSnackbar('User was assigned');
      }
    } catch (err) {
      enqueueSnackbar('An error has occurred', { variant: 'error' });
      console.log('error on handleReAssingOwned');
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }
    setIsOpenReAssign(false);
    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmMember = async () => {
    const { data: dataWorkflowInstance, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      null,
      null,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType,
      data: dataWorkflowInstance,
      dataSchema: dataSchema,
    };

    const setCondition = JSON.parse(checklistData.conditions);
    const manualIndex = setCondition.data.findIndex((e) => e.type === 'Manual');
    setCondition.data[manualIndex].data = { confirm: true, message: 'Patient info confirmed' };
    const conditions = JSON.stringify(setCondition);

    const payloadForSetItemCompleted = {
      item: { ...checklistData, conditions },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }
    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmNATOptIn = async (data: any) => {
    const modifiedFields = {
      opt_out_decision: data.opt_out_decision,
      method: data.method,
    };

    const { data: dataWorkflowInstance, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      modifiedFields,
      null,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType,
      data: dataWorkflowInstance,
      dataSchema: dataSchema,
    };

    const setCondition = JSON.parse(checklistData.conditions);
    const manualIndex = setCondition.data.findIndex((e) => e.type === 'Manual');
    setCondition.data[manualIndex].data = { confirm: true, message: 'Confirmed HJ Opt-Out' };
    const conditions = JSON.stringify(setCondition);

    const payloadForSetItemCompleted = {
      item: { ...checklistData, conditions },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }
    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmGuarantor = async (data: any) => {
    const { data: dataWorkflowInstance, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      null,
      null,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType,
      data: dataWorkflowInstance,
      dataSchema: dataSchema,
    };

    const setCondition = JSON.parse(checklistData.conditions);
    const manualIndex = setCondition.data.findIndex((e) => e.type === 'Manual');
    setCondition.data[manualIndex].data = {
      confirm: true,
      message: 'Parent/Caregiver info confirmed',
    };
    const conditions = JSON.stringify(setCondition);

    const payloadForSetItemCompleted = {
      item: { ...checklistData, conditions },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      if (!data?.skip) {
        if (data?.id) {
          await updateRelatedPerson(data);
        } else {
          await createRelatedPerson(data);
        }
      }

      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
    refreshRelatedPerson();
  };

  const handlerConfirmScheduleHJSession = async (data: any) => {
    const modifiedFields = {
      date_hour: data.date_hour && new Date(data.date_hour).toISOString(),
      location: data.location,
    };

    const { data: dataWorkflowInstance, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      modifiedFields,
      null,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType,
      data: dataWorkflowInstance,
      dataSchema: dataSchema,
    };

    const setCondition = JSON.parse(checklistData.conditions);
    const manualIndex = setCondition.data.findIndex((e) => e.type === 'Manual');
    setCondition.data[manualIndex].data = {
      confirm: true,
      message: i18n('patientEngagement.details.title', 'engagement'),
    };
    const conditions = JSON.stringify(setCondition);

    const payloadForSetItemCompleted = {
      item: { ...checklistData, conditions },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmContactAttempt = async (data: any) => {
    let resultContactAttempt: any = {};

    const payloadForSetItemCompleted = {
      item: checklistData,
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    const workflowOutcomeDisposition: string | undefined =
      process.env.REACT_APP_WORKFLOW_OUTCOME_DISPOSITION;
    const outcomeDisposition: string[] = workflowOutcomeDisposition
      ? workflowOutcomeDisposition.split(',')
      : [];

    if (outcomeDisposition?.find((item: string) => item === data?.outcome)) {
      setReject(true);
      return;
    }

    try {
      resultContactAttempt = await patientService.createContactAttempt(data);
      if (!_.isEmpty(resultContactAttempt)) {
        await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
        fetchContactAttempt(patientEngagement?.patientEngagementId || '');
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);

    return resultContactAttempt;
  };

  const handlerInitiateHJRequest = async (data: any) => {
    const additionalParams = { patientId: patient?.id, orderId: data } ?? null;

    const { data: dataWorkflowInstance, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      null,
      additionalParams,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType,
      data: dataWorkflowInstance,
      dataSchema: dataSchema,
    };

    const payloadForSetItemCompleted = {
      item: checklistData,
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      if (!_.isEmpty(data)) {
        await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
        await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
    refreshTask();
  };

  const handlerCompleteHJRequest = async () => {
    setExternalRefreshWorkFlow(true);
    getPatientEngagementDetails(patientEngagementId);
    setExternalRefreshWorkFlow(false);
  };

  const handlerReviewHJRequest = async () => {
    const { data: dataWorkflowInstance, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      null,
      null,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType,
      data: dataWorkflowInstance,
      dataSchema: dataSchema,
    };

    const setCondition = JSON.parse(checklistData.conditions);
    const manualIndex = setCondition.data.findIndex((e) => e.type === 'Manual');
    setCondition.data[manualIndex].data = { confirm: true, message: 'HJ report reviewed' };
    const conditions = JSON.stringify(setCondition);

    const payloadForSetItemCompleted = {
      item: { ...checklistData, conditions },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
  };

  const handlerConfirmCMOptIN = async (data: any) => {
    const modifiedFields = {
      opt_out_decision: data.opt_out_decision,
      type: data.type,
      notes: data.notes,
    };

    const { data: dataWorkflowInstance, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      modifiedFields,
      null,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType,
      data: dataWorkflowInstance,
      dataSchema: dataSchema,
    };

    const setCondition = JSON.parse(checklistData.conditions);
    const manualIndex = setCondition.data.findIndex((e) => e.type === 'Manual');
    setCondition.data[manualIndex].data = { confirm: true, message: 'CM Opt-Out Confirmed' };
    const conditions = JSON.stringify(setCondition);

    const payloadForSetItemCompleted = {
      item: { ...checklistData, conditions },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
  };

  const handlerComplementaryInfo = async (store: any) => {
    const payloadForUpdateWorkflowinstance = {
      referenceId: workflowInstance?.referenceId,
      referenceName: workflowInstance?.referenceName,
      data: JSON.stringify({
        scope: {
          checklistItem: {
            ...store,
          },
        },
      }),
    };

    try {
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error on handleComplementaryInfo', err);
    } finally {
      setExternalRefreshWorkFlow(true);
    }

    setExternalRefreshWorkFlow(false);
  };

  const handlerReferralInfo = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setReferralInfoModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerConsents = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    itemCode,
    more,
  }: any) => {
    setConsentsModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerProviderInformation = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setProvideInformationModalOpen(true);
    setChecklistData(checklistItem);
  };

  const handlerScreeningInterview = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setScreeningInterviewModalOpen(true);
  };

  const handlerChartReview = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setChartReviewModalOpen(true);
  };

  const handlerConfirmScreeningResponses = ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    setConfirmScreeningResponsesModalOpen(true);
  };

  const handlerReferralInformation = async (data: any) => {
    setIsLoading(true);

    const additionalParams = { patientId: patient?.id } ?? null;

    const { data: dataWorkflowInstance, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      null,
      additionalParams,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType,
      data: dataWorkflowInstance,
      dataSchema: dataSchema,
    };

    const payloadForSetItemCompleted = {
      item: checklistData,
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      const referral = await createReferral(data);
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
      setIsLoading(false);
    }
    setExternalRefreshWorkFlow(false);
    refreshServiceRequest();
  };

  const handlerProvidersInformation = async (data: PayloadProvider) => {
    setIsLoading(true);
    const { generalPractitioner, addCareTeam, removeCareTeam, notApplicable } = data;
    if (generalPractitioner && generalPractitioner.length > 0) {
      await updatePatient(
        produce(patient!, (draft) => {
          draft.generalPractitioner = generalPractitioner.map((e) => ({ reference: e }));
        })
      );
    }

    if (addCareTeam || removeCareTeam) {
      const careTeamsArray = [...addCareTeam, ...removeCareTeam];
      const wrapper = careTeamsArray?.map((e) => ({
        resourceType: e?.resourceType,
        id: e?.id,
        category: e?.category,
        encounter: e?.encounter,
        identifier: e?.identifier,
        managingOrganization: e?.managingOrganization,
        name: e?.name,
        note: e?.note,
        participant: e?.participant,
        period: e?.period,
        reasonCode: e?.reasonCode,
        reasonReference: e?.reasonReference,
        status: e?.status,
        subject: e?.subject,
        telecom: e?.telecom,
      }));
      await updateCareTeams(wrapper);
    }

    const additionalParams = { notApplicable: notApplicable, patientId: patient?.id } ?? null;

    const { data: dataWorkflowInstance, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      null,
      additionalParams,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType,
      data: dataWorkflowInstance,
      dataSchema: dataSchema,
    };

    const setCondition = JSON.parse(checklistData.conditions);
    const manualIndex = setCondition.data.findIndex((e) => e.type === 'Manual');
    setCondition.data[manualIndex].data = {
      confirm: true,
      message: 'Confirmed',
      typeResponse: 'Edit',
    };
    const conditions = JSON.stringify(setCondition);

    const payloadForSetItemCompleted = {
      item: { ...checklistData, conditions },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      setProvideInformationModalOpen(false);
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setProvideInformationModalOpen(false);
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
      setIsLoading(false);
    }
    setExternalRefreshWorkFlow(false);
    refreshCareTeams();
    refreshPractitionerRoles();
    refreshPatient();
  };

  const handlerConsentInformation = async () => {
    setRefreshConsents(true);

    const additionalParams = patient ? { patientId: patient?.id } : null;

    const { data, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      null,
      additionalParams,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType,
      data: data,
      dataSchema: dataSchema,
    };

    const payloadForSetItemCompleted = {
      item: checklistData,
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
      setRefreshConsents(false);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }
    setExternalRefreshWorkFlow(false);
  };

  const handlerScreeningInterviewSave = async () => {
    const { data, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      null,
      null,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType,
      data: data,
      dataSchema: dataSchema,
    };

    const payloadForSetItemCompleted = {
      item: { ...checklistData },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
  };

  const handlerChartReviewSave = async () => {
    const { data, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      null,
      null,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType,
      data: data,
      dataSchema: dataSchema,
    };

    const payloadForSetItemCompleted = {
      item: { ...checklistData },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
  };

  const handlerValidateScreeningInterviewSave = async () => {
    const { data, dataSchema } = mapDataAndDataSchemaWorkflowInstance(
      workflowInstance,
      userEmail,
      null,
      null,
      checklistData.code
    );

    const payloadForUpdateWorkflowinstance = {
      referenceId: patientEngagement?.patientEngagementId,
      referenceName: engagementType,
      data: data,
      dataSchema: dataSchema,
    };

    const payloadForSetItemCompleted = {
      item: { ...checklistData },
      record: {
        ...patientEngagement,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      getPatientEngagementDetails(patientEngagementId);
    }

    setExternalRefreshWorkFlow(false);
  };

  const WorkflowHandler: any = {
    ASSIGN_OWNER_INITAL_PATIENT_ITEM: {
      type: 'payload',
      handler: handlerAssign,
      data: allUsers,
    },
    CONTACT_INITIAL_PATIENT_ITEM: {
      type: 'payload',
      handler: handlerEngage,
      data: true,
    },
    CONFIRM_MEMBER_INFO_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmMemberInfo,
      data: true,
    },
    CONFIRM_GUARANTOR_INFO_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationGuarantorInfo,
      data: true,
    },
    CONFIRM_NAT_OPTIN_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationNatOptIn,
      data: true,
    },
    SCHEDULE_NAT_SESSION_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationScheduleNatSession,
      data: true,
    },
    REVIEW_NAT_REPORT_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationReviewNat,
      data: true,
    },
    CONFIRM_CM_OPTIN_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationCmOptIn,
      data: true,
    },
    INITIATE_NAT_REQUEST_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationInitiateNatRequest,
      data: true,
    },
    COMPLETE_NAT_INTERVIEW_INITIAL_PATIENT_ITEM: {
      type: 'confirm',
      handler: handlerConfirmCompleteNatInterview,
      data: true,
    },
    ASSIGN_OWNER_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerAssign,
      data: allUsers,
    },
    REFERRAL_INFORMATION_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerReferralInfo,
      data: serviceRequest ? referralInfoItemData : true,
    },
    CONFIRM_MEMBER_INFO_INTAKE_ITEM: {
      type: 'confirm',
      handler: handlerConfirmMemberInfo,
      data: true,
    },
    CONFIRM_GUARANTOR_INFO_INTAKE_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationGuarantorInfo,
      data: true,
    },
    CONSENT_INFORMATION_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerConsents,
      data: consentInfoItemData ? consentInfoItemData : true,
    },
    PROVIDER_INFORMATION_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerProviderInformation,
      data: providerInfoItemData ? providerInfoItemData : true,
    },
    SCHEDULE_NAT_SESSION_INTAKE_ITEM: {
      type: 'confirm',
      handler: handlerConfirmationScheduleNatSession,
      data: true,
    },
    SCREENING_INTERVIEW_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerScreeningInterview,
      data: true,
    },
    CHART_REVIEW_ITEM: {
      type: 'payload',
      handler: handlerChartReview,
      data: true,
    },
    CONTACT_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerEngage,
      data: true,
    },
    CONFIRM_SCREENING_RESPONSES_INTAKE_ITEM: {
      type: 'payload',
      handler: handlerConfirmScreeningResponses,
      data: true,
    },
    setDisposition: {
      type: 'confirm',
      handler: handlerDisposition,
      data: false,
    },
    setReject: {
      type: 'confirm',
      handler: (open: boolean = true) => handlerReject(open),
      data: false,
    },
  };

  const defaultStructure = useMemo(() => {
    const checklistItem = getParseColumn(workflowInstance, 'data')?.scope?.checklistItem;

    return {
      ...(checklistItem?.CONFIRM_MEMBER_INFO_ITEM && {
        CONFIRM_MEMBER_INFO_ITEM: {
          ...(checklistItem?.CONFIRM_MEMBER_INFO_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(checklistItem?.CONFIRM_MEMBER_INFO_ITEM?.validatedOn)
              .format('MM/DD/YYYY HH:mm:ss'),
          }),
        },
      }),
      ...(checklistItem?.CONFIRM_NAT_OPTIN_ITEM && {
        CONFIRM_NAT_OPTIN_ITEM: {
          ...(checklistItem?.CONFIRM_NAT_OPTIN_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(checklistItem?.CONFIRM_NAT_OPTIN_ITEM?.validatedOn)
              .format('MM/DD/YYYY HH:mm:ss'),
          }),
        },
      }),
      ...(checklistItem?.CONFIRM_GUARANTOR_INFO_ITEM && {
        CONFIRM_GUARANTOR_INFO_ITEM: {
          ...(checklistItem?.CONFIRM_GUARANTOR_INFO_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(checklistItem?.CONFIRM_GUARANTOR_INFO_ITEM?.validatedOn)
              .format('MM/DD/YYYY HH:mm:ss'),
          }),
        },
      }),
      ...(checklistItem?.SCHEDULE_NAT_SESSION_ITEM && {
        SCHEDULE_NAT_SESSION_ITEM: {
          ...(checklistItem?.SCHEDULE_NAT_SESSION_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(checklistItem?.SCHEDULE_NAT_SESSION_ITEM?.validatedOn)
              .format('MM/DD/YYYY HH:mm:ss'),
          }),
          modifiedFields: {
            ...(checklistItem?.SCHEDULE_NAT_SESSION_ITEM?.modifiedFields?.date_hour && {
              date_hour: moment
                .utc(checklistItem?.SCHEDULE_NAT_SESSION_ITEM?.modifiedFields?.date_hour)
                .format('MM/DD/YYYY HH:mm:ss'),
            }),
          },
        },
      }),
      ...(checklistItem?.INITIATE_NAT_REQUEST_ITEM && {
        INITIATE_NAT_REQUEST_ITEM: {
          ...(checklistItem?.INITIATE_NAT_REQUEST_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(checklistItem?.INITIATE_NAT_REQUEST_ITEM?.validatedOn)
              .format('MM/DD/YYYY HH:mm:ss'),
          }),
        },
      }),
      ...(checklistItem?.REVIEW_NAT_REPORT_ITEM && {
        REVIEW_NAT_REPORT_ITEM: {
          ...(checklistItem?.REVIEW_NAT_REPORT_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(checklistItem?.REVIEW_NAT_REPORT_ITEM?.validatedOn)
              .format('MM/DD/YYYY HH:mm:ss'),
          }),
        },
      }),
      ...(checklistItem?.CONFIRM_CM_OPTIN_ITEM && {
        CONFIRM_CM_OPTIN_ITEM: {
          ...(checklistItem?.CONFIRM_CM_OPTIN_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(checklistItem?.CONFIRM_CM_OPTIN_ITEM?.validatedOn)
              .format('MM/DD/YYYY HH:mm:ss'),
          }),
        },
      }),
      ...(checklistItem?.REFERRAL_INFORMATION_ITEM && {
        REFERRAL_INFORMATION_ITEM: {
          ...(checklistItem?.REFERRAL_INFORMATION_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(checklistItem?.REFERRAL_INFORMATION_ITEM?.validatedOn)
              .format('MM/DD/YYYY HH:mm:ss'),
          }),
        },
      }),
      ...(checklistItem?.CONSENT_INFORMATION_ITEM && {
        CONSENT_INFORMATION_ITEM: {
          ...(checklistItem?.CONSENT_INFORMATION_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(checklistItem?.CONSENT_INFORMATION_ITEM?.validatedOn)
              .format('MM/DD/YYYY HH:mm:ss'),
          }),
        },
      }),
      ...(checklistItem?.PROVIDER_INFORMATION_ITEM && {
        PROVIDER_INFORMATION_ITEM: {
          ...(checklistItem?.PROVIDER_INFORMATION_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(checklistItem?.PROVIDER_INFORMATION_ITEM?.validatedOn)
              .format('MM/DD/YYYY HH:mm:ss'),
          }),
        },
      }),
      ...(checklistItem?.SCREENING_INTERVIEW_ITEM && {
        SCREENING_INTERVIEW_ITEM: {
          ...(checklistItem?.SCREENING_INTERVIEW_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(checklistItem?.SCREENING_INTERVIEW_ITEM?.validatedOn)
              .format('MM/DD/YYYY HH:mm:ss'),
          }),
        },
      }),
      ...(checklistItem?.CHART_REVIEW_ITEM && {
        SCREENING_INTERVIEW_ITEM: {
          ...(checklistItem?.CHART_REVIEW_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(checklistItem?.CHART_REVIEW_ITEM?.validatedOn)
              .format('MM/DD/YYYY HH:mm:ss'),
          }),
        },
      }),
      ...(checklistItem?.CONFIRM_SCREENING_RESPONSES_ITEM && {
        CONFIRM_SCREENING_RESPONSES_ITEM: {
          ...(checklistItem?.CONFIRM_SCREENING_RESPONSES_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(checklistItem?.CONFIRM_SCREENING_RESPONSES_ITEM?.validatedOn)
              .format('MM/DD/YYYY HH:mm:ss'),
          }),
        },
      }),
    };
  }, [workflowInstance]);

  const patientEngagementPermissions: PatientEngagementPermissions = useMemo(
    () => ({
      isAllowedToEdit: checkAclValidation({
        acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.EDIT],
      }),
      notes: {
        isAllowedToAdd: checkAclValidation({
          acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.NOTES.ADD],
        }),
        isAllowedToView: checkAclValidation({
          acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.VIEW],
        }),
      },
    }),
    []
  );

  const isAllLoading =
    isPatientFetching ||
    isCareTeamFetching ||
    isPractitionerRoleFetching ||
    isLoading ||
    externalRefreshWorkFlow ||
    refreshConsents;

  return (
    <Page title={i18n('patientEngagement.details.title', 'engagement')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          title={i18n('patientEngagement.details.title', 'engagement')}
          heading=""
          links={[
            { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
            { name: `${i18n('title', 'engagement')}` },
            {
              name: `${i18n('patientEngagement.title', 'engagement')}`,
              href: PATH_DASHBOARD.engagementHub.patientEngagement,
            },
            { name: `${i18n('patientEngagement.details.title', 'engagement')}` },
          ]}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Workflow
              data={engagementWorkflowData}
              refreshRecord={fetchStageFromWorkflow}
              initialWorkflow={ENGAGEMENT_WORKFLOW}
              workflowHandler={WorkflowHandler}
              workflowData={WorkflowData}
              componentMapping={WorkflowComponentMapping}
              refreshWorkFlowExternal={externalRefreshWorkFlow}
              i18Path="patientEngagement.details.workflow"
              namespace="engagement"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <ExpandableCard title={`${i18n('patients.title', 'crs')} Engagement`}>
              <Box sx={{ px: 3, pb: 2 }}>
                <KeyValueGrid label="Status" value={patientEngagement?.workflowStatus || ''} />
                <KeyValueGrid
                  label="Sub Status"
                  value={patientEngagement?.workflowStageName || ''}
                />
                <KeyValueGrid label="Language" value={patientEngagement?.patientLanguage || ''} />

                <Grid container item spacing={1} wrap="nowrap">
                  <Grid container item xs={4} sm={4} md={4} lg={4} xl={4} justifyContent="flex-end">
                    <Typography variant="body2" align="right">
                      Assigned To
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    zeroMinWidth
                    sx={{ alignItems: 'center' }}
                  >
                    <Tooltip
                      title={
                        patientEngagement?.workflowOwnedBy
                          ? capitalCase(
                              patientEngagement?.workflowOwnedBy?.split('@')[0].replace('.', ' ')
                            )
                          : ''
                      }
                    >
                      <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                        {patientEngagement?.workflowOwnedBy
                          ? capitalCase(
                              patientEngagement?.workflowOwnedBy?.split('@')[0].replace('.', ' ')
                            )
                          : ''}
                      </Typography>
                    </Tooltip>
                    {patientEngagementPermissions.isAllowedToEdit && (
                      <Tooltip title="Edit Assigned To" placement="bottom">
                        <EditIcon
                          className="font-small margin-xs-right-btn text-primary div-hand"
                          sx={{
                            color: '#1890FF',
                            cursor: 'pointer',
                            marginLeft: '8px',
                            fontSize: '1.2rem !important',
                          }}
                          onClick={() => setIsOpenReAssign(true)}
                        />
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>

                {showOptInStatus && (
                  <KeyValueGrid
                    label="Opt In Status"
                    value={
                      consent?.[0]?.getOptInStatus()
                        ? consent?.[0]?.getOptInStatus()
                        : 'Not available'
                    }
                  />
                )}

                <Grid container item justifyContent="center" alignItems="center">
                  <KeyValueGrid
                    label="Interview Status"
                    value={capitalCase(
                      tasks
                        ?.slice(-1)
                        ?.map((item) => item?.status)
                        ?.toString()
                    )}
                  />
                </Grid>
              </Box>
            </ExpandableCard>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <ExpandableCard title={i18n('patients.title', 'crs')}>
              <Box sx={{ px: 3, pb: 2 }}>
                {patient?.getMRN()?.value && (
                  <KeyValueGrid
                    label="MRN"
                    value=""
                    children={
                      <CustomLink
                        to={PATH_DASHBOARD.crs.patient.details.forId(patient?.id!)}
                        target="_blank"
                      >
                        <Typography noWrap variant="subtitle2">
                          {patient?.getMRN()?.value}
                        </Typography>
                      </CustomLink>
                    }
                  />
                )}
                <KeyValueGrid label="Given Name" value={patientEngagement?.patientGiven || ''} />
                <KeyValueGrid label="Family Name" value={patientEngagement?.patientFamily || ''} />
                <KeyValueGrid
                  label="DOB"
                  value={
                    (patientEngagement?.patientDOB &&
                      dateYearFormatWithAge(patientEngagement?.patientDOB)) ||
                    ''
                  }
                />
                {!_.isEmpty(patientEngagement?.patientLastSIL) && (
                  <KeyValueGrid
                    label="SIL"
                    value={
                      patientEngagement?.patientLastSIL
                        ? setSILDisplay(patientEngagement?.patientLastSIL)
                        : ''
                    }
                  />
                )}
              </Box>
            </ExpandableCard>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <ExpandableCard title="Complementary Info">
              <ComplementaryInfo
                dataSchema={getParseColumn(workflowInstance, 'dataSchema')}
                data={getParseColumn(workflowInstance, 'data')}
                defaultStructure={defaultStructure}
                handleComplementaryInfo={handlerComplementaryInfo}
                isAllowedToEdit={patientEngagementPermissions.isAllowedToEdit}
              />
            </ExpandableCard>
          </Grid>

          <Grid item xs={12}>
            <ExpandableCard title={i18n('patients.details.cases.title', 'crs')}>
              <PatientCase patientId={patientId} />
            </ExpandableCard>
          </Grid>

          <Grid item xs={12}>
            <ExpandableCard title="Contact Attempts">
              <TableCustom
                data={Array.isArray(contactAttempts) && mapContactAttempts(contactAttempts)}
                tableHead={TABLE_HEAD_CONTACT}
              />
            </ExpandableCard>
          </Grid>

          {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.GENERAL_PRACTITIONER.VIEW] }) && (
            <Grid item xs={12}>
              <ExpandableCard title={i18n('patients.generalPractitioners.title', 'crs')}>
                <GeneralPractitionerList patient={patient} />
              </ExpandableCard>
            </Grid>
          )}

          {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.RELATED_PERSONS.VIEW] }) && (
            <Grid item xs={12}>
              <ExpandableCard title={i18n('patients.details.relatedPersons.title', 'crs')}>
                <RelatedPersons patient={patient} />
              </ExpandableCard>
            </Grid>
          )}

          <Grid item xs={12}>
            <ExpandableCard title="Notes">
              {patientEngagementId && <NotesList engagementWorkflowData={engagementWorkflowData} />}
            </ExpandableCard>
          </Grid>

          {checkAclValidation({
            acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.ATTACHMENTS.VIEW],
          }) && (
            <Grid item xs={12}>
              <ExpandableCard title={i18n('patients.details.attachments.title', 'crs')}>
                <Card style={{ boxShadow: 'none', position: 'static' }}>
                  <DocumentList
                    permissions={{
                      isAllowedToAdd: checkAclValidation({
                        acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.ATTACHMENTS.ADD],
                      }),
                      isAllowedToEdit: checkAclValidation({
                        acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.ATTACHMENTS.EDIT],
                      }),
                    }}
                    patient={patient}
                    documentReferences={documentReferences}
                    refreshDocumentReference={refreshDocumentReference}
                    openCollapseExternal={true}
                    handleUpdateResource={null}
                  />
                </Card>
              </ExpandableCard>
            </Grid>
          )}

          <ConfirmMemberInfo
            isOpen={confirmModalOpen}
            handlerIsOpen={setConfirmModalOpen}
            patient={patient}
            handlePatient={updatePatient}
            handleConfirmMember={handlerConfirmMember}
            refreshPatient={refreshPatient}
          />

          <ConfirmGuarantorInfo
            patient={patient}
            isOpen={confirmGuarantorModalOpen}
            handlerIsOpen={setConfirmGuarantorModalOpen}
            relatedPerson={relatedPersons?.length ? relatedPersons[0] : null}
            handleRelatedPerson={handlerConfirmGuarantor}
          />

          <ConfirmNatOptIn
            isOpen={confirmNatOptInModalOpen}
            handlerIsOpen={setConfirmNatOptInModalOpen}
            handlerConfirmNATOptIn={handlerConfirmNATOptIn}
          />

          <ScheduleNatSession
            isOpen={scheduleNatSessionModalOpen}
            handlerIsOpen={setScheduleNatSessionModalOpen}
            handlerSchedulHJSession={handlerConfirmScheduleHJSession}
          />

          <ConfirmContact
            patient={patient}
            patientEngagementId={patientEngagementId || ''}
            isOpen={confirmContactModalOpen}
            handlerIsOpen={setConfirmContactModalOpen}
            handlePatient={updatePatient}
            refreshPatient={refreshPatient}
            handleConfirmContactAttempt={handlerConfirmContactAttempt}
          />

          <CreateTask
            isOpen={createTask}
            patient={patient}
            relatedPersons={relatedPersons}
            handlerIsOpen={setCreateTask}
            handleInitiateHJRequest={handlerInitiateHJRequest}
          />

          <TasksList
            patient={patient}
            tasks={tasks}
            refreshTasks={refreshTask}
            isOpen={showTasksList}
            handlerIsOpen={setShowTasksList}
            handleCompleteHJRequest={handlerCompleteHJRequest}
          />

          <ReviewNatReportItem
            patients={patient}
            isOpen={reviewNatReportItem}
            handlerIsOpen={setReviewNatReportItem}
            handleReviewHJRequest={handlerReviewHJRequest}
          />

          <ConfirmCMOptInItem
            isOpen={confirmOptInItem}
            handlerIsOpen={setConfirmOptInItem}
            handlerConfirmCMOptIN={handlerConfirmCMOptIN}
          />

          <ReferralInformation
            patient={patient}
            serviceRequest={serviceRequest[0]}
            open={referralInfoModalOpen}
            isLoading={isAllLoading}
            onClose={() => setReferralInfoModalOpen(false)}
            handlerSave={handlerReferralInformation}
          />

          <InitialScreeningInterview
            patient={patient}
            open={screeningInterviewModalOpen}
            onClose={() => {
              setExternalRefreshWorkFlow(true);
              setScreeningInterviewModalOpen(false);
            }}
            handlerSave={handlerScreeningInterviewSave}
            checkListData={checklistData}
          />

          <ChartReview
            patient={patient}
            open={chartReviewModalOpen}
            onClose={() => {
              setExternalRefreshWorkFlow(true);
              setChartReviewModalOpen(false);
            }}
            handlerSave={handlerChartReviewSave}
            checkListData={checklistData}
          />

          <ProviderInformation
            patient={patient}
            careTeams={careTeams}
            relatedProviders={relatedProviders}
            providerOption={intakeOption.providers}
            open={provideInformationModalOpen}
            isLoading={isAllLoading}
            workflowInstanceData={getParseColumn(workflowInstance, 'data')}
            onClose={() => setProvideInformationModalOpen(false)}
            handlerSave={handlerProvidersInformation}
          />

          <ConfirmScreeningInterview
            patient={patient}
            open={confirmScreeningResponsesModalOpen}
            onClose={() => {
              setExternalRefreshWorkFlow(true);
              setConfirmScreeningResponsesModalOpen(false);
            }}
            handlerSave={handlerValidateScreeningInterviewSave}
            checkListData={checklistData}
          />

          <ConsentInformation
            patient={patient}
            isLoading={isAllLoading}
            handlerSave={handlerConsentInformation}
            open={consentsModalOpen}
            consentOption={intakeOption.consents}
            consents={consents}
            onClose={() => setConsentsModalOpen(false)}
          />
        </Grid>

        <Dialog
          open={isOpenReAssign}
          onClose={() => setIsOpenReAssign(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              minHeight: '400px',
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">Re-Assign To</DialogTitle>
          <DialogContent>
            <SelectType
              others={{
                data: allUsers,
                patient: patientEngagement,
                handler: handleReAssingOwned,
                checklistItem: {},
              }}
            />
          </DialogContent>
        </Dialog>
      </Container>
    </Page>
  );
}
