import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import {
  ReactNode, createContext, useContext, useEffect, useRef, useState
} from 'react';
import {
  EXPIRE_SESSION_DIALOG_TIMEOUT_MS,
  TRIGGER_REFRESH_TOKEN_FLOW_BEFORE_EXP_SECONDS
} from 'src/config';
import useActivityListeners from 'src/hooks/useActivityListeners';
import { getCareflowToken } from 'src/utils/auth-utils';
import { getBackEndBaseUrl } from 'src/utils/domain-utils';
import { expireSession } from 'src/utils/permissions/get.user.permissions';
import RefreshAuthTokenDialog from './RefreshAuthTokenDialog';
import { useStandardDialog } from './StandardDialogProvider';

interface IRefreshAuthWatcherProviderContextData {}

const initialValue: IRefreshAuthWatcherProviderContextData = {};

const RefreshAuthWatcherProviderContext =
  createContext<IRefreshAuthWatcherProviderContextData>(initialValue);

export function useRefreshAuthWatcherBackdrop() {
  const {} = useContext<IRefreshAuthWatcherProviderContextData>(RefreshAuthWatcherProviderContext);
  return {};
}

interface ICounterData {
  start: boolean;
  tokenExp: number;
  refreshBefore: number;
  retry: number;
}

interface IRefreshAuthWatcherProviderProps {
  children: ReactNode;
  meta?: ReactNode;
  //   handleOnClose: () => void;
}

interface IRefreshTokenResponse {
  access_token: string;
  refresh_token: string;
  careflow_token: string;
}

async function refreshToken(): Promise<IRefreshTokenResponse | undefined> {
  const baseApiUrl = getBackEndBaseUrl();
  const refreshToken = Cookies.get('refreshToken');
  const careflowToken = getCareflowToken();

  if (!refreshToken || !careflowToken) {
    return;
  }

  let headers: any = {};
  let res;

  try {
    res = await axios.post<IRefreshTokenResponse>(
      baseApiUrl + '/auth/refresh-token',
      {
        careflow_token: careflowToken,
        refresh_token: refreshToken,
      },
      {
        headers: headers,
      }
    );
  } catch (e) {
    console.log(e);
  }

  if (res?.data?.access_token) {
    return res.data as IRefreshTokenResponse;
  }

  return undefined;
}

export default function RefreshAuthWatcherProvider({
  children,
  meta,
}: IRefreshAuthWatcherProviderProps) {
  const initalCounterData: ICounterData = {
    start: false,
    tokenExp: 0,
    refreshBefore:
      TRIGGER_REFRESH_TOKEN_FLOW_BEFORE_EXP_SECONDS > 0
        ? TRIGGER_REFRESH_TOKEN_FLOW_BEFORE_EXP_SECONDS * 1000
        : 20 * 1000, // 20 seconds
    retry: 0,
  };
  const [counterData, setCounterData] = useState<ICounterData>(initalCounterData);
  const firstStart = useRef(true);
  const tick = useRef<any>();
  const tickExpireSession = useRef<any>();
  const standardDialog = useStandardDialog();
  const activityListeners = useActivityListeners({
    afterMaxInactivityHandler: showRefreshTokenDialog,
  });

  useEffect(() => {
    proccessAuthToken();
    activityListeners.enable();
  }, []);

  useEffect(() => {
    if (firstStart.current) {
      firstStart.current = !firstStart.current;
      return;
    }

    const timeLeft = getTokenTimeLeftMili();

    if (counterData.start && timeLeft > 0) {
      setExpireTimeout(timeLeft);
      standardDialog.close();

      if (counterData.retry < 3) {
        const refreshTimeMili =
          timeLeft > counterData.refreshBefore ? timeLeft - counterData.refreshBefore : timeLeft;

        setRefreshTokenFlowTimeout(refreshTimeMili);
      }
    }

    return clearTimeouts;
  }, [counterData]);

  function clearTimeouts() {
    clearTimeout(tick.current);
    clearTimeout(tickExpireSession.current);
  }

  function getTokenTimeLeftMili() {
    const nowSeconds = moment.now() / 1000;

    // counterData.tokenExp is in seconds
    if (counterData.tokenExp < nowSeconds) {
      return 0;
    }

    const leftTimeSeconds = counterData.tokenExp - nowSeconds;
    return Math.floor(leftTimeSeconds * 1000);
  }

  function handleContinueSession() {
    standardDialog.close();
    activityListeners.enable();
  }

  function showRefreshTokenDialog() {
    if (
      window.location.pathname === '/select-tenant' ||
      window.location.pathname === '/session-expired'
    ) {
      return;
    }
    standardDialog.open(
      <RefreshAuthTokenDialog
        timeLeft={EXPIRE_SESSION_DIALOG_TIMEOUT_MS}
        handleContinueSession={handleContinueSession}
        handleExpireSession={handleExpire}
      />,
      'xs'
    );
  }

  function setRefreshTokenFlowTimeout(timeLeftMili: number) {
    if (tick.current !== undefined) {
      clearTimeout(tick.current);
    }

    tick.current = setTimeout(refreshFn, timeLeftMili);
  }

  async function handleExpire() {
    setCounterData((prev) => initalCounterData);
    standardDialog.close();
    await expireSession();
  }

  function setExpireTimeout(timeLeftMili: number) {
    if (tickExpireSession.current !== undefined) {
      clearTimeout(tickExpireSession.current);
    }

    tickExpireSession.current = setTimeout(handleExpire, timeLeftMili);
  }

  async function refreshFn() {
    const res = await refreshToken();

    if (res === undefined) {
      setCounterData((prev) => ({
        ...prev,
        retry: prev.retry + 1,
      }));
      throw new Error('No refresh token response valid');
    }

    const decodedAccessToken = jwtDecode<{ exp: number }>(res.access_token);
    const decodedRefreshToken = jwtDecode<{ exp: number }>(res.refresh_token);
    const decodedCareflowToken = jwtDecode<{ exp: number }>(res.careflow_token);
    const actualHostName = window.location.hostname; //dev-careflo2.mycare.site

    console.debug('DEBUG actualHostName:', actualHostName);

    Cookies.remove('accessCode', { path: '/' });
    const aCExpDate = new Date(decodedAccessToken.exp * 1000);
    Cookies.set('accessCode', res.access_token, {
      expires: aCExpDate,
      path: '/',
    });

    Cookies.remove('refreshToken', { path: '/' });
    const rTExpDate = new Date(decodedRefreshToken.exp * 1000);
    Cookies.set('refreshToken', res.refresh_token, {
      expires: rTExpDate,
      path: '/',
    });

    Cookies.remove('careflowToken', { path: '/' });
    const cTExpDate = new Date(decodedCareflowToken.exp * 1000);
    Cookies.set('careflowToken', res.careflow_token, {
      expires: cTExpDate,
      path: '/',
    });

    setCounterData((prev) => ({
      start: true,
      tokenExp: decodedAccessToken.exp,
      refreshBefore: prev.refreshBefore,
      retry: 0,
    }));
  }

  function proccessAuthToken() {
    // TODO: get token exp
    const accessToken = Cookies.get('accessCode');

    if (!accessToken) {
      return;
    }

    const decodedAccessToken = jwtDecode<{ exp: number }>(accessToken);

    setCounterData((prev) => ({
      start: true,
      tokenExp: decodedAccessToken.exp,
      refreshBefore: prev.refreshBefore,
      retry: prev.retry,
    }));
  }

  return (
    <RefreshAuthWatcherProviderContext.Provider value={{}}>
      <div>{children}</div>
    </RefreshAuthWatcherProviderContext.Provider>
  );
}
