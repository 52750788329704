//@ts-nocheck
import { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { FormProvider } from 'src/components/hook-form';
import { useEpisodeOfCares, useValueSet } from 'src/@nicheaim/fhir-react';
import { ValueSetComposeIncludeConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import useLocales from 'src/hooks/useLocales';
import DateTimePicker from 'src/components/DateTimePicker/DateTimePicker';
import { isTimeIncludedInDate } from 'src/utils/dates';
import { WrappedPractitionerRole } from '../../../../../../@nicheaim/fhir-base/wrappers/PractitionerRole';
import LoadableAutocomplete from 'src/components/LoadableAutocomplete';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

export enum EpisodeOfCareStatus {
  PLANNED = 'planned',
  WAITLIST = 'waitlist',
  ACTIVE = 'active',
  ONHOLD = 'onhold',
  FINISHED = 'finished',
  CANCELLED = 'cancelled',
  ENTERED_IN_ERROR = 'entered-in-error',
}

export enum EpisodeOfCareParticipantRequired {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
  INFORMATION_ONLY = 'information-only',
}

export enum EpisodeOfCareParticipantStatus {
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  TENTATIVE = 'tentative',
  NEEDS_ACTION = 'needs-action',
}

type FormValue = {
  status: ValueSetComposeIncludeConcept;
  start: string;
  end: string;
  episodeOfCareType: ValueSetComposeIncludeConcept;
};

type Props = {
  patient: WrappedPatient | null;
  practitionerRoles: WrappedPractitionerRole[];
  episodeOfCare: any | null;
  open: boolean;
  onClose: VoidFunction;
};

export function EpisodeOfCareForm({
  patient,
  practitionerRoles,
  episodeOfCare,
  open,
  onClose,
}: Props) {
  const { i18n } = useLocales();

  const { componentsData } = useTenantConfigData();
  const { defaultValues: tenantDefaultValues } = componentsData?.episodeOfCare ?? {};

  const [episodeOfCareStatusVS, { isLoading: isEpisodeOfCareStatusVSLoading }] = useValueSet(
    'episode-of-care-status',
    {
      map: ValueSetWrapper,
    }
  );

  const practitionerRoleOptions = useMemo(
    () =>
      practitionerRoles.map((x) => ({
        display: x?.getPractitionerName(),
        id: x?.id,
        resourceType: 'PractitionerRole',
      })),
    [practitionerRoles]
  );

  const EventSchema = Yup.object().shape({
    status: Yup.mixed().required('Status is required'),
    episodeOfCareType: Yup.mixed().required('Type is required'),
    end: Yup.date()
      .nullable()
      .transform((curr, orig) => (moment(orig).isValid() ? curr : null))
      .test('is-greater', 'End date must be greater than start date', function (value) {
        const startDate = moment(this.parent.start);
        const endDate = moment(value);
        if (!startDate.isValid() || !endDate.isValid()) return true;

        return startDate.isSameOrBefore(
          endDate,
          isTimeIncludedInDate(endDate) && isTimeIncludedInDate(startDate) ? undefined : 'date'
        );
      }),
  });
  const [types, { isLoading: isTypesLoading }] = useValueSet('episodeofcare-type', {
    map: ValueSetWrapper,
  });

  const defaultValues = useMemo(() => {
    const defaultType = types
      ?.asListAll?.()
      ?.find?.(({ code }) => code === tenantDefaultValues?.type?.code);
    const defaultEpisodeOfCare = episodeOfCare?.type?.[0]?.coding?.[0]
      ? episodeOfCare.type[0].coding[0]
      : defaultType
      ? defaultType
      : tenantDefaultValues?.type
      ? tenantDefaultValues?.type
      : null;
    const defaultCareManager = episodeOfCare?.careManager ? episodeOfCare?.careManager : null;
    const defaultStatus = episodeOfCare?.status
      ? episodeOfCareStatusVS
          ?.asListAll?.()
          ?.filter?.((x) => x?.code === episodeOfCare?.status)?.[0]
      : null;
    return {
      episodeOfCareType: defaultEpisodeOfCare,
      careManager: defaultCareManager,
      status: defaultStatus,
      start: episodeOfCare?.period?.start || '',
      end: episodeOfCare?.period?.end || '',
    } as FormValue;
  }, [episodeOfCare, tenantDefaultValues, types]);

  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [open, defaultValues]);

  const methods = useForm<FormValue>({ resolver: yupResolver(EventSchema) });

  const [, { create, update }] = useEpisodeOfCares({ autofetch: false });

  const [isTimeIncluded, setIsTimeIncluded] = useState(false);
  const [isTimeIncludedInEndInitially, setIsTimeIncludedInEndInitially] = useState(false);
  const [isTimeIncludedInStartInitially, setIsTimeIncludedInStartInitially] = useState(false);

  useEffect(() => {
    if (!episodeOfCare) return;
    setIsTimeIncludedInStartInitially(isTimeIncludedInDate(episodeOfCare?.period?.start ?? null));
    setIsTimeIncludedInEndInitially(isTimeIncludedInDate(episodeOfCare?.period?.end ?? null));
  }, [episodeOfCare]);

  const { reset, control, handleSubmit, formState } = methods;

  const onSubmit = async (dataForm: FormValue) => {
    if (episodeOfCare) {
      updateEpisodeOfCare(dataForm);
    } else {
      createEpisodeOfCare(dataForm);
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const createEpisodeOfCare = async (dataForm: any) => {
    await create({
      resourceType: 'EpisodeOfCare',
      patient: { reference: `Patient/${patient?.id}` },
      status: dataForm?.status?.code,
      careManager: dataForm?.careManager?.id
        ? {
            reference: `${dataForm?.careManager?.resourceType}/${dataForm?.careManager?.id}`,
            display: dataForm?.careManager?.display,
          }
        : undefined,
      type: [
        {
          coding: [
            {
              display: dataForm?.episodeOfCareType?.display,
              system: dataForm?.episodeOfCareType?.system,
              code: dataForm?.episodeOfCareType?.code,
            },
          ],
          text: dataForm?.episodeOfCareType?.display,
        },
      ],
      ...((dataForm?.start || dataForm?.end) && {
        period: {
          ...(dataForm?.start && { start: dataForm?.start }),
          ...(dataForm?.end && { end: dataForm?.end }),
        },
      }),
    })
      .then(() => {
        handleClose();
      })
      .catch(() => {});
  };

  const updateEpisodeOfCare = async (dataForm: any) => {
    await update({
      id: episodeOfCare?.id,
      resourceType: 'EpisodeOfCare',
      patient: { reference: `Patient/${patient?.id}` },
      status: dataForm?.status?.code,
      careManager: dataForm?.careManager?.id
        ? {
            reference: `${dataForm?.careManager?.resourceType}/${dataForm?.careManager?.id}`,
            display: dataForm?.careManager?.display,
          }
        : undefined,
      type: [
        {
          coding: [
            {
              display: dataForm?.episodeOfCareType?.display,
              system: dataForm?.episodeOfCareType?.system,
              code: dataForm?.episodeOfCareType?.code,
            },
          ],
          text: dataForm?.episodeOfCareType?.display,
        },
      ],
      period:
        dataForm?.start && dataForm?.end
          ? {
              start: moment(dataForm?.start).toISOString(),
              end: moment(dataForm?.end).toISOString(),
            }
          : undefined,
    })
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Dialog open={open} fullWidth={true} maxWidth="md">
      <DialogTitle>
        {episodeOfCare
          ? 'Edit Episode Of Care'
          : `${i18n('patients.details.episodeOfCare.titleAddEpisodeOfCare', 'crs')}`}{' '}
      </DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Controller
                  name={'episodeOfCareType'}
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    const { onChange, onBlur, value } = field;
                    return (
                      <>
                        <LoadableAutocomplete
                          fullWidth
                          label={'Type'}
                          isLoading={isTypesLoading}
                          value={value}
                          onChange={(_, newValue) => {
                            onChange(newValue);
                          }}
                          error={error?.message}
                          onBlur={onBlur}
                          options={types?.asListAll?.() ?? []}
                          getOptionLabel={({ display }: ValueSetComposeIncludeConcept) =>
                            display ?? ''
                          }
                        />
                      </>
                    );
                  }}
                />

                <Controller
                  name="status"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    const { onChange, onBlur, value } = field;
                    return (
                      <>
                        <LoadableAutocomplete
                          fullWidth
                          label={'Status'}
                          isLoading={isEpisodeOfCareStatusVSLoading}
                          value={value}
                          onChange={(_, newValue) => {
                            onChange(newValue);
                          }}
                          error={error?.message}
                          onBlur={onBlur}
                          options={episodeOfCareStatusVS?.asListAll?.() ?? []}
                          getOptionLabel={({ display }: ValueSetComposeIncludeConcept) =>
                            display ?? ''
                          }
                        />
                      </>
                    );
                  }}
                />

                <Controller
                  name={'careManager' as keyof FormValue}
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    const { onChange, onBlur, value } = field;
                    return (
                      <>
                        <Autocomplete
                          value={value}
                          onChange={(event, newValue) => {
                            onChange(newValue);
                          }}
                          onBlur={onBlur}
                          options={practitionerRoleOptions}
                          getOptionLabel={(option: any) => `${option?.display}`}
                          renderInput={(params) => (
                            <TextField
                              error={!!error}
                              helperText={error?.message}
                              label="Care Manager"
                              {...params}
                            />
                          )}
                        />
                      </>
                    );
                  }}
                />

                <Typography sx={{ ml: 1, mt: 0.7, py: 2 }}>
                  {i18n('patients.details.episodeOfCare.period', 'crs')}
                </Typography>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} sx={{ width: 1 }}>
                  <Controller
                    name="start"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <DateTimePicker
                          {...field}
                          textFieldProps={{ fullWidth: true }}
                          label={i18n('patients.details.episodeOfCare.start', 'crs')}
                          isTimeIncluded={
                            episodeOfCare ? isTimeIncludedInStartInitially : isTimeIncluded
                          }
                          onTimeInclusionChange={setIsTimeIncluded}
                          isTimeOptional
                          errorMessage={error?.message}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="end"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DateTimePicker
                        {...field}
                        textFieldProps={{ fullWidth: true }}
                        isTimeIncluded={
                          episodeOfCare ? isTimeIncludedInEndInitially : isTimeIncluded
                        }
                        label={i18n('patients.details.episodeOfCare.end', 'crs')}
                        isTimeOptional
                        errorMessage={error?.message}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />

              <Button variant="contained" color="info" onClick={handleClose}>
                {i18n('cancel')}
              </Button>

              <Button
                disabled={formState.isSubmitting}
                variant="contained"
                color="info"
                type="submit"
              >
                {i18n('submit')}
              </Button>
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
}
