import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  debounce,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import produce from 'immer';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/Iconify';
import { isBetweenDates } from 'src/utils/dates';
import CaseOutboundGrid from './CaseOutboundGrid';
import useObjectState from 'src/hooks/useObjectState';
import { useValueSets } from 'src/@nicheaim/fhir-react';
import React, { useEffect, useMemo, useState } from 'react';
import { ServiceRequestGridRowData } from 'src/@types/crs/case';
import useGridFilters, { GridFilters } from 'src/hooks/useGridFilters';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import SeverityStatus, { AlertSeverity } from 'src/components/SeverityStatus';
import { initialServiceRequestFilters, ServiceRequestFilters } from './OutboundGrid';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { getServiceRequestGridRows, searchIfContainedInObj } from 'src/sections/crs/helpers/common';
import {
  ServiceRequest,
  ValueSetComposeIncludeConcept,
} from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources/resources';
import { WrappedHealthcareService } from 'src/@nicheaim/fhir-base/wrappers/HealthcareService';
import Reference from 'yup/lib/Reference';

interface OutboundByPathwayProps {
  openCollapseExternal: boolean;
  patient: WrappedPatient | null;
  carePlan: WrappedCarePlan | null;
  healthCareServices: WrappedHealthcareService[];
  serviceRequest: WrappedServiceRequest[];
  refreshServiceRequest: any;
  createServiceRequest: any;
  updateServiceRequest: any;
}
interface OutboundByPathwayState {
  selectedPathway: string;
  filteredServiceRequestGridRows: ServiceRequestGridRowData[];
}

interface OutboundByPathway {
  id?: string | undefined;
  code: string | undefined;
  display?: string | undefined;
}

const pathwayAll: ValueSetComposeIncludeConcept = {
  code: 'all',
  display: 'All',
};

const OutboundByPathway = React.memo(
  ({
    serviceRequest,
    patient,
    carePlan,
    healthCareServices,
    refreshServiceRequest,
    createServiceRequest,
    updateServiceRequest,
    openCollapseExternal,
  }: OutboundByPathwayProps) => {
    const [{ selectedPathway }, updateState] = useObjectState<OutboundByPathwayState>({
      selectedPathway: 'all',
      filteredServiceRequestGridRows: [],
    });

    const {
      isFilterDrawerOpen,
      onFilterDrawerOpen,
      onFilterDrawerClose,
      onSearchTextFieldChange,
      searchTextFieldValue,
      filters,
      onApplyFilters,
    } = useGridFilters<ServiceRequestFilters>(initialServiceRequestFilters);

    const { enqueueSnackbar } = useSnackbar();

    const [, { isLoading: isPathWaysLoading }] = useValueSets({
      filter: { identifier: 'ph-path-ways' },
      map: ValueSetWrapper,
    });

    const outboundGridRows = useMemo(
      () => getServiceRequestGridRows(serviceRequest),
      [serviceRequest]
    );

    const handleFiltersChange = useMemo(
      () =>
        debounce(
          (
            searchValue: string,
            filters: ServiceRequestFilters,
            serviceRequestGridRows: ServiceRequestGridRowData[]
          ) => {
            updateState({
              filteredServiceRequestGridRows: filterServiceRequestGridRows(serviceRequestGridRows, {
                filters,
                searchTextFieldValue: searchValue,
              }),
            });
          },
          600
        ),
      [updateState]
    );

    useEffect(() => {
      handleFiltersChange(searchTextFieldValue ?? '', filters, outboundGridRows);
    }, [filters, updateState, outboundGridRows, handleFiltersChange, searchTextFieldValue]);

    const handlePathwayTabChange = (_: React.SyntheticEvent, pathway: string) => {
      updateState({
        selectedPathway: pathway,
      });
    };

    const getType = healthCareServices?.map((e) => ({
      id: e.id,
      code: e.program?.[0].coding?.[0].code,
      display: e.program?.[0].coding?.[0].display,
    }));

    const pathwaysList: OutboundByPathway[] = [pathwayAll, ...(getType ?? [])];

    const [openCollapse, setOpenCollapse] = useState(false);

    useEffect(() => {
      setOpenCollapse(openCollapseExternal);
    }, [openCollapseExternal]);

    const handleOutboundReferral = async (data: ServiceRequest) => {
      const id = data?.id;
      const isEditing = Boolean(id);

      let result: any = {};

      if (!isEditing) {
        const response = await createServiceRequest(data);
        result = response[0];
        if (result) enqueueSnackbar('Outbound Referral was created');
      } else if (isEditing) {
        result = await updateServiceRequest(
          produce(data!, (draft) => {
            draft.code = data.code;
            draft.reasonCode = data.reasonCode;
            draft.priority = data.priority;
            draft.performer = data.performer;
            draft.status = data.status;
            draft.occurrencePeriod = { end: data.occurrencePeriod?.end };
          })
        );

        enqueueSnackbar('Outbound Referral was updated');
      }

      refreshServiceRequest();

      return result;
    };

    return (
      <>
        <Card>
          {!isPathWaysLoading ? (
            <>
              <Stack sx={{ m: 2 }}>
                <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                  <Grid item xs={10}>
                    <Typography sx={{ ml: 1 }}>
                      Outbound Referrals
                      <IconButton onClick={() => setOpenCollapse(!openCollapse)}>
                        <Iconify
                          icon={
                            openCollapse
                              ? 'eva:arrow-ios-upward-fill'
                              : 'eva:arrow-ios-downward-fill'
                          }
                        />
                      </IconButton>
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Collapse in={openCollapse}>
                <Tabs
                  scrollButtons={'auto'}
                  variant={'scrollable'}
                  value={selectedPathway}
                  onChange={handlePathwayTabChange}
                  aria-label="PathWay Tab"
                  sx={{ ml: 2 }}
                >
                  {pathwaysList.map((pathway) => {
                    const { code, id } = pathway;
                    const getTasks = getServiceRequestGridRows(
                      getOutboundByPathWay(code, outboundGridRows, id)
                    );
                    const statuses = getStatusCountByPathway(getTasks);
                    return (
                      <Tab
                        key={code}
                        label={
                          <Box display={'flex'} flexDirection={'row'}>
                            <Typography sx={{ fontSize: '1em' }} variant="caption">
                              {code?.toUpperCase()}{' '}
                            </Typography>
                            {Object.entries(statuses).map(([severity, count]) => (
                              <SeverityStatusCount
                                key={severity}
                                count={count}
                                severity={severity as AlertSeverity}
                              />
                            ))}
                          </Box>
                        }
                        value={code}
                      />
                    );
                  })}
                </Tabs>
                <CardContent className={'cardContent'} sx={{ padding: 0 }}>
                  <Box sx={{ marginTop: 2, paddingBottom: 0 }}>
                    {pathwaysList.map((pathway) => {
                      const { code, id } = pathway;
                      const serviceRequestGridRowsByPathway = getOutboundByPathWay(
                        code,
                        outboundGridRows,
                        id
                      );
                      return (
                        <TabPanel key={code} pathway={code} selectedPathway={selectedPathway}>
                          <CaseOutboundGrid
                            serviceRequests={serviceRequestGridRowsByPathway}
                            patient={patient as WrappedPatient}
                            carePlan={carePlan}
                            healthCareServices={healthCareServices}
                            handleServiceRequest={handleOutboundReferral}
                            isFilterDrawerOpen={isFilterDrawerOpen}
                            onFilterDrawerOpen={onFilterDrawerOpen}
                            searchTextFieldValue={searchTextFieldValue}
                            onApplyFilters={onApplyFilters}
                            onFilterDrawerClose={onFilterDrawerClose}
                            onSearchTextFieldChange={onSearchTextFieldChange}
                            filterValues={filters}
                          />
                        </TabPanel>
                      );
                    })}
                  </Box>
                </CardContent>
              </Collapse>
            </>
          ) : (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                my: 2,
              }}
            >
              <CircularProgress size={50} />
            </Box>
          )}
        </Card>
      </>
    );
  }
);

interface TabPanelProps {
  children: React.ReactNode;
  selectedPathway: string;
  pathway: string | undefined;
}

const TabPanel = ({ children, selectedPathway, pathway }: TabPanelProps): JSX.Element => (
  <>{selectedPathway === pathway ? <>{children}</> : null}</>
);

type DueDateSeverities = AlertSeverity.SUCCESS | AlertSeverity.ERROR | AlertSeverity.WARNING;

export type StatusSeverityCount = Record<DueDateSeverities, number>;

const getStatusCountByPathway = (
  serviceRequests: ServiceRequestGridRowData[]
): StatusSeverityCount => {
  const statuses: StatusSeverityCount = {
    [AlertSeverity.ERROR]: 0,
    [AlertSeverity.WARNING]: 0,
    [AlertSeverity.SUCCESS]: 0,
  };
  for (const {
    dueTo: { severity },
  } of serviceRequests) {
    if (statuses.hasOwnProperty(severity as AlertSeverity))
      statuses[severity as DueDateSeverities]++;
  }
  return statuses;
};

const filterServiceRequestGridRows = (
  serviceRequestGridRows: ServiceRequestGridRowData[],
  { filters, searchTextFieldValue }: GridFilters<ServiceRequestFilters>
): ServiceRequestGridRowData[] => {
  let filteredServiceRequestGridRows = [...serviceRequestGridRows];
  const searchByString = searchTextFieldValue?.toLowerCase().trim() ?? '';
  if (searchByString.length >= 3) {
    filteredServiceRequestGridRows = filteredServiceRequestGridRows.filter(
      (serviceRequestGridRow) =>
        searchIfContainedInObj(serviceRequestGridRow, ['serviceReferral', 'status'], searchByString)
    );
  }
  const { startDate, endDate, status } = filters;

  return filteredServiceRequestGridRows.filter((serviceRequestRow) => {
    const serviceRequest = serviceRequestRow.wrappedServiceRequest;
    const serviceRequestStartDate = moment(serviceRequest?.authoredOn ?? null);
    const serviceRequestEndDate = moment(serviceRequest?.occurrencePeriod?.end ?? null);
    if (startDate?.isValid?.() && endDate?.isValid?.()) {
      if (!serviceRequestStartDate.isValid() || !serviceRequestEndDate.isValid()) return false;

      if (!isBetweenDates(serviceRequestStartDate, serviceRequestEndDate, startDate, endDate))
        return false;
    }

    if (startDate?.isValid?.()) {
      if (!serviceRequestStartDate.isValid()) return false;
      if (!serviceRequestStartDate.isSameOrAfter(startDate)) return false;
    }

    if (endDate?.isValid?.()) {
      if (!serviceRequestEndDate.isValid()) return false;
      if (!serviceRequestEndDate.isSameOrBefore(endDate)) return false;
    }

    if (status.length) {
      if (!status.find((status) => status.value === serviceRequest?.status)) return false;
    }
    return true;
  });
};

const getOutboundByPathWay = (
  pathwayCode: string | undefined,
  serviceRequest: ServiceRequestGridRowData[],
  healthCareServiceId: string | undefined
) => {
  if (!pathwayCode || pathwayCode === pathwayAll.code)
    return serviceRequest.map((e) => e.wrappedServiceRequest);
  return serviceRequest
    .filter(
      (item) =>
        !!item.wrappedServiceRequest?.performer?.find?.(
          ({ reference }) => reference === `HealthcareService/${healthCareServiceId}`
        )
    )
    .map((e) => e.wrappedServiceRequest);
};

interface SeverityStatusCountProps {
  count: number;
  severity: AlertSeverity;
}

const SeverityStatusCount = ({ count, severity }: SeverityStatusCountProps) => (
  <SeverityStatus status={{ message: String(count), severity }} sx={{ height: '20px', ml: 0.5 }} />
);

export default OutboundByPathway;
