// @ts-nocheck
import { Autocomplete, CircularProgress, TextField, debounce } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import { WrappedCareTeam } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import { OrganizationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Organization';
import { useOrganizations } from 'src/@nicheaim/fhir-react';
import { CRSGridProps } from 'src/@types/crs/case';
import CustomModal, {
  CustomModalBasicProps,
  GridItem,
  GridSection,
} from 'src/components/CustomModal';
import useLocales from 'src/hooks/useLocales';
import useObjectState from 'src/hooks/useObjectState';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import {
  Coding,
  Reference,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getReference, getValueSetConceptValue } from 'src/sections/crs/helpers/common';
import { createCareTeam, updateCareTeam } from 'src/services/api/case';
import useTenantConfigData, {
  CareTeamTenantConfigData,
} from '../../../../../hooks/useTenantConfigData';

interface CareTeamModalProps extends CustomModalBasicProps, CRSGridProps {
  careTeam: WrappedCareTeam | null;
  carePlan: WrappedCarePlan;
}

interface CareTeamFormState {
  isLoading: boolean;
  name: string | null;
  category: Coding | null;
  managingOrganization: Reference | null;
  status: CareTeamStatus | null;
  errorName: string | null;
  errorCategory: string | null;
  errorStatus: string | null;
}

export type CareTeamStatus = ValueSetComposeIncludeConcept;

const getInitialFormState = (
  careTeam: WrappedCareTeam | null,
  careTeamStatuses: CareTeamStatus[],
  categories: ValueSetComposeIncludeConcept[],
  tenantDefaultValues: CareTeamTenantConfigData
): CareTeamFormState => {
  // const defaultCategoryCoding =
  const defaultCategory = categories?.find?.(
    ({ code }) => code === tenantDefaultValues?.category?.code
  );

  const defaultStatus = tenantDefaultValues?.status
    ? getValueSetConceptValue(careTeamStatuses, tenantDefaultValues?.status)
    : null;

  const categoryCoding = careTeam?.category?.[0]?.coding?.[0];
  const orgReference = careTeam?.managingOrganization?.[0];

  return {
    isLoading: false,
    status: careTeam?.status
      ? getValueSetConceptValue(careTeamStatuses, careTeam?.status)
      : defaultStatus
      ? getValueSetConceptValue(careTeamStatuses, tenantDefaultValues?.status)
      : null,
    name: careTeam?.name ?? null,
    category: categoryCoding ? categoryCoding : defaultCategory ? defaultCategory : null,
    managingOrganization: orgReference
      ? { reference: orgReference?.reference, display: orgReference?.display }
      : null,
    errorName: null,
    errorCategory: null,
    errorStatus: null,
  };
};

const CareTeamModal = ({
  open,
  onClose,
  careTeam,
  carePlan,
  onSuccessfulCreation,
  onSuccessfulEdit,
  patient,
}: CareTeamModalProps) => {
  const { componentsData } = useTenantConfigData();
  const { defaultValues: tenantDefaultValues, readOnly: tenantReadOnlyValues } =
    componentsData?.careTeam ?? {};

  const {
    valueSets: [categories, careTeamStatuses],
  } = useValueSetsByIdentifiers(['ph-careteam-categories', 'ph-careteam-status']);

  const [
    {
      errorName,
      errorCategory,
      errorStatus,
      status,
      isLoading,
      category,
      name,
      managingOrganization,
    },
    updateState,
  ] = useObjectState<CareTeamFormState>({
    ...getInitialFormState(careTeam, [], categories?.asList() ?? [], tenantDefaultValues),
  });
  const [{ organizationFieldValue, organizationFilter }, updateOrganizationState] = useObjectState<{
    organizationFieldValue: string;
    organizationFilter: any;
  }>({ organizationFieldValue: '', organizationFilter: {} });

  useEffect(() => {
    if (!open) return;
    updateState({
      ...getInitialFormState(
        careTeam,
        careTeamStatuses?.asList?.() ?? [],
        categories?.asList() ?? [],
        tenantDefaultValues
      ),
    });
  }, [open, careTeam, careTeamStatuses, tenantDefaultValues]);

  const readOnlyFields = useMemo(
    () => (tenantReadOnlyValues ? tenantReadOnlyValues : []),
    [tenantReadOnlyValues]
  );

  const [
    organizations,
    {
      isLoading: isOrganizationsLoading,
      isFetching: isOrganizationsFetching,
      isRefetching: isOrganizationsRefetching,
    },
  ] = useOrganizations({
    map: OrganizationWrapper,
    filter: organizationFilter,
    pagination: {
      pageSize: 10,
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const organizationReferences: Reference[] = useMemo(
    (): Reference[] =>
      organizations?.map?.((organization) => ({
        reference: getReference(organization.resourceType, organization?.id ?? ''),
        display: organization?.name,
      })) ?? [],
    [organizations]
  );

  const handleOrganizationFilterChange = useCallback(
    debounce((value: string) => {
      updateOrganizationState({
        organizationFilter: value
          ? {
              name: value,
            }
          : null,
      });
    }, 800),
    []
  );

  const handleOnOrganizationFieldChange = (text: string) => {
    updateOrganizationState({
      organizationFieldValue: text,
    });
    handleOrganizationFilterChange(text);
  };

  const handleOnSave = async () => {
    const errors: Pick<CareTeamFormState, 'errorCategory' | 'errorName' | 'errorStatus'> = {
      errorCategory: null,
      errorName: null,
      errorStatus: null,
    };
    if (!name) errors.errorName = 'Must specify a valid name';
    if (!category) errors.errorCategory = 'Must specify a valid category';
    if (!status) errors.errorStatus = 'Must specify a valid status';
    updateState({ ...errors });
    if (errors.errorCategory || errors.errorName || errors.errorStatus) return;

    const isUpdating = !!careTeam;
    const payload = {
      name,
      status: status?.code ?? null,
      carePlanId: carePlan?.id,
      categoryName: category?.display ?? null,
      categoryId: category?.code ?? null,
      organizationId: managingOrganization?.reference ?? null,
      organizationName: managingOrganization?.display ?? null,
      ...(!isUpdating
        ? { patientId: `Patient/${patient?.id}`, patientName: patient?.getFullName() ?? '' }
        : {}),
    };
    const response = !isUpdating
      ? await createCareTeam(payload)
      : await updateCareTeam(payload, careTeam?.id as string);

    if (!response) {
      enqueueSnackbar("There's been an error. Please Try Again", { variant: 'error' });
      return;
    }
    enqueueSnackbar(`Care Team succesfully ${isUpdating ? 'updated' : 'created'}`);
    return response;
  };
  const { i18n } = useLocales();

  const title = `${i18n('patients.details.careteams.titleAddCareTeam', 'crs')}`;

  return (
    <CustomModal
      open={open}
      title={title}
      breadcrumbs={['List of Care Teams & Members', title]}
      onSave={async () => {
        updateState({ isLoading: true });
        const resource = await handleOnSave();
        updateState({ isLoading: false });
        if (!resource) return;
        !careTeam ? onSuccessfulCreation(resource) : onSuccessfulEdit(resource);
        onClose?.({}, 'backdropClick');
      }}
      onClose={onClose}
      onCancel={onClose as Function}
      isLoading={isLoading}
    >
      <>
        <GridSection>
          <GridItem xs={12}>
            <TextField
              disabled={isLoading}
              fullWidth
              error={!!errorName}
              helperText={errorName}
              label={i18n('patients.details.careteams.name', 'crs')}
              value={name}
              onChange={(event) => {
                updateState({ name: event.target.value });
              }}
              type="text"
              placeholder={i18n('patients.details.careteams.name', 'crs')}
              variant="outlined"
            />
          </GridItem>
        </GridSection>
        <GridSection>
          <GridItem xs={12}>
            <Autocomplete
              disabled={isLoading || readOnlyFields.includes('category')}
              readOnly={readOnlyFields.includes('category')}
              value={category}
              fullWidth
              onChange={(_: React.SyntheticEvent, category) => {
                updateState({
                  category,
                });
              }}
              options={(categories?.compose?.include?.[0]?.concept as Coding[]) ?? []}
              getOptionLabel={({ display }: Coding) => display ?? ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errorCategory}
                  helperText={errorCategory}
                  label={i18n('patients.details.careteams.category', 'crs')}
                  variant="outlined"
                />
              )}
            />
          </GridItem>
        </GridSection>
        <GridSection>
          <GridItem xs={12}>
            <Autocomplete
              disabled={isLoading}
              value={managingOrganization}
              fullWidth
              onChange={(_: React.SyntheticEvent, organization) => {
                updateState({
                  managingOrganization: organization,
                });
              }}
              onBlur={() => {
                handleOnOrganizationFieldChange('');
              }}
              filterOptions={(x) => x}
              options={organizationReferences}
              getOptionLabel={({ display }: Reference) => display ?? ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={organizationFieldValue}
                  label={i18n('patients.details.careteams.managingOrganization', 'crs')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const text = event.target.value;
                    handleOnOrganizationFieldChange(text);
                  }}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isOrganizationsLoading ||
                        isOrganizationsFetching ||
                        isOrganizationsRefetching ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </GridItem>
        </GridSection>
        <GridSection>
          <GridItem xs={12}>
            <Autocomplete
              disabled={isLoading || readOnlyFields.includes('status')}
              readOnly={readOnlyFields.includes('status')}
              value={status}
              fullWidth
              onChange={(_: React.SyntheticEvent, status) => {
                updateState({ status });
              }}
              options={careTeamStatuses?.asList?.() ?? []}
              getOptionLabel={({ display }: CareTeamStatus) => display ?? ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errorStatus}
                  helperText={errorStatus}
                  label={i18n('patients.details.careteams.status', 'crs')}
                  variant="outlined"
                />
              )}
            />
          </GridItem>
        </GridSection>
      </>
    </CustomModal>
  );
};

export default CareTeamModal;
