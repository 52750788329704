import { Autocomplete, CircularProgress, Grid, Stack, TextField, Typography } from "@mui/material";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { WrappedServiceRequest } from "src/@nicheaim/fhir-base/wrappers/ServiceRequest";
import CustomModal, { CustomModalBasicProps, GridItem, GridSection } from "src/components/CustomModal";
import useLocales from "src/hooks/useLocales";
import {
  TaskSelectedMemberOption, 
  TaskSelectedMemberValue, 
  getOwnerDisplayLabel
} from "src/sections/crs/case/components/TasksGrid/TaskModal";
import useObjectState from 'src/hooks/useObjectState';
import SearchMember, { MemberResourceTypeOption, SearchMemberProps } from "src/sections/crs/case/components/SearchMember/SearchMember";
import { IconWrapperStyle } from "src/sections/@dashboard/general/e-commerce/EcommerceWidgetSummary";
import useAuth from "src/hooks/useAuth";
import { useHealthcareServices, useOrganization, useValueSets } from "src/@nicheaim/fhir-react";
import { OrganizationWrapper, WrappedOrganization } from "src/@nicheaim/fhir-base/wrappers/Organization";
import { getFhirIdFromEntity } from "src/utils/fhir";
import { ActionData } from "src/sections/crs/case/components/GoalsGrid/GoalModal";
import { ValueSetWrapper, WrappedValueSet } from "src/@nicheaim/fhir-base/wrappers/ValueSet";
import { useEffect, useMemo } from "react";
import { Reference, ValueSetComposeIncludeConcept } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
import { getValueSetConceptValue } from "src/sections/crs/helpers/common";
import { isEmpty } from "lodash";
import { isValidJsonString } from "src/utils/string";
import { WrappedPractitionerRole } from "src/@nicheaim/fhir-base/wrappers/PractitionerRole";
import { WrappedPractitioner } from "src/@nicheaim/fhir-base/wrappers/Practitioner";
import { WrappedRelatedPerson } from "src/@nicheaim/fhir-base/wrappers/RelatedPerson";
import { getResourceByReference } from "src/sections/crs/helpers/indicent";
import { getFullName } from "src/sections/crs/common/common-utils";
import { HealthcareServiceWrapper } from "src/@nicheaim/fhir-base/wrappers/HealthcareService";

export type ReferralReferedOption = MemberResourceTypeOption;

interface ReferralInformationFormState {
  referred: TaskSelectedMemberValue | null;
  cause: ValueSetComposeIncludeConcept | null;
  referredDetails: string | null;
  causeDetails: string | null;
}

interface ReferralSelectedMemberState {
  isSearchMemberOpen: boolean;
  selectedReferred: TaskSelectedMemberOption | TaskSelectedMemberValue | null;
  referredOptions: (TaskSelectedMemberOption | TaskSelectedMemberValue)[];
};

interface ErrorFieldState {
  referralCause?: string | null;
  referralReferred?: string | null;
}

interface Props extends CustomModalBasicProps {
  patient: WrappedPatient | null;
  serviceRequest: WrappedServiceRequest | null;
  open: boolean;
  isLoading: boolean;
  handlerSave: (data:any) => Promise<any>;
};

export default function ReferralInformation({ patient, serviceRequest, open, isLoading, onClose, handlerSave }: Props) {

  const { i18n } = useLocales();
  const currentUser = useAuth().getCurrentUser();
  
  const title = [
  `${i18n('patientEngagement.details.title', 'engagement')}`,
  `${i18n('patientEngagement.details.workflow.step.assignCoordinator', 'engagement')}`,
  `${i18n('patientEngagement.details.workflow.checklist.referralInformation.title', 'engagement')}`];

  const [organization, { isFetching: isOrganizationFetching }] = useOrganization(
    getFhirIdFromEntity(currentUser?.organization_fhir_uri), {
    autofetch: currentUser?.organization_fhir_uri ? true : false,
    map: OrganizationWrapper,
  });

  const [healthCareService, { isFetching: isLoadingHealthcareServide }] = useHealthcareServices({
    filter: { 'service-type': 'enroll-to-sbha-program' },
    map: HealthcareServiceWrapper
  });

  const valueSetsIds = ['ph-service-request-category', 'ph-service-request-type', 'ph-reason-referral'];

  const [valueSets, { isFetching: isValueSetsFetching }] = useValueSets({
    filter: {
      _id: valueSetsIds.join(),
    },
    map: ValueSetWrapper,
  });

  const [serviceRequestCategory, serviceRequestType, reasonReferral] = useMemo(() => {
    if (!valueSets?.length) return [];

    return valueSetsIds.map((valueSetId) => valueSets.find(({ id }) => id === valueSetId) ?? null);
  }, [valueSets]);

  const setServiceRequestType = (serviceRequestType?.asListAll?.() ?? []).find(
    ({ code }) => code === 'enroll-to-sbha-program'
  );

  const setServiceRequestCategory = (serviceRequestCategory?.asListAll?.() ?? []).find(
    ({ code }) => code === 'intake-referral'
  );

  const [
    {
      referred,
      cause,
      referredDetails,
      causeDetails
    },
    updateState,
  ] = useObjectState<ReferralInformationFormState>(getInitialState({
    serviceRequest: null,
    reasonReferralValues: null
  }));

  const [
    {
      isSearchMemberOpen,
      selectedReferred,
      referredOptions
    },
    updateSelectedMember,
  ] = useObjectState<ReferralSelectedMemberState>(getInitialSelectedState(!!patient));

  const [errors, updateErrorState] = useObjectState<ErrorFieldState>({
    referralReferred: null,
    referralCause: null,
  });

  useEffect(() => {
    if (!open) return;

    const getReferredFromServiceRequest = async () => {
      const { referred } = await getReferred(serviceRequest);
      updateState({ referred: referred });
      updateSelectedMember({ selectedReferred: referred });
    };

    getReferredFromServiceRequest();

    updateState(
      getInitialState({
        serviceRequest,
        reasonReferralValues: reasonReferral
      })
    );
    updateErrorState({
      referralReferred: null,
      referralCause: null,
    });
    updateSelectedMember(getInitialSelectedState(!!patient));
  }, [open, serviceRequest, patient]);


  const handleOnSave = async () => {
    const errors: ErrorFieldState = {};
    if (isEmpty(cause) && isEmpty(referred)) {
      if(!referred) errors.referralReferred = 'Must specify a Referred';
      if(!cause) errors.referralCause = 'Must specify a Cause';
    }
   
    if (Object.keys(errors).length) {
      updateErrorState({ ...errors });
      return;
    }

    updateErrorState({
      referralReferred: null,
      referralCause: null,
    });
    
    const { actionCall, payload: serviceRequestData, successfulMessage } = mapServiceRequest();

    await handlerSave(serviceRequestData);
    onClose?.({}, 'backdropClick');
  };

  const mapServiceRequest = (): ActionData => ({
    payload: {
      id: serviceRequest?.id ?? null,
      ...(referred ? 
        { 
          referredFrom: {
          reference: `${referred?.resourceType}/${referred?.id}`,
          display: `${referredDetails ? `${referredDetails}` : ''}`
        }} : null),
      ...(cause ? {
        reasonCode: [
          {
            coding: [cause],
            text: `${causeDetails ? `${causeDetails}` : ''}`
          }
        ]
      }: null),
      category: [
        {
          coding: [setServiceRequestCategory],
          text: setServiceRequestCategory?.display
        }
      ],
      serviceType: {
        coding: [setServiceRequestType],
        text: setServiceRequestType?.display
      },
      intent: "directive",
      status: "active",
      patientId: patient?.id,
      patientName: patient?.getFullName(),
      serviceId: healthCareService?.[0]?.id ?? null,
      serviceName: healthCareService?.[0]?.name ?? null
    },
    actionCall: () => {},
    successfulMessage: '',
  });

  const isAllLoading = isLoading || isValueSetsFetching || isOrganizationFetching || isLoadingHealthcareServide;

  return(
    <CustomModal
      keepMounted
      open={open}
      title={title[2]}
      breadcrumbs={title}
      onSave={handleOnSave}
      onCancel={onClose as Function}
      onClose={onClose}
      isLoading={isAllLoading}
      containerSx={[{ overflow: 'scroll' }, isSearchMemberOpen ? { width: '94vw' } : {width: '70vw'}]}
    >
      <Grid container my={3} justifyContent={'space-between'}>
        <Grid item xs={isSearchMemberOpen ? 6 : 12}>
          <GridSection mt={0}>
            <Typography variant="caption" sx={{ color: 'gray'}}>
              {`${i18n('patientEngagement.details.workflow.checklist.referralInformation.comment', 'engagement')}`}
            </Typography>
          </GridSection>
          <GridSection>
            <GridItem>
              <Stack direction="row" alignItems="center">
                <IconWrapperStyle sx={{ bgcolor: 'green', color: 'white'}}>1 </IconWrapperStyle>
                <Typography variant="body2" component="span" noWrap sx={{ color: 'text.primary' }}>
                  &nbsp;{`${i18n('patientEngagement.details.workflow.checklist.referralInformation.question1', 'engagement')}`}
                </Typography>
              </Stack>
            </GridItem>
            <GridItem>
              <Stack direction="row" alignItems="center">
                <IconWrapperStyle sx={{ bgcolor: 'green', color: 'white'}}>2</IconWrapperStyle>
                <Typography variant="body2" component="span" noWrap sx={{ color: 'text.primary' }}>
                  &nbsp;{`${i18n('patientEngagement.details.workflow.checklist.referralInformation.question2', 'engagement')}`}
                </Typography>
              </Stack>
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <Autocomplete
                fullWidth
                disabled={isAllLoading || !patient}
                freeSolo
                value={selectedReferred}
                onChange={(_: React.SyntheticEvent, referredOption) => {
                  const optionAsValue = referredOption as TaskSelectedMemberValue;
                  const optionAsOption = referredOption as TaskSelectedMemberOption;

                  if (
                    !optionAsValue?.resourceType &&
                    optionAsOption?.value !== 'Patient' &&
                    optionAsOption && 
                    optionAsOption?.label !== 'Internal Referral'
                  ) {
                    updateSelectedMember({
                      isSearchMemberOpen: true,
                      selectedReferred: optionAsOption
                    });
                    return;
                  }
                  if (optionAsOption?.value === 'Patient') {
                    updateSelectedMember({
                      isSearchMemberOpen: false,
                      selectedReferred: patient
                    });
                    updateState({ referred: patient });
                    return;
                  }

                  if (
                    optionAsOption?.label === 'Internal Referral' &&
                    optionAsOption
                  ) {
                    updateSelectedMember({
                      isSearchMemberOpen: false,
                      selectedReferred: organization,
                    });
                    updateState({ referred: organization });
                    return;
                  }
                  updateSelectedMember({ selectedReferred: null });
                  updateState({ referred: null });
                }}
                options={referredOptions}
                getOptionLabel={(option) => {
                  const optionAsValue = option as TaskSelectedMemberValue;
                  const optionAsOption = option as TaskSelectedMemberOption;

                  if (!optionAsValue?.resourceType) return optionAsOption?.label as string;
                  let display = getDisplay(optionAsValue);
                  if (optionAsValue?.resourceType === 'Patient') {
                    display = `${display} (Me)`;
                  }else if(optionAsOption?.label === 'Internal Referral' && 
                    optionAsValue?.resourceType === 'Organization'
                  ){
                    display = `${display}`;
                  }else if(optionAsValue?.resourceType === 'RelatedPerson'){
                    display = `${display} ${optionAsValue?.relationship ? 
                      `(${optionAsValue?.relationship?.[0]?.coding?.[0]?.display})` : ''}`;
                  }
                  return display;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('patientEngagement.details.workflow.checklist.referralInformation.referred', 'engagement')}
                    placeholder={`Select ${i18n('patientEngagement.details.workflow.checklist.referralInformation.referred', 'engagement')}...`}
                    variant="outlined"
                    error={!!errors?.referralReferred}
                    helperText={errors?.referralReferred}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {!patient ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </GridItem>
            <GridItem>
              <Autocomplete
                disabled={isAllLoading}
                value={cause}
                fullWidth
                onChange={(_: React.SyntheticEvent, cause) => {
                  updateState({ cause });
                }}
                options={reasonReferral?.asListAll?.() ?? []}
                getOptionLabel={({ display }) => display as string}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors?.referralCause}
                    helperText={errors?.referralCause}
                    label={i18n('patientEngagement.details.workflow.checklist.referralInformation.cause', 'engagement')}
                    placeholder={`Select ${i18n('patientEngagement.details.workflow.checklist.referralInformation.cause', 'engagement')}...`}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <TextField
                fullWidth
                rows={4}
                multiline
                disabled={isAllLoading}
                value={referredDetails ?? ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updateState({
                    referredDetails: event.target.value,
                  });
                }}
                label={i18n('patientEngagement.details.workflow.checklist.referralInformation.details', 'engagement')}
                placeholder={`Write Some ${i18n('patientEngagement.details.workflow.checklist.referralInformation.details', 'engagement')}...`}
                variant="outlined"
              />
            </GridItem>
            <GridItem>
              <TextField
                fullWidth
                rows={4}
                multiline
                disabled={isAllLoading}
                value={causeDetails ?? ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  updateState({
                    causeDetails: event.target.value,
                  });
                }}
                label={i18n('patientEngagement.details.workflow.checklist.referralInformation.details', 'engagement')}
                placeholder={`Write Some ${i18n('patientEngagement.details.workflow.checklist.referralInformation.details', 'engagement')}...`}
                variant="outlined"
              />
            </GridItem>
          </GridSection>
        </Grid>
        {isSearchMemberOpen && (
          <Grid item xs={5.9}>
            <SearchMember
              patient={patient as WrappedPatient}
              onSelectResource={(resource) => {
                updateSelectedMember({ selectedReferred: resource, isSearchMemberOpen: false });
                updateState({ referred: resource });
              }}
              onClear={() => {
                updateSelectedMember({ selectedReferred: null, isSearchMemberOpen: false });
                updateState({ referred: null });
              }}
              externalResourceType={(() => {
                const selectedOption = selectedReferred as ReferralReferedOption;
                return selectedOption?.value as SearchMemberProps['externalResourceType'];
              })()}
            />
          </Grid>
        )}
      </Grid>
    </CustomModal>
  )
}

const getMemberResourceTypes = (showPatient: boolean = true): MemberResourceTypeOption[] => [
  { label: 'Internal Referral', value: 'Organization' },
  { label: 'External Referral', value: 'Organization' },
  { label: 'Parent / Guardian', value: 'RelatedPerson' },
  ...(showPatient ? [{ label: 'Self-Referral', value: 'Patient' } as MemberResourceTypeOption] : []),
];

export const getReferred = async (serviceRequest: WrappedServiceRequest | null) => {
  const getReferred: ReferralInformationFormState['referred'] = 
    await getWrappedByReference(serviceRequest?.requester);
  const referred  = getReferred;
  const referredDetails = serviceRequest?.requester?.display ?? null;

  return {referred, referredDetails};
};

const getInitialState = ({
  serviceRequest,
  reasonReferralValues, 
}: {
  serviceRequest: WrappedServiceRequest | null;
  reasonReferralValues: WrappedValueSet | null;
}): ReferralInformationFormState => {

  const referredDetails = serviceRequest?.requester?.display ?? null;
  const getReasonCode = serviceRequest?.reasonCode?.[0] ?? null;
  const cause  = getValueSetConceptValue(
    reasonReferralValues?.asListAll() ?? [], getReasonCode?.coding?.[0]?.code) ?? null;
  const causeDetails  = getReasonCode?.text ?? null;

  return {
    referred: null,
    cause,
    referredDetails,
    causeDetails
  };
};

const getInitialSelectedState = (
  showPatient: boolean
) => {
  return {
    isSearchMemberOpen: false,
    selectedReferred: null,
    referredOptions: [...getMemberResourceTypes(showPatient)]
  };
};

export const getDisplay = (option: TaskSelectedMemberValue): string => {
  const ownerDisplayLabel = getOwnerDisplayLabel(option);
  const validDisplay = isValidJsonString(ownerDisplayLabel);
  const display = validDisplay ? JSON.parse(ownerDisplayLabel)?.display : ownerDisplayLabel;
  return display;
};

export const getWrappedByReference = async (
  reference: Reference | undefined
): Promise<ReferralInformationFormState['referred']> => {
  if(!reference) return null;
  let referred: ReferralInformationFormState['referred'] = null;
  const resource = await getResourceByReference(reference?.reference ?? '');
  if (resource.id) {
    switch (resource.resourceType) {
      case 'Practitioner':
        referred = {
          id: resource.id,
          resourceType: resource.resourceType,
          getFullName: () => getFullName(resource.name ?? null) ?? ''
        } as WrappedPractitioner;
        break;
      case 'Patient':
        referred = {
          id: resource.id,
          resourceType: resource.resourceType,
          getFullName: () => getFullName(resource.name ?? null) ?? ''
        } as WrappedPatient;
        break;
      case 'PractitionerRole':
        referred = {
          id: resource.id,
          resourceType: resource.resourceType,
          getPractitionerName: () => 
            `${resource?.practitioner?.display} | ${resource?.code?.[0]?.coding?.[0]?.display}` ?? ''
        } as WrappedPractitionerRole;
        break;
      case 'Organization':
        referred = {
          id: resource.id,
          resourceType: resource.resourceType,
          name: resource?.name ?? '',
        } as WrappedOrganization;
        break;
      case 'RelatedPerson':
        referred = {
          id: resource.id,
          resourceType: resource.resourceType,
          getFullName: () => getFullName(resource.name ?? null) ?? ''
        } as WrappedRelatedPerson;
        break;
    }
  }
  return referred;
};