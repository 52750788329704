import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { filters } from 'src/@types/crs/incident';
import { incidentService } from 'src/crs/incident';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker, LoadingButton } from '@mui/lab';
import useObjectState from 'src/hooks/useObjectState';
import { getRegistries } from 'src/services/api/registry';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IncidentResponse } from 'src/crs/incident/IncidentService';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

interface DoFormIncidentProps {
  dataDoForm: any;
  incident: IncidentResponse | undefined;
  dataIncidentStatus: boolean;
}

export function DoFormIncident({
  incident,
  dataDoForm,
  dataIncidentStatus,
}: DoFormIncidentProps) {

  const { enqueueSnackbar } = useSnackbar();
  const [beneficiaries, setBeneficiaries] = useState<any>([]);
  const [notMatch, setNotMatch] = useState(false);
  const [lodingMatch, setLoadingMatch] = useState(false);

  const [{ firstName, middleName, lastName, date, medicaidId, ssn }, updateState] =
    useObjectState<filters>({
      firstName: '',
      middleName: '',
      lastName: '',
      date: null,
      medicaidId: '',
      ssn: '',
    });

  useEffect(() => {
    let linkRecordUuid: string = '';
    if (incident?.scope_data?.linked_record_uuid != '') {
      incident?.scope_data?.linked_records?.forEach((row: any) => {
        if (incident?.scope_data?.linked_record_uuid == row.uuid) {
          linkRecordUuid = row?.record?.record?.data?.uuid;
        }
      });
    }

    const beneficiaries: any =
      incident?.scope_data?.matchard_records?.map((item: any) => {
        return [item.record.record.uuid, item];
      }) || [];

    const benefiariesMap = [...new Map(beneficiaries).values()];

    var notExistMatch = false;
    benefiariesMap?.forEach((item: any) => {
      //matchard_records
      item.style = 'scope';
      item.link = true;
      if (item?.record?.record?.data?.uuid === linkRecordUuid) {
        item.link = true;
        notExistMatch = true;
      }
    });

    if (!notExistMatch) {
      incident?.scope_data?.linked_records?.forEach((row: any) => {
        if (row.uuid === incident?.scope_data?.linked_record_uuid) {
          row.link = false;
          row.style = 'match';
          benefiariesMap.unshift(row);
        }
      });
    }

    benefiariesMap?.sort(function (a: any, b: any) {
      return b.record.score * 100 - a.record.score * 100;
    });

    setBeneficiaries(benefiariesMap);
  }, [incident]);

  const fecthMatchRules = async () => {
    try {
      const { data } = await getRegistries(undefined, 'incident.beneficiaty.matcheng_rules');
      const dataKeyValue = data?.[0]?.keyValue ? JSON.parse(data?.[0]?.keyValue) : null;
      return dataKeyValue;
    } catch (e) {
      console.error(e);
    }
  };

  const handleMatchService = async () => {
    setLoadingMatch(true);

    const rules = await fecthMatchRules();

    const payload = {
      data: {
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        dob: date,
        rcp_idn: medicaidId,
        ssn_idn: ssn,
        gender: '',
        race: '',
      },
      ...rules,
    };

    try {
      const matchServiceResponse: any = await incidentService.getMatchService(payload);

      if (matchServiceResponse.matches.length === 0) {
        setNotMatch(true);
        setLoadingMatch(false);
        return;
      }

      const beneficiariesMatch: any =
        matchServiceResponse?.matches?.map((item: any) => {
          return [item.record.uuid, item];
        }) || [];

      const benefiariesMap = [...new Map(beneficiariesMatch).values()];

      let matches: any = [];
      benefiariesMap?.forEach((item: any) => {
        matches.push({
          link: true,
          style: 'match',
          record: item,
        });
      });

      matches.sort(function (a: any, b: any) {
        return b.record.score * 100 - a.record.score * 100;
      });

      if (matches?.length > 0) {
        const beneficiariesData = beneficiaries;

        const beneficiariesReturn: any =
          beneficiariesData?.filter((item: any) => {
            if (item.style !== 'match' || item.link === false) {
              return item;
            }
          }) || [];

        beneficiariesReturn.sort(function (a: any, b: any) {
          return b.score * 100 - a.score * 100;
        });

        const newBeneficiaries = matches.concat(beneficiariesReturn);
        setBeneficiaries(newBeneficiaries);
      }
    } catch (error) {
      enqueueSnackbar('Matching service is down. Please try again.', { variant: 'error' });
    }
    setLoadingMatch(false);
  };

  const onClearAllPress = () => {
    updateState({
      firstName: '',
      middleName: '',
      lastName: '',
      date: null,
      medicaidId: '',
      ssn: '',
    });
    const beneficiariesData = beneficiaries;

    const beneficiariesReturn: any =
      beneficiariesData?.filter((item: any) => {
        if (item.style === 'scope') {
          return item;
        }
      }) || [];

    setBeneficiaries(beneficiariesReturn);
    setNotMatch(false);
    setLoadingMatch(false);
  };

  const aclEditUser = checkAclValidation({ acls: [crsAcls.CRS.INCIDENT.EDIT] });

  return (
    <>
      <Grid item xs={12} md={3} lg={3} sx={{ mt: 2, display: 'block' }}>
        <Card>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ py: 2 }}
            >
              Self-Referral doForm Details
            </AccordionSummary>
            <Stack sx={{ m: 2 }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Questions</TableCell>
                      <TableCell>Response</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataDoForm &&
                      Object.entries(dataDoForm).map(([key, value]: any) => (
                        <TableRow key={key}>
                          <TableCell>{key}</TableCell>
                          {key != 'originalEntity' && <TableCell>{value}</TableCell>}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Accordion>
        </Card>
      </Grid>

      <Grid item xs={12} md={3} lg={3} sx={{ mt: 2, display: 'block' }}>
        <Card>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              sx={{ py: 2 }}
            >
              Found Matches
            </AccordionSummary>
            <Stack sx={{ m: 2 }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead sx={{ fontSize: '22px' }}>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Date of Birth</TableCell>
                      <TableCell>Medicaid</TableCell>
                      <TableCell>Social Security Number</TableCell>
                      <TableCell>Gender</TableCell>
                      <TableCell>Match %</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {beneficiaries?.length > 0 &&
                      beneficiaries?.map(
                        (row: any) =>
                          row?.style === 'scope' && (
                            <TableRow
                              sx={{
                                backgroundColor: row?.style === 'match' ? '#EDEFF1' : '#ffffff',
                              }}
                            >
                              <TableCell align="left">
                                <b>
                                  {row?.record?.record?.data?.first_name +
                                    ' ' +
                                    row?.record?.record?.data?.middle_name +
                                    ' ' +
                                    row?.record?.record?.data?.last_name}
                                </b>
                              </TableCell>
                              <TableCell align="left">{row?.record?.record?.data?.dob}</TableCell>
                              <TableCell align="left">
                                {row?.record?.record?.data?.rcp_idn}
                              </TableCell>
                              <TableCell align="left">
                                {row?.record?.record?.data?.ssn_idn}
                              </TableCell>
                              <TableCell align="left">
                                {row?.record?.record?.data?.gender == 'M' ? 'Male' : 'Female'}
                              </TableCell>
                              <TableCell>
                                {row?.record?.score && `${Math.round(row?.record?.score * 100)} %`}
                              </TableCell>
                              <TableCell>
                                {row?.link ? (
                                  <Button
                                    variant="contained"
                                    onClick={() => {}}
                                    disabled={dataIncidentStatus || aclEditUser}
                                  >
                                    Link
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    onClick={() => {}}
                                    disabled={dataIncidentStatus || aclEditUser}
                                  >
                                    UnLink
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Accordion>
        </Card>
      </Grid>

      <Grid item xs={12} md={3} lg={3} sx={{ mt: 2, display: 'block' }}>
        <Card>
          <Accordion disableGutters elevation={0} square>
            <AccordionSummary
              expandIcon={<SearchIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              sx={{
                py: 2,
                flexDirection: 'row-reverse',
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(90deg)',
                },
                '& .MuiAccordionSummary-content': {
                  marginLeft: 1,
                },
              }}
            >
              Search Beneficiary Master List
            </AccordionSummary>
            <AccordionDetails>
              <Card sx={{ p: 2 }}>
                <Grid container spacing={2} sx={{ pt: 1 }}>
                  <Grid item xs={4}>
                    <TextField
                      name="firstName"
                      value={firstName}
                      label="First Name"
                      defaultValue=""
                      fullWidth
                      onChange={(e) => updateState({ firstName: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="middleName"
                      value={middleName}
                      label="Middle Name"
                      defaultValue=""
                      fullWidth
                      onChange={(e) => updateState({ middleName: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="lastName"
                      value={lastName}
                      label="Last Name"
                      defaultValue=""
                      fullWidth
                      onChange={(e) => updateState({ lastName: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DatePicker
                      label="Date of Birth"
                      value={date}
                      onChange={(date) => updateState({ date: date })}
                      renderInput={(params) => (
                        <TextField label="Date of Birth" type="date" {...params} fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="medicaidId"
                      value={medicaidId}
                      label="Medicaid"
                      defaultValue=""
                      fullWidth
                      onChange={(e) => updateState({ medicaidId: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="ssn"
                      value={ssn}
                      label="Social Security Number"
                      defaultValue=""
                      fullWidth
                      onChange={(e) => updateState({ ssn: e.target.value })}
                    />
                  </Grid>

                  {notMatch && (
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Typography color="red">No results found</Typography>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Stack
                      spacing={1}
                      direction={{ sm: 'row' }}
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Button variant="contained" size="large" onClick={onClearAllPress}>
                        Reset
                      </Button>
                      <LoadingButton
                        variant="contained"
                        size="large"
                        loading={lodingMatch}
                        onClick={handleMatchService}
                      >
                        Search
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </AccordionDetails>
            <Stack sx={{ m: 2 }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead sx={{ fontSize: '22px' }}>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Date of Birth</TableCell>
                      <TableCell>Medicaid</TableCell>
                      <TableCell>Social Security Number</TableCell>
                      <TableCell>Gender</TableCell>
                      <TableCell>Match %</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {beneficiaries?.length > 0 &&
                      beneficiaries?.map(
                        (row: any) =>
                          row?.style === 'match' &&
                          row?.record && (
                            <TableRow>
                              <TableCell align="left">
                                <b>
                                  {row?.record?.record?.data?.first_name +
                                    ' ' +
                                    row?.record?.record?.data?.middle_name +
                                    ' ' +
                                    row?.record?.record?.data?.last_name}
                                </b>
                              </TableCell>
                              <TableCell align="left">{row?.record?.record?.data?.dob}</TableCell>
                              <TableCell align="left">
                                {row?.record?.record?.data?.rcp_idn}
                              </TableCell>
                              <TableCell align="left">
                                {row?.record?.record?.data?.ssn_idn}
                              </TableCell>
                              <TableCell align="left">
                                {row?.record?.record?.data?.gender == 'M' ? 'Male' : 'Female'}
                              </TableCell>
                              <TableCell>
                                {row?.record?.score && `${Math.round(row?.record?.score * 100)} %`}
                              </TableCell>
                              <TableCell>
                                {row?.link ? (
                                  <Button
                                    variant="contained"
                                    onClick={() => {}}
                                    disabled={dataIncidentStatus || aclEditUser}
                                  >
                                    Link
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    onClick={() => {}}
                                    disabled={dataIncidentStatus || aclEditUser}
                                  >
                                    UnLink
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Accordion>
        </Card>
      </Grid>
    </>
  );
}
