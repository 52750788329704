// @mui
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Page from '../components/Page';
// assets
import { useEffect, useState } from 'react';
import agent from 'src/api/agent';
import { RoleAclSimpleI, UserOrganizationSimpleI } from 'src/utils/auth-utils';
import { getBackEndBaseUrl } from 'src/utils/domain-utils';
import Unauthorized from './auth/Unauthorized';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function SelectOrgNRole() {
  const [orgList, setOrgList] = useState<UserOrganizationSimpleI[]>([]);
  const [roleList, setRoleList] = useState<RoleAclSimpleI[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<UserOrganizationSimpleI>();
  const [selectedRole, setSelectedRole] = useState<RoleAclSimpleI>();

  async function loadOrganizations() {
    const oList = await agent.User.getOrganizations();
    setOrgList(oList);
  }

  useEffect(() => {
    loadOrganizations();
  }, []);

  const handleChangeOrganization = (ev: any) => {
    const { value } = ev.target;
    const org = orgList.find((org: UserOrganizationSimpleI) => org.organizationCode === value);

    if (org !== undefined) {
      setSelectedOrg(org);
      setRoleList(org.roles);
    }
  };

  const handleChangeRole = (ev: any) => {
    const { value } = ev.target;
    const role = roleList.find((role: RoleAclSimpleI) => role.roleId === value);

    if (role !== undefined) {
      setSelectedRole(role);
    }
  };

  return (
    <Page title="Select Organization And Role">
      <Unauthorized show={orgList.length === 0} />
      <Container sx={{ display: orgList.length === 0 ? 'none' : 'inherit' }}>
        <ContentStyle sx={{ alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Please select your organization and role
          </Typography>

          <form
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            action={`${getBackEndBaseUrl()}/auth/redirect-home`}
            method="post"
            id="context-form"
          >
            <FormControl fullWidth>
              <InputLabel id="organization">Organization</InputLabel>
              <Select
                labelId="organization"
                label="organization"
                id="organization-select"
                name="organization-select"
                value={selectedOrg?.organizationCode || ''}
                onChange={handleChangeOrganization}
              >
                {(orgList ?? []).map((org: UserOrganizationSimpleI) => (
                  <MenuItem value={org.organizationCode} key={org.organizationCode}>
                    {org.organizationName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <br />

            <FormControl fullWidth>
              <InputLabel id="role">Role</InputLabel>
              <Select
                labelId="Role"
                id="role-select"
                name="role-select"
                value={selectedRole?.roleId || ''}
                onChange={handleChangeRole}
              >
                {(selectedOrg?.roles ?? []).map((role: RoleAclSimpleI) => (
                  <MenuItem value={role.roleId} key={role.roleCode}>
                    {role.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <br />

            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={!selectedOrg || !selectedRole}
            >
              Submit
            </Button>
          </form>
        </ContentStyle>
      </Container>
    </Page>
  );
}
