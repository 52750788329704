import {
  Cake as CakeIcon,
  Done,
  Event,
  EventAvailable,
  InfoOutlined,
  MedicalInformationOutlined,
  Person as PersonIcon,
} from '@mui/icons-material';
import Iconify from 'src/components/Iconify';
import { transformScope } from 'src/utils/string';
import { useNavigate, useLocation } from 'react-router-dom';
import { dateYearFormatWithAge } from 'src/utils/formatTime';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { IncidentResponse } from 'src/crs/incident/IncidentService';
import { getPatientField, statusStyle } from '../../helpers/indicent';
import { TableRow, TableCell, Typography, Chip, Checkbox } from '@mui/material';

type Props = {
  row: IncidentResponse;
  selected: boolean;
  onSelectRow: VoidFunction;
};

export default function IncidentRow({ 
  row,
  selected,
  onSelectRow, 
}: Props) {

  const navigate = useNavigate();
  const location = useLocation();

  const handleOnRowDoubleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    const navigateType = row?.uuid;
    navigate(`${location.pathname}/${navigateType}`, {
      replace: false,
    });
  };

  return (
    <TableRow hover selected={selected} onDoubleClick={handleOnRowDoubleClick}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <EventAvailable 
            sx={{ marginRight: 1, color: '#919eab', marginBottom: '-5px' }} 
            fontSize={'small'} 
          />
          {row?.createdOn && dateYearFormatWithAge(row.createdOn)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2">
          <PersonIcon 
            sx={{ marginRight: 1, marginBottom: '-3px' }} 
            fontSize={'small'} 
          /> 
          {row && 
            `${getPatientField(row,"Beneficiary_First_Name","text")} 
             ${getPatientField(row,"Beneficiary_Last_Name","text")}`.trim()}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <MedicalInformationOutlined 
            sx={{ marginRight: 1, color: '#919eab', marginBottom: '-3px' }} 
            fontSize={'small'} 
          />
          {row && getPatientField(row,"Beneficiary_Medicaid_ID","text")}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <CakeIcon 
            sx={{ marginRight: 1, color: '#919eab', marginBottom: '-3px' }} 
            fontSize={'small'} 
          />
          {row && getPatientField(row,"Beneficiary_Date_of_Birth","date")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap variant="subtitle2">
          <Chip label={row.status} sx={statusStyle(row.status)}/>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2">
          <PersonIcon 
            sx={{ marginRight: 1, marginBottom: '-3px' }} 
            fontSize={'small'} 
          /> 
          {row.assigned_to}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <Event 
            sx={{ marginRight: 1, color: '#919eab', marginBottom: '-3px' }} 
            fontSize={'small'} 
          />
          {row?.assigned_on && dateYearFormatWithAge(row.assigned_on)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2">
          <InfoOutlined 
            sx={{ marginRight: 1, marginBottom: '-3px' }} 
            fontSize={'small'} 
          /> 
          <Chip label={transformScope(row.scope_code)} sx={statusStyle('New')}/>
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <Iconify 
            icon="material-symbols:data-info-alert" 
            sx={{ marginRight: 1, color: '#919eab', marginBottom: '-3px', width: 22, height: 22 }} 
          />
          {row.scope_reason}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <FeedOutlinedIcon
            sx={{ marginRight: 1, color: '#919eab', marginBottom: '-3px' }} 
            fontSize={'small'} 
          />
          {row.scope_text}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2">
          <PersonIcon 
            sx={{ marginRight: 1, marginBottom: '-3px' }} 
            fontSize={'small'} 
          /> 
          {row.disposition_by}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <EventAvailable 
            sx={{ marginRight: 1, color: '#919eab', marginBottom: '-3px' }} 
            fontSize={'small'} 
          />
          {row?.disposition_on && dateYearFormatWithAge(`${row.disposition_on}`)}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          <Done 
            sx={{ marginRight: 1, color: '#919eab', marginBottom: '-3px' }} 
            fontSize={'small'} 
          />
          {row.disposition_outcome}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
