// routes
import { getRouter } from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { EventBusProvider } from './event-bus';
import { FhirClientProvider } from './@nicheaim/fhir-react';
import { createAxiosFhirClient } from './@nicheaim/fhir-base/clients/axios';
import axiosFhirInstance from './application/adapters/out/repositories/axiosFhirInstance';
import { DialogRenderer } from './stores/dialog';
import {
  getInitialPatientContext,
  PatientContext,
  PatientContextProvider,
} from './application/adapters/in/ui/contexts/PatientContext';
import { RouterProvider } from 'react-router';
import StandardLoadingBackdropProvider from './sections/careflow/common/StandardLoadingBackdropProvider';
import { createAxiosIndexedFhirClient } from './@nicheaim/fhir-base/clients/axios-indexed';
import axiosIndexedFhirInstance from './application/adapters/out/repositories/axiosIndexedFhirInstance';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import RefreshSSO from './sections/careflow/common/RefreshSSO';
import RefreshAuthWatcherProvider from './sections/careflow/common/RefreshAuthWatcherProvider';
import './crs/firebase/firebase-init';
import StandardDialogProvider from './sections/careflow/common/StandardDialogProvider';
import { useTenant } from './sections/careflow/common/TenantProvider';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export const fhirClient = createAxiosFhirClient(axiosFhirInstance);
export const indexedFhirClient = createAxiosIndexedFhirClient(
  axiosIndexedFhirInstance,
  axiosFhirInstance
);

const patientContext = ((): PatientContext => {
  const { patient, admissionDetails, careTeam } = getInitialPatientContext();
  return {
    patient: {
      ...patient,
      id: '81d5f8c4-f0bb-49ee-9ee2-183d30160dc2',
    },
    admissionDetails: {
      ...admissionDetails,
      id: '854c06de-af63-44dc-89e7-c7ffbac0e8ad',
    },
    careTeam: {
      ...careTeam,
      id: '078a5765-dfd7-41db-8d44-90ee7701c1c4',
    },
  };
})();

export default function App() {
  const tenant = useTenant();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(tenant.isLoading);
  }, [tenant.isLoading]);

  // if (tenant.isLoading) {
  //   return <>Loading...</>;
  // }

  function getMainRender() {
    const router = getRouter(tenant.frontendBaseUrl, tenant.backendBaseUrl);

    return (
      <ThemeProvider>
        <StandardDialogProvider>
          <RefreshAuthWatcherProvider>
            <RefreshSSO>
                <FhirClientProvider
                  clients={{
                    default: fhirClient,
                    indexed: indexedFhirClient,
                  }}
                >
                  <MotionLazyContainer>
                    <ThemeSettings>
                      <EventBusProvider>
                        <NotistackProvider>
                          <StandardLoadingBackdropProvider>
                            <DialogRenderer />
                            <ProgressBarStyle />
                            <ChartStyle />
                            <PatientContextProvider value={patientContext}>
                              <RouterProvider router={router} />
                            </PatientContextProvider>
                          </StandardLoadingBackdropProvider>
                        </NotistackProvider>
                      </EventBusProvider>
                    </ThemeSettings>
                  </MotionLazyContainer>
                </FhirClientProvider>
                <ReactQueryDevtools />
            </RefreshSSO>
          </RefreshAuthWatcherProvider>
        </StandardDialogProvider>
      </ThemeProvider>
    );
  }

  return tenant.isLoading ? <>Loading...</> : getMainRender();
}
