import moment from 'moment';
import { capitalCase } from 'change-case';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { TableRow, TableCell, Typography, Chip } from '@mui/material';
import { PatientEngagementResponse } from 'src/engagement/patient/PatientService';
import CustomLink from 'src/sections/crs/common/CustomLink';

type Props = {
  row: PatientEngagementResponse;
};

export default function Row({ 
  row
}: Props) {
  const {
    workflowStatus,
    workflowStageName,
    workflowScope,
    contactAttemptContactOn,
    workflowOwnedBy,
    workflowDispositionReason,
    workflowDispositionOn,
    patientEngagementId,
  } = row;

  return (
    <TableRow hover>
      <TableCell>
        <Typography noWrap variant="subtitle2">
          {workflowStatus === "Open" ? (
            <Chip color="success" label={workflowStatus} />
          ) : workflowStatus === "Close" ? (
            <Chip color="info" label={workflowStatus} />
          ) : (
            <Chip color="primary" label={workflowStatus} />
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap variant="body2">
          <CustomLink 
            to={`${PATH_DASHBOARD.engagementHub.patientEngagement}/${patientEngagementId}`}
          >
            {workflowScope}
          </CustomLink>
        </Typography>
        <Typography noWrap variant="body2">
          {workflowStageName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {contactAttemptContactOn ? 
            moment(contactAttemptContactOn).format("MM/DD/YYYY") : ""}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {workflowOwnedBy
            ? capitalCase(workflowOwnedBy?.split('@')[0].replace('.', ' ')) : ""}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{workflowDispositionReason}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {workflowDispositionOn
            ? moment(workflowDispositionOn).format("MM/DD/YYYY"): ""}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
