import { Container, Grid, Stack, Typography } from "@mui/material";
import { getFhirCookie } from "src/@nicheaim/fhir-base/clients/axios";
import { PatientWrapper } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { usePatient } from "src/@nicheaim/fhir-react";
import Page from "src/components/Page";
import useAuth from "src/hooks/useAuth";
import { getCareflowToken } from "src/utils/auth-utils";

export default function Home() {

  const [patient, { isFetching: isFetchingPatient }] = 
    usePatient('9842281d-ceec-4e7a-8afa-7cdeb8906034', {
    map: PatientWrapper,
  });

  const currentUser = useAuth().getCurrentUser();

  const careflowToken = getCareflowToken();
  
  const fhirToken = getFhirCookie();

  return  (
    <Page title="SBHA">
      <Container maxWidth={'xl'}>
        <Stack sx={{ m: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h4">Patient</Typography>
              <Typography>Name: {patient?.getFullName()}</Typography>
              <Typography>Date Of Birth: {patient?.getBirthDateForDisplay()}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4">Session Details</Typography>
              <Typography>Name: {currentUser?.name}</Typography>
              <Typography>User Name: {currentUser?.userName}</Typography>
              <Typography>User Id: {currentUser?.id}</Typography>
              <Typography>Practitioner Id: {currentUser?.user_fhir_uri}</Typography>
              <Typography>Role Id: {currentUser?.role}</Typography>
              <Typography>Organization Id: {currentUser?.organization}</Typography>
            </Grid>
          </Grid>
        </Stack>
      </Container>
   </Page>
  )
}