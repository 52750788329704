import { useEffect, useMemo } from 'react';
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { capitalize } from 'lodash';
import { useFormik } from 'formik';
import moment, { Moment } from 'moment';
import DatePickerMoment from 'src/components/DatePickerMoment';
import EditDrawer from '../EditDrawer';
import InputContainer from '../InputContainer';
import { genders } from '../../utils/constants';

import * as yup from 'yup';
import { Person } from '../../types';
import { getPersonNames } from '../../utils/personHelpers';

export type OnPersonDetailsSave = (personNewData: Partial<Person>) => Promise<void>;

export interface PersonDetailsEditDrawerProps {
  isOpen: boolean;
  onDrawerClose: () => void;
  person: Person | null;
  onPersonSave: (personNewData: Partial<Person>) => Promise<void>;
}

export interface PersonForm {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  birthDate: Moment | null;
}
const PersonDetailsEditDrawer = ({
  isOpen,
  onDrawerClose,
  person,
  onPersonSave,
}: PersonDetailsEditDrawerProps) => {
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .min(2, 'First Name must be at least 2 characters long')
      .required('First Name is Required'),
    lastName: yup
      .string()
      .min(2, 'Last Name must be at least 2 characters long')
      .required('Last Name is Required'),
    gender: yup.string().required('Gender is Required'),
  });
  const personNames = useMemo(() => getPersonNames(person?.name), [person]);
  const { firstName, middleName, lastName } = personNames;
  const formik = useFormik<PersonForm>({
    initialValues: {
      firstName,
      middleName,
      lastName,
      gender: person?.gender ?? '',
      birthDate: moment(person?.birthDate ?? null),
    },
    validationSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!isOpen) return;
    const { firstName, middleName, lastName } = personNames;
    formik.setValues({
      firstName,
      middleName,
      lastName,
      gender: person?.gender ?? '',
      birthDate: moment(person?.birthDate ?? null),
    });
  }, [person, isOpen, personNames]);

  const handleOnSave = async () => {
    const { birthDate } = formik.values;
    if (!birthDate?.isValid?.()) {
      formik.setFieldError('birthDate', 'Must specify a valid Date of Birth');
      return;
    }
    if (birthDate.isSameOrAfter(moment(), 'date')) {
      formik.setFieldError('birthDate', "Date of Birth can't be today nor a date in the future");
      return;
    }
    formik.handleSubmit();
    if (Object.entries(formik.errors).length) return;
    await onPersonSave({
      name: [
        {
          family: formik.values.lastName,
          given: [
            formik.values.firstName,
            ...(formik.values.middleName.trim().length ? [formik.values.middleName.trim()] : []),
          ],
        },
      ],
      gender: formik.values.gender,
      birthDate: formik.values.birthDate!.format('YYYY-MM-DD'),
    });
  };

  return (
    <EditDrawer
      title={"Edit Person's Details"}
      open={isOpen}
      onCloseIconButtonClick={onDrawerClose}
      onSaveButtonClick={handleOnSave}
    >
      <Box>
        <InputContainer label="First Name">
          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            placeholder="First Name"
            error={formik.touched.firstName && !!formik.errors.firstName}
            helperText={formik.touched.firstName && formik.errors.firstName}
            variant="outlined"
            value={formik.values.firstName}
            onChange={formik.handleChange}
          />
        </InputContainer>
        <InputContainer label="Middle Name">
          <TextField
            fullWidth
            id="middleName"
            name="middleName"
            placeholder="Middle Name"
            error={formik.touched.middleName && !!formik.errors.middleName}
            helperText={formik.touched.middleName && formik.errors.middleName}
            variant="outlined"
            value={formik.values.middleName}
            onChange={formik.handleChange}
          />
        </InputContainer>
        <InputContainer label="Last Name">
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            error={formik.touched.lastName && !!formik.errors.lastName}
            helperText={formik.touched.lastName && formik.errors.lastName}
            variant="outlined"
            value={formik.values.lastName}
            onChange={formik.handleChange}
          />
        </InputContainer>
        <InputContainer label="Gender">
          <Autocomplete
            value={formik.values.gender}
            id="gender"
            fullWidth
            onChange={(_: React.SyntheticEvent, gender) => {
              formik.setFieldValue('gender', gender ?? '');
            }}
            getOptionLabel={(gender) => capitalize(gender)}
            options={genders}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Gender"
                variant="outlined"
                error={formik.touched.gender && !!formik.errors.gender}
                helperText={formik.touched.gender && formik.errors.gender}
              />
            )}
          />
        </InputContainer>
        <InputContainer label="Date of Birth">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DatePickerMoment
                label={''}
                value={formik.values.birthDate}
                error={formik.errors.birthDate}
                onChange={(date) => {
                  formik.setFieldValue('birthDate', date);
                }}
              />
            </Grid>
          </Grid>
        </InputContainer>
      </Box>
    </EditDrawer>
  );
};

export default PersonDetailsEditDrawer;
