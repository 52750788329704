import { IncidentResponse, IncidentStatus } from "src/crs/incident/IncidentService";

export class IncidentDto {
  id: number;

  uuid: string;

  createdBy: string | null;

  createdOn: string;

  updatedBy: string | null;

  updatedOn: string | null;

  deletedOn: string | null;

  deletedBy: string | null;

  status: IncidentStatus;

  assigned_to: string | null;

  assigned_on: string | null;

  reason_code: string | null;

  reason_text: string | null;

  scope_code: string;

  scope_reason: string;

  scope_text: string;

  scope_status: string | null;

  scope_data: any | null;
  
  source_type: string;

  source_reference_type: string;

  source_reference_name: string | null;

  source_reference_value: string;

  source_reason: string;

  source_data: string;

  disposition_by: string | null;

  disposition_on: Date | null;

  disposition_outcome: string | null;

  disposition_note: string | null;
  
  disposition_data: string | null;

  fromIncidentResponse(dto: IncidentResponse) {
    this.id = dto.id;
    this.uuid = dto.uuid;
    this.createdBy = dto.createdBy;
    this.createdOn = dto.createdOn;
    this.updatedBy = dto.updatedBy;
    this.updatedOn = dto.updatedOn;
    this.deletedOn = dto.deletedOn;
    this.deletedBy = dto.deletedBy;
    this.status = dto.status;
    this.assigned_to = dto.assigned_to;
    this.assigned_on = dto.assigned_on;
    this.reason_code = dto.reason_code;
    this.reason_text = dto.reason_text;
    this.scope_code = dto.scope_code;
    this.scope_reason = dto.scope_reason;
    this.scope_text = dto.scope_text;
    this.scope_status = dto.scope_status;
    this.scope_data = dto.scope_data;
    this.source_type = dto.source_type;
    this.source_reference_type = dto.source_reference_type;
    this.source_reference_name = dto.source_reference_name;
    this.source_reference_value = dto.source_reference_value;
    this.source_reason = dto.source_reason;
    this.source_data = dto.source_data;
    this.disposition_by = dto.disposition_by;
    this.disposition_on = dto.disposition_on;
    this.disposition_outcome = dto.disposition_outcome;
    this.disposition_note = dto.disposition_note;
    this.disposition_data = dto.disposition_data;
  }
}