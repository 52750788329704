import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, TableRow, TableCell, Typography, MenuItem, Box } from '@mui/material';
// @types
import { Role, userOrgsRoles } from 'src/@types/user';
// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import Item from 'src/sections/crs/common/Item';
import uuidv4 from 'src/utils/uuidv4';
import { PATH_DASHBOARD } from 'src/routes/paths';
import CustomLink from 'src/sections/crs/common/CustomLink';
import { paramCase } from 'change-case';
// ----------------------------------------------------------------------

type Props = {
  row: userOrgsRoles;
  selected: boolean;
  editOption?: boolean;
  customLink?: boolean;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function UserTableRow({
  row,
  editOption,
  customLink,
  onEditRow,
  onDeleteRow,
}: Props) {
  const theme = useTheme();

  const { firstName, middleName, lastName, photo, userOrgRole, status, uuid } = row;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const orgRoles: any = userOrgRole.reduce((result: any, userOrgRole) => {
    const {
      organizationFhirUuid,
      organizationCode,
      organizationName,
      roleCode,
      roleId,
      roleName
    } = userOrgRole;
  
    const existingOrgIndex = result.
      findIndex(item => item.organizationFhirUuid === organizationFhirUuid);
  
    if (existingOrgIndex !== -1) {
      result[existingOrgIndex].roles.push({ roleCode, roleId, roleName });
    } else {
      result.push({
        organizationName,
        organizationCode,
        organizationFhirUuid,
        roles: [{ roleCode, roleId, roleName }]
      });
    }
  
    return result;
  }, []);

  return (
    <TableRow hover>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={firstName} src={photo} sx={{ mr: 2 }} />
        {customLink ? (
          <CustomLink to={`${PATH_DASHBOARD.user.edit(paramCase(uuid))}`}>
            <Typography noWrap variant="subtitle2">
              {`${firstName} ${middleName} ${lastName}`}
            </Typography>
          </CustomLink>
        ) : (
          <TableCell>
            <Typography variant="subtitle2" noWrap>
              {`${firstName} ${middleName} ${lastName}`}
            </Typography>
          </TableCell>
        )}
      </TableCell>

      <TableCell align="left">
        {orgRoles?.length > 0 && (
          orgRoles?.map((item: any) => (
            <Box key={uuidv4()}>
              <Typography variant="caption" fontWeight="fontWeightBold">
                {item?.organizationName}
              </Typography>
              <br />
              {item?.roles?.map((role: Role) => (
                <Item key={uuidv4()} text={role.roleName} color="success" />
              ))}
            </Box>
          ))
        )}
      </TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(status === 'Inactive' && 'error') || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {status}
        </Label>
      </TableCell>

      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onDeleteRow();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
              {!editOption && (
                <MenuItem
                  onClick={() => {
                    onEditRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                  Edit
                </MenuItem>
              )}
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
