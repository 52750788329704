import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import PreviewDetailDrawer, {
  InfoRow,
  PreviewDetailDrawerProps,
  SectionInfo,
} from '../../common/PreviewDetailDrawer';
import { Option } from 'src/@types/crs/case';
import useLocales from 'src/hooks/useLocales';
import moment from 'moment';
import { ReferralStatuses } from './child-referral/constants';
import { useHealthcareServices } from 'src/@nicheaim/fhir-react';
import { HealthcareServiceWrapper } from 'src/@nicheaim/fhir-base/wrappers/HealthcareService';
import { useMemo } from 'react';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

export interface OutboundReferralDetailDrawerProps
  extends Omit<PreviewDetailDrawerProps, 'title' | 'anchor'> {
  serviceRequest?: WrappedServiceRequest | null;
}

const OutboundReferralDetailDrawer = ({
  serviceRequest,
  ...drawerProps
}: OutboundReferralDetailDrawerProps) => {
  const { i18n } = useLocales();

  const healthCareServiceIds = useMemo(
    () =>
      serviceRequest
        ?.getPerformersByReferenceType?.('HealthcareService')
        ?.reduce?.<string[]>((ids, { reference }) => {
          const id = reference?.split?.('/')?.[1];
          if (id) return [...ids, id];
          return ids;
        }, []) ?? [],
    [serviceRequest]
  );

  const [healthcareServices, { isLoading }] = useHealthcareServices({
    map: HealthcareServiceWrapper,
    filter: {
      _id: healthCareServiceIds.join(','),
    },
    autofetch: !!healthCareServiceIds.length,
  });

  const serviceRequestDetails: Option<() => string | undefined>[] = [
    {
      label: `patients.details.serviceRequests.internalNumber`,
      value: () => serviceRequest?.getInternalNumber?.(),
    },
    {
      label: 'patients.details.serviceRequests.occurrenceDate',
      value: () => {
        const date = moment(serviceRequest?.occurrenceDateTime ?? null);
        if (!date.isValid()) return;
        return date.format('MM/DD/YYYY');
      },
    },
    {
      label: 'patients.details.serviceRequests.status',
      value: () => {
        if (!serviceRequest?.status) return '';
        return ReferralStatuses?.[serviceRequest.status];
      },
    },
    {
      label: 'patients.details.serviceRequests.referredFrom',
      value: () => serviceRequest?.requester?.display,
    },
    {
      label: 'patients.details.serviceRequests.referredTo',
      value: () => serviceRequest?.getPerformersByReferenceType?.('Organization')?.[0]?.display,
    },
    {
      label: 'patients.details.serviceRequests.service',
      value: () => {
        const healthCareServiceId = serviceRequest
          ?.getPerformersByReferenceType?.('HealthcareService')?.[0]
          ?.reference?.split?.('/')?.[1];
        if (!healthCareServiceId) return;

        return healthcareServices?.find?.(({ id }) => id === healthCareServiceId)?.name;
      },
    },
    {
      label: 'patients.details.serviceRequests.location',
      value: () => serviceRequest?.locationReference?.[0]?.display,
    },
    {
      label: 'patients.details.serviceRequests.assignedTo',
      value: () => serviceRequest?.getPerformersByReferenceType?.('Practitioner')?.[0]?.display,
    },
  ];

  return (
    <PreviewDetailDrawer
      shouldShowActionButton={checkAclValidation({
        acls: [crsAcls.CRS.PATIENT.SERVICE_REQUESTS.EDIT],
      })}
      isLoading={isLoading}
      title={i18n('patients.details.serviceRequests.details', 'crs')}
      anchor="right"
      {...drawerProps}
    >
      <SectionInfo title={i18n('patients.details.serviceRequests.detail', 'crs')}>
        {serviceRequestDetails.map(({ label, value }) => (
          <InfoRow key={`${label}-${value}`} label={i18n(label, 'crs')} value={value() ?? ''} />
        ))}
      </SectionInfo>
    </PreviewDetailDrawer>
  );
};

export default OutboundReferralDetailDrawer;
