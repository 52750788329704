import { Button, Card, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';

interface CTimerPropsI {
  hours: number;
  minutes: number;
  seconds: number;
}

interface RefreshAuthTokenDialogPropsI {
  handleContinueSession: () => void;
  handleExpireSession: () => void;
  timeLeft?: number;
}

export default function RefreshAuthTokenDialog({
  handleContinueSession,
  handleExpireSession,
  timeLeft,
}: RefreshAuthTokenDialogPropsI) {
  const [msLeft, setMsLeft] = useState<number>(timeLeft || 15000);
  const [displayLoading, setDisplayLoading] = useState<boolean>(false);
  const tick = useRef<any>();

  const timerData = useMemo(() => {
    return milisecondsToTime(msLeft);
  }, [msLeft]);

  useEffect(() => {
    if (msLeft > 0) {
      tick.current = setInterval(decreaseMsLeft, 1000);
    } else {
      handleExpireSession();
      clearTimer();
    }

    return clearTimer;
  }, [timeLeft, msLeft]);

  function clearTimer() {
    if (tick.current) {
      clearInterval(tick.current);
    }
  }

  function decreaseMsLeft() {
    setMsLeft((prev) => {
      const now = prev - 1000;

      if (now < 0) {
        return 0;
      }

      return now;
    });
  }

  function milisecondsToTime(miliseconds: number): CTimerPropsI {
    if (miliseconds <= 0) {
      return {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const secondInMs = 1000;
    const minuteInMs = 60 * secondInMs;
    const hourInMs = 60 * minuteInMs;

    const hours = Math.floor(miliseconds / hourInMs);
    const hoursRemainder = miliseconds % hourInMs;

    const minutes = Math.floor(hoursRemainder / minuteInMs);
    const minutesRemainder = hoursRemainder % minuteInMs;

    const seconds = Math.floor(minutesRemainder / secondInMs);

    return {
      hours,
      minutes,
      seconds,
    };
  }

  function handleContinue() {
    setDisplayLoading(true);
    handleContinueSession();
  }

  function getDisplayTime(data: number, oneUnitLabel: string, multipleUnitLable: string) {
    if (data > 1) {
      return data + ' ' + multipleUnitLable;
    }

    return data + ' ' + oneUnitLabel;
  }

  return (
    <Card>
      <Stack margin={2}>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Typography variant="h5">Session Timeout</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">Your session will expire in:</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h2">
              {timerData.minutes > 0 && getDisplayTime(timerData.minutes, 'min', 'mins')}
            </Typography>{' '}
            <Typography variant="h2">
              {timerData.seconds > 0 && getDisplayTime(timerData.seconds, 'sec', 'secs')}
            </Typography>
          </Grid>
        </Grid>

        {displayLoading ? (
          <>Refreshing...</>
        ) : (
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <Button onClick={handleExpireSession}>Log out</Button>
            </Grid>
            <Grid item>
              <Button onClick={handleContinue} variant="contained">
                Continue Session
              </Button>
            </Grid>
          </Grid>
        )}
      </Stack>
    </Card>
  );
}
