import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import { GridItem, GridSection } from 'src/components/CustomModal';
import InfoRibbon, { InfoTypography } from 'src/components/InfoRibbon';
import { capitalize } from 'src/utils/string';

export interface GoalInfoRibbonProps {
  goal?: WrappedGoal | null;
}

const GoalInfoRibbon = ({ goal }: GoalInfoRibbonProps) => (
  <GridSection mt={3}>
    <GridItem xs={12}>
      <InfoRibbon containerSx={{ marginTop: 2 }}>
        <InfoTypography>Goal: {goal?.getPlainDescription?.() ?? ''}</InfoTypography>
        <InfoTypography>Pathway: {goal?.getPathwayDisplay?.() ?? ''}</InfoTypography>
        <InfoTypography>Status: {capitalize(goal?.lifecycleStatus ?? '')}</InfoTypography>
      </InfoRibbon>
    </GridItem>
  </GridSection>
);

export default GoalInfoRibbon;
