import * as Yup from 'yup';
import { MobileDateTimePicker } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useValueSet } from 'src/@nicheaim/fhir-react';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, RHFSelect } from 'src/components/hook-form';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useLocales from '../../../../../hooks/useLocales';

type Props = {
  isOpen: boolean;
  handlerIsOpen: Function;
  handlerSchedulHJSession: (data: any) => Promise<any>;
};

export default function ScheduleNatSession({
  isOpen,
  handlerIsOpen,
  handlerSchedulHJSession,
}: Props) {
  const { i18n } = useLocales();

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [scheduleHJLocation] = useValueSet('nat-session-types', { map: ValueSetWrapper });

  const schema = Yup.object().shape({
    date_hour: Yup.string().required('Date and Hour is required'),
    location: Yup.string().required('Type is required'),
  });

  const defaultValues = {
    date_hour: '',
    location: '',
  };

  const methods = useForm({ resolver: yupResolver(schema), defaultValues });

  const { control, reset, handleSubmit } = methods;

  const resetCallback = useCallback(() => {
    if (isOpen) {
      reset({
        date_hour: '',
        location: '',
      });
    }
  }, [isOpen, reset]);

  useEffect(() => {
    resetCallback();
  }, [resetCallback]);

  const onSubmit = async (values: any) => {
    setOpenBackdrop(true);
    try {
      await handlerSchedulHJSession(values);
      handlerIsOpen(false);
    } catch (error) {}
    setOpenBackdrop(false);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false);
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <List component="nav">
          <ListItem>
            <Grid container justifyContent="space-between">
              <Stack direction="row" spacing={2}>
                <Button variant="contained" type="submit">
                  {i18n(
                    'patientEngagement.details.workflow.checklist.screeningInformation.title',
                    'engagement'
                  )}
                </Button>
                <Button
                  onClick={() => {
                    handlerIsOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem>
            <Grid container direction="column" marginTop={2} width={800}>
              <Grid container direction="row" alignItems="center" minHeight={60}>
                <Grid container item xs={4}>
                  <Typography>Date and Hour</Typography>
                </Grid>

                <Grid container item xs={8}>
                  <Controller
                    name="date_hour"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <MobileDateTimePicker
                        {...field}
                        label=""
                        minDate={new Date()}
                        DialogProps={{sx: { 
                        '& .css-yquuwn .PrivateDateTimePickerToolbar-penIcon': {
                            display: 'none'
                          }
                        }}}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!error}
                            placeholder='mm/dd/yyyy hh:mm am|pm'
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" alignItems="center" minHeight={60}>
                <Grid container item xs={4}>
                  <Typography>Location</Typography>
                </Grid>

                <Grid container item xs={8}>
                  <RHFSelect name="location" label="" fullWidth={true}>
                    <MenuItem disabled />
                    {scheduleHJLocation?.asList().map((type: any) => (
                      <MenuItem key={type.code} value={type.display}>
                        {type.display}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </FormProvider>
    </Drawer>
  );
}
