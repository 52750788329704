import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
} from '@mui/material';
import * as Yup from 'yup';
import moment from 'moment';
import { capitalCase } from 'capital-case';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AppointmentParticipantList from './AppointmentParticipantList';
import { getReferenceResourceId } from 'src/sections/crs/helpers/common';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { WrappedAppointment } from 'src/@nicheaim/fhir-base/wrappers/Appointment';
import useLocales from 'src/hooks/useLocales';
import DateTimePicker from 'src/components/DateTimePicker/DateTimePicker';
import { isTimeIncludedInDate } from 'src/utils/dates';

export enum AppointmentStatus {
  PROPOSED = 'proposed',
  PENDING = 'pending',
  BOOKED = 'booked',
  ARRIVED = 'arrived',
  FULFILLED = 'fulfilled',
  CANCELLED = 'cancelled',
  NO_SHOW = 'noshow',
  ENTERED_IN_ERROR = 'entered-in-error',
  CHECKED_IN = 'checked-in',
  WAITLIST = 'waitlist',
}

export enum AppointmentParticipantRequired {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
  INFORMATION_ONLY = 'information-only',
}

export enum AppointmentParticipantStatus {
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  TENTATIVE = 'tentative',
  NEEDS_ACTION = 'needs-action',
}

type CustomParticipant = {
  actor: {
    label: string;
    value: string;
  };
  status: string;
  required: string;
};

type FormValue = {
  status: string;
  start: string;
  end: string;
  description: string;
  minutesDuration: number;
  participant: CustomParticipant[];
};

type Props = {
  patient: WrappedPatient | null;
  appointment: WrappedAppointment | null;
  open: boolean;
  onClose: VoidFunction;
  handleAppointment: (data: any) => Promise<any>;
};

export function AppointmentForm({ patient, appointment, open, onClose, handleAppointment }: Props) {
  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [open]);
  const { i18n } = useLocales();

  const [isTimeIncluded, setIsTimeIncluded] = useState(true);

  const [isTimeIncludedInEndInitially, setIsTimeIncludedInEndInitially] = useState(false);
  const [isTimeIncludedInStartInitially, setIsTimeIncludedInStartInitially] = useState(false);

  const EventSchema = Yup.object().shape({
    status: Yup.string().required('Status is required'),
    start: Yup.date()
      .required('Start is required')
      .nullable()
      .transform((curr, orig) => (moment(orig).isValid() ? curr : null)),
    end: Yup.date()
      .required('End is required')
      .nullable()
      .transform((curr, orig) => (moment(orig).isValid() ? curr : null))
      .test('is-greater', 'End date must be greater than start date', function (value) {
        const startDate = moment(this.parent.start);
        const endDate = moment(value);

        return startDate.isSameOrBefore(
          endDate,
          isTimeIncludedInDate(endDate) && isTimeIncludedInDate(startDate) ? undefined : 'date'
        );
      }),
    description: Yup.string().required('Description is required'),
    participant: Yup.array(
      Yup.object({
        status: Yup.string()
          .nullable()
          .transform((curr, orig) => (orig === '' ? curr : null))
          .min(Yup.ref('actor'), 'Status is required'),
        required: Yup.string()
          .nullable()
          .transform((curr, orig) => (orig === '' ? curr : null))
          .min(Yup.ref('actor'), 'Required is required'),
      })
    ),
  });
  useEffect(() => {
    if (!appointment) return;
    setIsTimeIncludedInEndInitially(isTimeIncludedInDate(appointment?.end ?? null));
    setIsTimeIncludedInStartInitially(isTimeIncludedInDate(appointment?.start ?? null));
  }, [appointment]);

  const defaultValues = useMemo(
    () =>
      ({
        status: appointment?.status || '',
        start: appointment?.start || null,
        end: appointment?.end || null,
        description: appointment?.description || '',
        minutesDuration: appointment?.minutesDuration || null,
        participant:
          appointment?.participant
            ?.filter((x1) => getReferenceResourceId(x1.actor?.reference ?? '') !== patient?.id)
            .map((x2) => ({
              ...x2,
              actor: {
                label: x2.actor?.display,
                value: x2.actor?.reference && getReferenceResourceId(x2.actor?.reference),
              },
            })) || [],
      } as FormValue),
    [appointment]
  );

  const methods = useForm<FormValue>({ resolver: yupResolver(EventSchema) });

  const { reset, control, handleSubmit } = methods;

  const mapAppointment = (dataForm: FormValue) => {
    const setParticipant =
      dataForm?.participant?.map((x1) => ({
        ...x1,
        actor: {
          reference: `Practitioner/${x1.actor?.value}`,
          display: x1.actor?.label,
        },
      })) ?? [];

    const mapParticipant: any = [
      ...setParticipant,
      {
        actor: {
          reference: `${patient?.resourceType}/${patient?.id}`,
          display: patient?.getFullName(),
        },
        required: 'required',
        status: 'accepted',
      },
    ];

    return {
      ...dataForm,
      minutesDuration: undefined,
      resourceType: 'Appointment',
      ...(appointment !== null && { id: appointment?.id }),
      start: dataForm?.start && moment(dataForm?.start).toISOString(),
      end: dataForm?.end && moment(dataForm?.end).toISOString(),
      participant: mapParticipant,
      ...(appointment === null && { created: new Date().toISOString() }),
    };
  };

  const onSubmit = async (dataForm: FormValue) => {
    const resultMap = mapAppointment(dataForm);
    handleAppointment(resultMap);
    handleClose();
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };
  const calculateEndFromDuration = (start, duration) => {
    if (start && duration) {
      const end = moment(start).add(duration, 'minutes').toISOString();
      return end;
    }
    return null;
  };
  const minutesDurationRef = useRef(null);
  const calculateDurationFromStartEnd = (start, end) => {
    if (start && end) {
      const startMoment = moment(start);
      const endMoment = moment(end);
      const duration = endMoment.diff(startMoment, 'minutes');
      return duration;
    }
    return 0;
  };
  return (
    <Dialog open={open} fullWidth={true} maxWidth="md">
      <DialogTitle>
        {appointment
          ? 'Edit'
          : `${i18n('patients.details.appointments.titleAddAppointments', 'crs')}`}{' '}
      </DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <RHFSelect
                  name="status"
                  label={i18n('patients.details.appointments.status', 'crs')}
                >
                  <MenuItem disabled></MenuItem>
                  {Object.entries(AppointmentStatus).map((option) => (
                    <MenuItem key={option[0]} value={option[1]}>
                      {capitalCase(option[1])}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} sx={{ width: 1 }}>
                  <Controller
                    name="start"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <DateTimePicker
                          {...field}
                          isTimeIncluded={
                            appointment ? isTimeIncludedInStartInitially : isTimeIncluded
                          }
                          isTimeOptional
                          onTimeInclusionChange={setIsTimeIncluded}
                          textFieldProps={{ 
                            fullWidth: true, 
                            placeholder: i18n('patients.details.appointments.placeholderStartDate', 'crs') 
                          }}
                          label={i18n('patients.details.appointments.start', 'crs')}
                          errorMessage={error?.message}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="end"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <DateTimePicker
                          {...field}
                          textFieldProps={{ 
                            fullWidth: true, 
                            placeholder: i18n('patients.details.appointments.placeholderEndDate', 'crs') 
                          }}
                          label={i18n('patients.details.appointments.end', 'crs')}
                          isTimeIncluded={
                            appointment ? isTimeIncludedInEndInitially : isTimeIncluded
                          }
                          isTimeOptional
                          onChange={(date) => {
                            field.onChange(date);
                            const startValue = methods.getValues('start');
                            const endValue = date; // Nuevo valor del campo 'end'

                            if (startValue && endValue) {
                              const calculatedDuration = calculateDurationFromStartEnd(
                                startValue,
                                endValue
                              );
                              methods.setValue('minutesDuration', calculatedDuration);
                            }
                          }}
                          errorMessage={error?.message}
                        />
                      </>
                    )}
                  />
                </Stack>
                <AppointmentParticipantList />
                <RHFTextField
                  name="description"
                  label={i18n('patients.details.appointments.description', 'crs')}
                  multiline
                  rows={4}
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />

              <Button variant="contained" color="info" onClick={handleClose}>
                {i18n('cancel')}
              </Button>

              <Button variant="contained" color="info" type="submit">
                {i18n('submit')}
              </Button>
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
}
