import { orderBy } from "lodash";
import { fhirClient } from "src/App";
import { getSplitReference } from "src/utils/fhir";
import { dateYearFormatWithAge } from "src/utils/formatTime";
import { ScopeReasonType } from "src/crs/incident/IncidentService";
import { FhirResourceMapping, FhirResourceName } from "src/@nicheaim/fhir-base/mappings";
import { Address } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";

export type ResourceMapping = FhirResourceMapping[FhirResourceName];

export const getSourceDataField = (
  row: any,
  fieldName: string,
  fieldAttribute: string
) => {
  const data = row?.source_data?.doform_data ?? row?.source_data;
  if (!data?.fields) return "";
  const value = data?.fields
    .filter(
      (value: any) => value?.name === "Interested_Child_s_Basic_Infor"
    )?.[0]
    ?.fields?.filter((value: any) => value?.name === fieldName)?.[0]?.[
    fieldAttribute
  ];
  const value2 = data?.fields.filter(
    (value: any) => value?.name === "Beneficiary_Medicaid_ID"
  )?.[0]?.text;
  if (fieldName === "Beneficiary_Medicaid_ID") {
    return value2 || "";
  }

  if(fieldName === "Beneficiary_Date_of_Birth") {
    return dateYearFormatWithAge(value)
  }

  return value || "";
};

export const getPatientField = (
  row: any,
  fieldName: string,
  fieldAttribute: string
) => {
  const scopeReason = row?.scope_reason?.toLowerCase?.() ?? "";
  switch (scopeReason) {
    case ScopeReasonType.PCP_ASSIGNMENT_GROUP_REASON:
      return getPatientIncidentField(row, fieldName) ?? "";
    case ScopeReasonType.INVALID_ADDRESS:
      return getPatientIncidentField(row, fieldName) ?? "";
    default:
      return getSourceDataField(row, fieldName, fieldAttribute) ?? "";
  }
};
  
export const getPatientIncidentField = (row: any, fieldName: string) => {
  const resourceType = row?.source_data?.patient ?? row?.source_data?.resource;
  switch (fieldName) {
    case "Beneficiary_First_Name":
      return resourceType?.firstName;
    case "Beneficiary_Last_Name":
      return resourceType?.lastName;
    case "Beneficiary_Medicaid_ID":
      return resourceType?.medicaId;
    case "Beneficiary_Date_of_Birth":
      return resourceType?.birthDate ? dateYearFormatWithAge(resourceType?.birthDate) : "";
    default:
      return "";
  }
};

export const statusStyle = (status: string) => {
  switch (status) {
    case "New":
      return {
        color: "#006c9c",
        backgroundColor: "rgb(0, 184, 217,16%)",
      };
    case "In-Progress":
      return {
        color: "#b76e00",
        backgroundColor: "rgb(183, 110, 0,16%)"
      };
    case "Accepted":
      return {
        color: "#007b55",
        backgroundColor: "rgb(0, 123, 85,16%)"
      };
    case "Rejected":
      return {
        color: "#b71d18",
        backgroundColor: "rgb(183, 29, 24,16%)"
      };
    case "Close":
      return {
        color: "#454f5b",
        backgroundColor: "rgb(69, 79, 91,16%)"
      };
  }
};

export async function getResourceByReference (referenceValue: string) {
  const getReference = getSplitReference(referenceValue);
  const resourceType = getReference?.resourceType ?? '';
  const resourceUuid = getReference?.uuid ?? '';

  const resource = await fhirClient.findById<ResourceMapping>(resourceType, resourceUuid);
  return resource;
};

export async function updateResource(resourceType: string, payload: ResourceMapping) {
  const resource = await fhirClient.updateOne<ResourceMapping>(resourceType, payload);
  return resource;
}

export const addressesSorted = (addresses: Address[], sorted: string[]) => {

  const getPatientAddress: Address[] = JSON.parse(JSON.stringify(addresses));
  getPatientAddress?.map((ident: any, index: any) => {
    ident.id = ident.id ? ident.id : index;
    ident.valid = ident?.extension?.[0]?.extension?.
      find((a: any) => a.url === 'valid')?.valueBoolean ?? false;
  });

  const addressSorted: any = orderBy(getPatientAddress, sorted, ['desc']);
  return addressSorted;
}