// @mui
import { styled } from '@mui/material/styles';
import { Stack, AppBar, Toolbar } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import LanguagePopover from './LanguagePopover';
import { useEffect, useRef, useState } from 'react';
import { ADD_PERSON, useEventBus } from 'src/event-bus';
import { mockPersons } from 'src/_mock/_careflow';
import SettingsPopover from './SettingsPopover';
import PatientTabs from './PatientTabs';
import {
  checkAclValidation,
  SysAdmin,
  adminAll,
  ACLValidationMode,
} from 'src/utils/permissions/permission.utils';
import usePatientTabsStore from 'src/stores/patient-tabs';

// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {

  const { openPatientIds } = usePatientTabsStore();

  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  const [persons, setPersons] = useState([mockPersons[0]]);

  const refValue = useRef(persons);
  useEffect(() => {
    refValue.current = persons;
  });

  const { dispatchEvent, onEvent, removeEvent } = useEventBus();

  const mergeUnique = (listA: any, listB: any) => {
    const existingIds = listA.map((a: any) => a.id);
    const newList = listB.filter((b: any) => !existingIds.includes(b.id));
    return [...listA, ...newList];
  };

  const callback = ({ data }: Array<any> | any) => {
    const persons = refValue.current;
    const newPersons = Array.isArray(data) ? data : [data];

    const updatedPersons = mergeUnique(persons, newPersons);
    setPersons(updatedPersons);
  };
  useEffect(() => {
    onEvent(ADD_PERSON, callback);
    return () => removeEvent(ADD_PERSON, callback);
  }, []);

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={openPatientIds?.length ? false : isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <LanguagePopover />
        <Searchbar />
        <PatientTabs />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
          sx={{ ml: 'auto' }}
        >
          <NotificationsPopover />
          {checkAclValidation({ acls: [SysAdmin, adminAll] }, ACLValidationMode.EXCLUSIVE) && (
            <>
              <SettingsPopover />
            </>
          )}
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
