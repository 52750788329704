import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import agent from 'src/api/agent';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/Iconify';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { incidentService } from 'src/crs/incident';
import CustomModal from 'src/components/CustomModal';
import { useEffect, useMemo, useState } from 'react';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { dateYearFormatWithAge } from 'src/utils/formatTime';
import KeyValueGrid from 'src/sections/crs/common/KeyValueGrid';
import { ResourceMapping } from 'src/sections/crs/helpers/indicent';
import DetailsSummary from 'src/sections/crs/common/DetailsSummary';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { checkAclValidation, getRelatedAcls } from 'src/utils/permissions/permission.utils';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { RelatedPersonWrapper } from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import { IncidentResponse, IncidentStatus, ScopeReasonType } from 'src/crs/incident/IncidentService';

interface HeaderProps {
  incident: IncidentResponse | undefined;
  resource: ResourceMapping | undefined;
  dataDoForm: any;
  typeIncident: string;
  user: any;
  onIncidentUpdate: (updatedIncident: IncidentResponse) => void;
}

export default function Header({
  incident,
  resource,
  dataDoForm,
  typeIncident,
  user,
  onIncidentUpdate,
}: HeaderProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [openAssign, setOpenAssign] = useState(false);
  const [assigned, setAssigned] = useState<any>(null);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const fetchAllUsers = async () => {
    try {
      const getAllUsers = await agent.User.getAllUsersByAcls(
        getRelatedAcls([crsAcls.CRS.INCIDENT.EDIT])
      );
      const uniqueUsers: any = [];
      const userNamesSet = new Set();

      getAllUsers?.forEach((user: any) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        if (!userNamesSet.has(fullName)) {
          uniqueUsers.push(user);
          userNamesSet.add(fullName);
        }
      });
      uniqueUsers.sort((a: any, b: any) => {
        const fullNameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const fullNameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        return fullNameA.localeCompare(fullNameB);
      });

      setAllUsers(uniqueUsers);
    } catch (err) {
      console.log('error on fetching all users', err);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const getPatientDetails = useMemo(() => {

    let setOwner: any = null;
    let medicaId: any = null;

    switch (resource?.resourceType) {
      case "Patient":
        setOwner = PatientWrapper(resource)
        medicaId = setOwner?.getMedicaId();
        break;
      case "Practitioner":
        setOwner = PractitionerWrapper(resource);
        break;
      case "RelatedPerson":
        setOwner = RelatedPersonWrapper(resource);
        break;
    }

    return {
      "Date of Birth": (setOwner?.birthDate ? dateYearFormatWithAge(setOwner?.birthDate || '') : 
        (dataDoForm?.beneficiary_date_of_birth && dateYearFormatWithAge(dataDoForm?.beneficiary_date_of_birth))) ?? null,
      "Medicaid ID": (medicaId || dataDoForm?.beneficiary_medicaid_ID) ?? null,
      Phone: (setOwner ? setOwner?.getPrimaryPhone()?.value : dataDoForm?.beneficiary_cell_phone_number) ?? null,
      Email: (setOwner ? setOwner?.getPrimaryEmail()?.value : dataDoForm?.beneficiary_contact_email) ?? null,
      Address: (setOwner ? setOwner?.getPrimaryAddress()?.line?.[0] : dataDoForm?.beneficiary_address) ?? null,
      State: (setOwner ? setOwner?.getPrimaryAddress()?.state : dataDoForm?.state) ?? null,
      "Zip Code": (setOwner ? setOwner?.getPrimaryAddress()?.postalCode : dataDoForm?.zip_code) ?? null
    }
  }, [dataDoForm, resource]); 

  const handlerCancel = () => {
    setOpenAssign(false);
    setErrorMsg(false);
    setAssigned(null);
  };

  const handlerAssign = async () => {
    try {
      if (!incident) return;

      if (!assigned) {
        setErrorMsg(true);
        return;
      }

      setIsLoading(true);

      const scopeData = {
        ...(incident.scope_data ?? {}),
        ...(incident.scope_data?.linked_record_new && { linked_record_new: '' }),
      };

      const incidentPayload: IncidentResponse = {
        ...incident,
        assigned_to: assigned,
        assigned_on: moment().toISOString(),
        scope_data: { ...scopeData },
        status: IncidentStatus.IN_PROGRESS,
      };

      const updatedIncident = await incidentService.updateIncident(incident?.id, incidentPayload);
      if (updatedIncident) onIncidentUpdate(updatedIncident);

      enqueueSnackbar('Incident was assigned');
      handlerCancel();
      setIsLoading(false);
    } catch (error) {
      handlerCancel();
      enqueueSnackbar('An error has occurred', { variant: 'error' });
    }
  };

  const aclEditUser = checkAclValidation({ acls: [crsAcls.CRS.INCIDENT.EDIT] });
  const dataIncidentStatus =
    incident?.status === IncidentStatus.REJECTED || incident?.status === IncidentStatus.ACCEPTED;
  const isNotDoForm = 
    typeIncident === ScopeReasonType.INVALID_ADDRESS || 
    typeIncident === ScopeReasonType.PCP_ASSIGNMENT_GROUP_REASON;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={isNotDoForm ? 4 : 3} lg={isNotDoForm ? 4 : 3}>
          <DetailsSummary
            children={
              isNotDoForm ? (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>Beneficiary Information</Typography>
                  {typeIncident === ScopeReasonType.PCP_ASSIGNMENT_GROUP_REASON && (
                    <Tooltip title={"Beneficiary Details"}>
                      <IconButton  onClick={() => {
                        window.open(
                          `${PATH_DASHBOARD.crs.patient.details.forId(resource?.id!)}`,"blank"
                        )
                      }}>
                        <Iconify 
                          icon={'ic:outline-open-in-new'} 
                          width={18} 
                          height={18} 
                          color='#3dab2b'
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              ) : (
                <Typography>Beneficiary Information</Typography>
              )
            }
            data={getPatientDetails}
            devMode={false}
          />
        </Grid>

        {!isNotDoForm && (
          <Grid item xs={12} md={3} lg={3}>
            <DetailsSummary
              title="Guarantor Information"
              data={{
                Name: `${dataDoForm?.guarantor_first_name ?? ''} ${
                  dataDoForm?.guarantor_last_name ?? ''
                }`.trim(),
                Phone: dataDoForm?.guarantor_cell_phone_number,
                Email: dataDoForm?.guarantor_contact_email,
                Address: '',
              }}
              devMode={false}
            />
          </Grid>
        )}

        <Grid item xs={12} md={isNotDoForm ? 4 : 3} lg={isNotDoForm ? 4 : 3}>
          <DetailsSummary
            title={typeIncident === ScopeReasonType.PCP_ASSIGNMENT_GROUP_REASON ? 
              'Incident General Information' : 'General Information'}
            children={
              <Stack spacing={1} sx={{ m: 2 }}>
                <KeyValueGrid
                  key={incident?.assigned_to}
                  label={'Assigned To'}
                  value={incident?.assigned_to ?? ''}
                  children={
                    <Stack direction="row" alignItems="center">
                      <Tooltip title={incident?.assigned_to}>
                        <Box sx={{ maxWidth: 100 }}>
                          <Typography variant="body2" noWrap color="#637381">
                            {incident?.assigned_to}
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip title={incident?.assigned_to}>
                        <IconButton
                          onClick={() => setOpenAssign(true)}
                          disabled={!aclEditUser || dataIncidentStatus}
                        >
                          <Iconify icon={'mdi:pencil'} color="#3dab2b" width={18} height={18} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  }
                />
                <KeyValueGrid
                  key={`cron-${incident?.createdOn}`}
                  label={'Created On'}
                  value={incident?.createdOn ? dateYearFormatWithAge(incident?.createdOn) : ''}
                />
                <KeyValueGrid
                  key={incident?.updatedOn}
                  label={'Updated On'}
                  value={incident?.updatedOn ? dateYearFormatWithAge(incident?.updatedOn) : ''}
                />
                <KeyValueGrid
                  key={incident?.source_type}
                  label={'Source'}
                  value={incident?.source_type ?? ''}
                />
                <KeyValueGrid
                  key={incident?.scope_text}
                  label={'Scope'}
                  value={incident?.scope_text ?? ''}
                />
              </Stack>
            }
            data={null}
          />
        </Grid>

        <Grid item xs={12} md={isNotDoForm ? 4 : 3} lg={isNotDoForm ? 4 : 3}>
          <DetailsSummary
            title="Disposition"
            data={{
              'Disposition By': incident?.disposition_by,
              'Disposition On':
                incident?.disposition_on && dateYearFormatWithAge(`${incident?.disposition_on}`),
              'Disposition Outcome': incident?.disposition_outcome,
              'Disposition Note': incident?.disposition_note,
            }}
          />
        </Grid>
      </Grid>
      <CustomModal
        open={openAssign}
        title={`${incident?.assigned_to ? 'Re-Assign To' : 'Assign To'}`}
        breadcrumbs={[]}
        showSaveButton={true}
        onCancel={handlerCancel}
        onSave={handlerAssign}
        isLoading={isLoading}
      >
        <Autocomplete
          sx={{ mt: 2 }}
          id="combo-box-assign-to"
          value={assigned}
          onChange={(_, user) => {
            setErrorMsg(false);
            setAssigned(user);
          }}
          getOptionLabel={(user: any) => user ?? ''}
          options={allUsers.map((user: any) => user?.email)}
          renderInput={(params) => (
            <TextField
              label="Users"
              {...params}
              helperText={errorMsg && 'Field is required'}
              error={errorMsg}
            />
          )}
        />
      </CustomModal>
    </>
  );
}
